export const countries = [
    { id: 1, name: 'Afghanistan', code: 'AF', phoneCode: '+93' },
    { id: 2, name: 'Albania', code: 'AL', phoneCode: '+355' },
    { id: 3, name: 'Algeria', code: 'DZ', phoneCode: '+213' },
    { id: 4, name: 'Andorra', code: 'AD', phoneCode: '+376' },
    { id: 5, name: 'Angola', code: 'AO', phoneCode: '+244' },
    { id: 6, name: 'Argentina', code: 'AR', phoneCode: '+54' },
    { id: 7, name: 'Armenia', code: 'AM', phoneCode: '+374' },
    { id: 8, name: 'Australia', code: 'AU', phoneCode: '+61' },
    { id: 9, name: 'Austria', code: 'AT', phoneCode: '+43' },
    { id: 10, name: 'Azerbaijan', code: 'AZ', phoneCode: '+994' },
    { id: 11, name: 'Bahamas', code: 'BS', phoneCode: '+1-242' },
    { id: 12, name: 'Bahrain', code: 'BH', phoneCode: '+973' },
    { id: 13, name: 'Bangladesh', code: 'BD', phoneCode: '+880' },
    { id: 14, name: 'Barbados', code: 'BB', phoneCode: '+1-246' },
    { id: 15, name: 'Belarus', code: 'BY', phoneCode: '+375' },
    { id: 16, name: 'Belgium', code: 'BE', phoneCode: '+32' },
    { id: 17, name: 'Belize', code: 'BZ', phoneCode: '+501' },
    { id: 18, name: 'Benin', code: 'BJ', phoneCode: '+229' },
    { id: 19, name: 'Bhutan', code: 'BT', phoneCode: '+975' },
    { id: 20, name: 'Bolivia', code: 'BO', phoneCode: '+591' },
    { id: 21, name: 'Bosnia and Herzegovina', code: 'BA', phoneCode: '+387' },
    { id: 22, name: 'Botswana', code: 'BW', phoneCode: '+267' },
    { id: 23, name: 'Brazil', code: 'BR', phoneCode: '+55' },
    { id: 24, name: 'Brunei', code: 'BN', phoneCode: '+673' },
    { id: 25, name: 'Bulgaria', code: 'BG', phoneCode: '+359' }
  ];

  export const AddOnServices = [
    {label: 'Meet And Greet'},
    {label: 'Coordination Fee'},
    {label: 'Top Up Champange'},
    {label: 'Holiday'},
  ]

  export const currencies = [
    { id: 1, name: 'United States Dollar', symbol: '$', code: 'USD', rate: 0.74, remarks: 'Popular currency' },
    { id: 2, name: 'Euro', symbol: '€', code: 'EUR', rate: 0.65, remarks: 'Widely used in Europe' },
    { id: 3, name: 'Japanese Yen', symbol: '¥', code: 'JPY', rate: 81.57, remarks: 'Used in Japan' },
    { id: 4, name: 'British Pound', symbol: '£', code: 'GBP', rate: 0.53, remarks: 'Used in the UK' },
    { id: 5, name: 'Australian Dollar', symbol: 'A$', code: 'AUD', rate: 1.08, remarks: 'Used in Australia' },
    { id: 6, name: 'Canadian Dollar', symbol: 'C$', code: 'CAD', rate: 0.94, remarks: 'Used in Canada' },
    { id: 7, name: 'Swiss Franc', symbol: 'CHF', code: 'CHF', rate: 0.69, remarks: 'Used in Switzerland' },
    { id: 8, name: 'Chinese Yuan', symbol: '¥', code: 'CNY', rate: 4.85, remarks: 'Used in China' },
    { id: 9, name: 'New Zealand Dollar', symbol: 'NZ$', code: 'NZD', rate: 1.07, remarks: 'Used in New Zealand' },
    { id: 10, name: 'Indian Rupee', symbol: '₹', code: 'INR', rate: 54.78, remarks: 'Used in India' },
  ]