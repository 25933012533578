import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TripList } from "../../../trips/model/Trip.model";
import {
  faFilePen,
  faCar,
  faDollarSign,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Typography,
  FormLabel,
  TextField,
  Button,
  Box,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import dayjs from "dayjs";
import LoadingPage from "../../../common/Loading.page";
import { tripManagementHeader } from "../../../trips/Data/form-data";
import Colors from "../../../../utils/ColorUtils";

const SupplierTripsPage = () => {
  const navigate = useNavigate();
  type TripMenuType =
    | ""
    | "ALL_TRIP"
    | "IN_PROGRESS"
    | "UNCONFIRMED"
    | "UNSCHEDULED"
    | "CANCELLED"
    | "COMPLETED";

  const [selectedMenu, setSelectedMenu] = useState<TripMenuType>("ALL_TRIP");
  const [tripList, setTripList] = useState<TripList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalRefund, setModalRefund] = useState<boolean>(false);
  const [selectedTrip, setSelectedTrip] = useState<TripList>();
  const [refundAmount, setRefundAmount] = useState<string>("");
  const [modalCopy, setModalCopy] = useState<boolean>(false);

  const listTripMenu = [
    { label: "All Trip", value: "ALL_TRIP" },
    { label: "In Progress", value: "IN_PROGRESS" },
    { label: "Unconfirmed", value: "UNCONFIRM" },
    { label: "Unscheduled", value: "UNSCHEDULED" },
    { label: "Canceled", value: "CANCELLED" },
    { label: "Completed", value: "COMPLETED" },
  ];

  return (
    <div className="flex flex-col">
      <LoadingPage loading={loading} />
      <div className="flex flex-1 bg-[#F1F2F3]">
        <div className="w-96 flex flex-col justify-center">
          <Typography variant="h5" className="p-4">
            Trip Management
          </Typography>
        </div>
      </div>
      <div className="flex flex-1 flex-row justify-between h-full px-4">
        {listTripMenu.map((item, index) => (
          <div
            className={`flex flex-1 rounded-t-lg p-2 py-4 shadow-lg hover:cursor-pointer hover:bg-opacity-90 text-white ${
              item.value === selectedMenu ? "bg-[#333333]" : `bg-[#edba37]`
            }`}
            onClick={() => {
              setSelectedMenu(item.value as TripMenuType);
            }}
          >
            <span className="font-semibold">{item.label}</span>
          </div>
        ))}
      </div>
      <div className={`flex flex-row m-4 justify-between`}>
        <div className="flex flex-row w-1/3 rounded-lg border-2 border-orange-200 p-2 gap-2">
          <div className="flex flex-col">
            <FormLabel>Start Date</FormLabel>
            <TextField
              size="small"
              type="date"
              InputProps={{ style: { height: 24 } }}
            />
          </div>
          <div className="flex flex-col">
            <FormLabel>End Date</FormLabel>
            <TextField
              size="small"
              type="date"
              InputProps={{ style: { height: 24 } }}
            />
          </div>
          <Button
            className="w-32 disabled:text-white disabled:cursor-not-allowed"
            variant="contained"
            size="small"
            style={{ backgroundColor: "#0A2638" }}
          >
            Search
          </Button>
        </div>
        <div className="flex flex-row self-center gap-2">
          <Button
            className="w-32 mr-2 disabled:text-white disabled:cursor-not-allowed"
            variant="contained"
            size="small"
            style={{ backgroundColor: "#0A2638" }}
            onClick={() => {}}
          >
            AllSelected
          </Button>
          <Button
            className="w-32 disabled:text-white disabled:cursor-not-allowed"
            variant="contained"
            size="small"
            style={{ backgroundColor: "#0A2638" }}
          >
            Export
          </Button>
          <Select
            placeholder="Select Status"
            className="w-48 text-white disabled:cursor-not-allowed"
            size="small"
            style={{ backgroundColor: "#0A2638" }}
            defaultValue={''}
            sx={{
                color: 'white',
                '& .MuiSelect-icon': {
                  color: 'white',
                },
                '& .MuiList-root': {
                  color: 'white',
                },
              }}
          >
            <MenuItem className="text-white" value={"all"}>Select Status</MenuItem>
          </Select>
        </div>
      </div>
      <div>
        <Box>
          <CardContent>
            <TableContainer component={Paper}>
              <Table size="small" sx={{ minWidth: 650 }}>
                <TableHead className="bg-neutral-300">
                  <TableRow>
                    {tripManagementHeader.map((header, idx) => (
                      <TableCell>{header.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tripList?.map((val: TripList, idx) => (
                    <TableRow
                      key={val.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="flex flex-col gap-4">
                          <div className="flex flex-row gap-4">
                            <FontAwesomeIcon
                              className="h-5 w-5 cursor-pointer"
                              title="detail"
                              icon={faFilePen}
                              onClick={() =>
                                navigate(
                                  `/trip/trip-management/detail?id=${val.id}`
                                )
                              }
                            />
                            <FontAwesomeIcon
                              className="h-5 w-5 cursor-pointer"
                              title="Assign Driver"
                              icon={faCar}
                              onClick={() => {
                                navigate(`/trip/assign-driver?id=${val.id}`);
                              }}
                            />
                          </div>
                          <div className="flex flex-row gap-4">
                            <FontAwesomeIcon
                              className="h-5 w-5 cursor-pointer"
                              title="refund"
                              icon={faDollarSign}
                              onClick={() => {
                                setModalRefund(true);
                                setSelectedTrip(val);
                              }}
                            />
                            <FontAwesomeIcon
                              className="h-5 w-5 cursor-pointer"
                              title="copy"
                              icon={faCopy}
                              onClick={() => setModalCopy(true)}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        {val?.basicDetails?.accountName ?? "-"}
                      </TableCell>
                      <TableCell>{val?.status}</TableCell>
                      <TableCell>{val.vehicleDetails?.vehicleName}</TableCell>
                      <TableCell>{val.vehicleDetails?.serviceType}</TableCell>
                      <TableCell>{`${dayjs(
                        val?.timeLocationDetails?.pickupDate,
                        "YYYY-MM-DD"
                      ).format("DD-MMM-YYYY")} ${
                        val?.timeLocationDetails?.pickupTime
                      }`}</TableCell>
                      <TableCell>{val?.flightDetails?.flightNumber}</TableCell>
                      <TableCell>{val?.basicDetails?.name}</TableCell>
                      <TableCell>
                        <div className="flex flex-col">
                          <span
                            title={
                              val?.timeLocationDetails?.pickUpLocationDetails
                                ?.placeName
                            }
                          >
                            {"From: " +
                              val?.timeLocationDetails?.pickUpLocationDetails
                                ?.placeName}
                          </span>
                          <span
                            title={
                              val?.timeLocationDetails?.dropOffLocationDetails
                                ?.placeName
                            }
                          >
                            {"To: " +
                              val?.timeLocationDetails?.dropOffLocationDetails
                                ?.placeName}
                          </span>
                        </div>
                      </TableCell>
                      {/* <TableCell>
                                                    <div className="flex flex-col">
                                                        <span>{'<<DRIVER NAME>>'}</span>
                                                        <span>{'<<DRIVER PHONE>>'}</span>
                                                        <span>{val?.vehicleDetails?.vehicleName}</span>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="flex flex-col">
                                                        <span>{'<<SUPPLIER PERSON NAME>>'}</span>
                                                        <span>{'<<SUPPLIER NAME>>'}</span>
                                                    </div>
                                                </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Box>
      </div>
      <Modal
        open={modalRefund}
        onClose={() => setModalRefund(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="flex flex-1 flex-col w-screen h-screen justify-center"
      >
        <div className="flex flex-col h-1/3 w-1/3 bg-white rounded-lg self-center p-4 justify-between">
          <Typography variant="h5">Refund</Typography>
          <div>
            <InputLabel className="text-sm -mb-2">Amount*</InputLabel>
            <TextField
              className="w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="refundAmount"
              name="refundAmount"
              value={refundAmount}
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  setRefundAmount(e.target.value);
                }
              }}
            />
          </div>
          <div>
            <InputLabel className="text-sm -mb-2">Transaction ID</InputLabel>
            <TextField
              disabled
              className="w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="transactionId"
              name="transactionId"
              value={selectedTrip?.paymentDetails.transactionId}
            />
          </div>
          <div className="flex flex-row-reverse">
            <Button
              className="w-42 disabled:text-white disabled:cursor-not-allowed gap-2"
              variant="contained"
              size="small"
              style={{ backgroundColor: "#B6A269" }}
            >
              Refund
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SupplierTripsPage;
