export const timezones = [
    "Africa/Abidjan",
    "Africa/Accra",
    "Africa/Addis_Ababa",
    "Africa/Algiers",
    "Africa/Asmara",
    "Africa/Bamako",
    // Add more timezones as needed
    "America/New_York",
    "America/Chicago",
    "America/Denver",
    "America/Los_Angeles",
    "Asia/Tokyo",
    "Asia/Shanghai",
    "Europe/London",
    "Europe/Paris",
  ]

  export const currencies = [
    "AED - United Arab Emirates Dirham",
    "AFN - Afghan Afghani",
    "ALL - Albanian Lek",
    "AMD - Armenian Dram",
    "ANG - Netherlands Antillean Guilder",
    "AOA - Angolan Kwanza",
    "ARS - Argentine Peso",
    "AUD - Australian Dollar",
    "AWG - Aruban Florin",
    "AZN - Azerbaijani Manat",
    "BAM - Bosnia-Herzegovina Convertible Mark",
    "BBD - Barbadian Dollar",
    "BDT - Bangladeshi Taka",
    "BGN - Bulgarian Lev",
    "BHD - Bahraini Dinar",
    "BIF - Burundian Franc",
    "BMD - Bermudian Dollar",
    "BND - Brunei Dollar",
    "BOB - Bolivian Boliviano",
    "BRL - Brazilian Real",
    "BSD - Bahamian Dollar",
    "BTN - Bhutanese Ngultrum",
    "BWP - Botswanan Pula",
    "BYN - Belarusian Ruble",
    "BZD - Belize Dollar",
    "CAD - Canadian Dollar",
    "CDF - Congolese Franc",
    "CHF - Swiss Franc",
    "CLP - Chilean Peso",
    "CNY - Chinese Yuan",
    "COP - Colombian Peso",
    "CRC - Costa Rican Colón",
    "CUP - Cuban Peso",
    "CVE - Cape Verdean Escudo",
    "CZK - Czech Republic Koruna",
    "DJF - Djiboutian Franc",
    "DKK - Danish Krone",
    "DOP - Dominican Peso",
    "DZD - Algerian Dinar",
    "EGP - Egyptian Pound",
    "ERN - Eritrean Nakfa",
    "ETB - Ethiopian Birr",
    "EUR - Euro",
    "FJD - Fijian Dollar",
    "FKP - Falkland Islands Pound",
    "FOK - Faroese Króna",
    "GBP - British Pound Sterling",
    "GEL - Georgian Lari",
    "GGP - Guernsey Pound",
    "GHS - Ghanaian Cedi",
    "GIP - Gibraltar Pound",
    "GMD - Gambian Dalasi",
    "GNF - Guinean Franc",
    "GTQ - Guatemalan Quetzal",
    "GYD - Guyanaese Dollar",
    "HKD - Hong Kong Dollar",
    "HNL - Honduran Lempira",
    "HRK - Croatian Kuna",
    "HTG - Haitian Gourde",
    "HUF - Hungarian Forint",
    "IDR - Indonesian Rupiah",
    "IMP - Isle of Man Pound",
    "INR - Indian Rupee",
    "IQD - Iraqi Dinar",
    "IRR - Iranian Rial",
    "ISK - Icelandic Króna",
    "JEP - Jersey Pound",
    "JMD - Jamaican Dollar",
    "JOD - Jordanian Dinar",
    "JPY - Japanese Yen",
    "KES - Kenyan Shilling",
    "KGS - Kyrgystani Som",
    "KHR - Cambodian Riel",
    "KID - Kiribati Dollar",
    "KMF - Comorian Franc",
    "KRW - South Korean Won",
    "KWD - Kuwaiti Dinar",
    "KYD - Cayman Islands Dollar",
    "KZT - Kazakhstani Tenge",
    "LAK - Laotian Kip",
    "LBP - Lebanese Pound",
    "LKR - Sri Lankan Rupee",
    "LRD - Liberian Dollar",
    "LSL - Lesotho Loti",
    "LYD - Libyan Dinar",
    "MAD - Moroccan Dirham",
    "MDL - Moldovan Leu",
    "MGA - Malagasy Ariary",
    "MKD - Macedonian Denar",
    "MMK - Myanma Kyat",
    "MNT - Mongolian Tugrik",
    "MOP - Macanese Pataca",
    "MRU - Mauritanian Ouguiya",
    "MUR - Mauritian Rupee",
    "MVR - Maldivian Rufiyaa",
    "MWK - Malawian Kwacha",
    "MXN - Mexican Peso",
    "MYR - Malaysian Ringgit",
    "MZN - Mozambican Metical",
    "NAD - Namibian Dollar",
    "NGN - Nigerian Naira",
    "NIO - Nicaraguan Córdoba",
    "NOK - Norwegian Krone",
    "NPR - Nepalese Rupee",
    "NZD - New Zealand Dollar"
  ]