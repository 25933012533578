import React, { useEffect, useState } from "react"
import LoadingPage from "../../../common/Loading.page"
import { Box, Button, Card, CardContent, InputLabel, Modal, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleLeft, faCopy, faEdit, faFilePen, faPlus, faSave, faSearch } from "@fortawesome/free-solid-svg-icons"
import { PricePlan } from "../model/Price.model"
import { useLocation, useNavigate } from "react-router-dom"
import AirportDeparture from "./components/AirportDeparture"
import AirportArrival from "./components/AirportArrival"
import PointToPoint from "./components/PointToPoint"
import Hourly from "./components/Hourly"
import { getPricePlanById } from "../../../../services/axiosPayment"

const EditSellingPricePage = () => {

    type tabType = 'ARR' | 'DEP' | 'P2P' | 'HRL'

    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [loading, setLoading] = useState<boolean>(false)
    const [pricePlanDetail, setPriceDetail] = useState<PricePlan[]>([])
    const [selectedPricePlan, setSelectedPricePlan] = useState<PricePlan>()
    const [showModalPricePlan, setShowModalPricePlan] = useState<boolean>(false)
    const [selectedName, setSelectedName] = useState<string>('')
    const [activeTab, setActiveTab] = useState<tabType>('ARR')

    const id: string = queryParams.get('id') ?? ''

    const tab = [
        { label: 'Airport Arrival', value: 'ARR' },
        { label: 'Airport Departure', value: 'DEP' },
        { label: 'Point To Point', value: 'P2P' },
        { label: 'Hourly', value: 'HRL' },
    ]

    const fetchPriceById = (id: string) => {
        getPricePlanById(id)
            .then((res: any) => {
                setPriceDetail(res?.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    
    useEffect(() => {
        if (id) {
            fetchPriceById(id)
        }
    }, [id])

    return (
        <div className="flex flex-col">
            <LoadingPage loading={loading} />
            <div className="flex flex-row w-full justify-between p-4">
                <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                    <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                    <Typography variant="h5" className="pt-2 text-[#333333]">Price Plan</Typography>
                </div>
            </div>
            <div className="flex flex-col p-4 mx-8">
                <div className="flex flex-row h-4 z-40">
                    <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                        Hub Details
                    </span>
                </div>
                <div className="flex flex-col border border-[#EDBA37] rounded-lg pt-6 h-fit">
                    <div className="grid grid-cols-5 gap-4 p-4">
                        <div>
                            <InputLabel className="text-sm -mb-2">Country*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="email"
                                name="email"
                                value={selectedName}
                                onChange={(e) => setSelectedName(e.target.value)}
                            />
                        </div>
                        <div>
                            <InputLabel className="text-sm -mb-2">State*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="email"
                                name="email"
                                value={selectedName}
                                onChange={(e) => setSelectedName(e.target.value)}
                            />
                        </div>
                        <div>
                            <InputLabel className="text-sm -mb-2">Name*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="email"
                                name="email"
                                value={selectedName}
                                onChange={(e) => setSelectedName(e.target.value)}
                            />
                        </div>
                        <div>
                            <InputLabel className="text-sm -mb-2">Currency*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="email"
                                name="email"
                                value={selectedName}
                                onChange={(e) => setSelectedName(e.target.value)}
                            />
                        </div>

                        <div>
                            <InputLabel className="text-sm -mb-2">User Types*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="email"
                                name="email"
                                value={selectedName}
                                onChange={(e) => setSelectedName(e.target.value)}
                            />
                        </div>
                        <div>
                            <InputLabel className="text-sm -mb-2">User Types*</InputLabel>
                            <TextField
                                className="w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="email"
                                name="email"
                                value={selectedName}
                                onChange={(e) => setSelectedName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row-reverse p-4">
                        <Button className="w-42 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#B6A269' }}>
                            <FontAwesomeIcon icon={faSave} />
                            Save Changes
                        </Button>
                    </div>
                </div>
            </div>
            <div className="p-4">
                <div className="flex flex-row">
                    {tab.map((tabs) => (
                        <div onClick={() => setActiveTab(tabs.value as tabType)}
                            className={`hover:cursor-pointer hover:opacity-80 
                        ${tabs.value === activeTab ? 'bg-[#0A2638]' : 'bg-[#EDBA37]'}
                        ${tabs.value === activeTab ? 'text-[#EDBA37]' : 'text-[#0A2638]'}
                        px-8 py-4 drop-shadow-xl rounded-t-lg`}>
                            {tabs.label}
                        </div>
                    ))}
                </div>
                {activeTab === 'ARR' && (<AirportArrival />)}
                {activeTab === 'DEP' && (<AirportDeparture />)}
                {activeTab === 'P2P' && (<PointToPoint />)}
                {activeTab === 'HRL' && (<Hourly />)}
                
            </div>

        </div>
    )
}

export default EditSellingPricePage