import React, { useState } from "react"
import LoadingPage from "../../common/Loading.page"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation, useNavigate } from "react-router-dom"
import { faChevronCircleLeft, faSearch } from "@fortawesome/free-solid-svg-icons"
import { Button, Grid, TextField, Typography } from "@mui/material"
import { DriverListItem } from "../User.model"
import dayjs from "dayjs"

const DriverDetailsPage = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const location = useLocation();
    const state = location.state as DriverListItem


    return (
        <div className="flex flex-col">
            <LoadingPage loading={loading} />
            <div className="flex flex-row w-full justify-between p-4">
                <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                    <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                    <Typography variant="h5" className="pt-2 text-[#333333]">Driver Information</Typography>
                </div>
            </div>
            <div className="flex flex-col p-4 mx-8">
                <div className="flex flex-row h-4 z-40">
                    <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                        Basic Information
                    </span>
                </div>
                <div className="flex flex-col border border-[#EDBA37] rounded-lg pt-6 gap-4 h-fit">
                    <div className="grid grid-rows-4 grid-flow-col gap-4 p-4">
                        <div className="flex">
                            <span className="w-1/2">Name</span>
                            <span>: {state?.driverName}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">NIRC</span>
                            <span>: {state?.nric}</span>
                        </div>
                        <div className="flex">

                            <span className="w-1/2">DOB</span>
                            <span>: {dayjs(state?.dateOfBirth).format('DD MMMM YYYY')}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">Email</span>
                            <span>: {state?.email}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">Gender</span>
                            <span>: {state?.gender}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">Phone Number</span>
                            <span>: {state?.phoneNumber}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">Address</span>
                            <span>: {state?.address}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">Postal Code</span>
                            <span>: {state?.postalCode}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">Country</span>
                            <span>: {state?.country}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">State</span>
                            <span>: {state?.state}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">City</span>
                            <span>: {state?.city}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">Category</span>
                            <span>: {state?.category}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">Hired Date</span>
                            <span>: {dayjs(state?.hiredDate).format('DD MMMM YYYY')}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col p-4 mx-8">
                <div className="flex flex-row h-4 z-40">
                    <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                        License Detail
                    </span>
                </div>
                <div className="flex flex-col border border-[#EDBA37] rounded-lg pt-6 gap-4 h-fit">
                    <div className="grid grid-rows-2 grid-flow-col gap-4 p-4">
                        <div className="flex">
                            <span className="w-1/3">
                                License Number </span>
                            <span>: {state?.licenceDetails?.licenseNumber}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/3">
                                Place of Issued</span>
                            <span>: {state?.licenceDetails.placeOfIssued}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/3">Date of Issued</span>
                            <span>: {state?.licenceDetails?.dateOfIssued}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/3">Expiry Date</span>
                            <span>: {state?.licenceDetails?.expirationDate}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col p-4 mx-8">
                <div className="flex flex-row h-4 z-40">
                    <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                        Bank Detail
                    </span>
                </div>
                <div className="flex flex-col border border-[#EDBA37] rounded-lg pt-6 gap-4 h-fit">
                    <div className="grid grid-rows-2 grid-flow-col gap-4 p-4">
                        <div className="flex">
                            <span className="w-1/3">
                                Account Number </span>
                            <span>: {state?.bankDetails?.accountNumber}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/3">
                                Account Name </span>
                            <span>: {state?.bankDetails?.accountName}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/3">
                                Type of Account </span>
                            <span>: {state?.bankDetails?.accountType}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/3">
                                Bank Name </span>
                            <span>: {state?.bankDetails?.bankName}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/3">
                                Branch Code </span>
                            <span>: {state?.bankDetails?.branchCode}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DriverDetailsPage