import { Button, InputLabel, TextField } from "@mui/material"
import React, { useState } from "react"
import { SecondTabType } from "../../data/form-data"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from "@fortawesome/free-solid-svg-icons"

const AirportArrival = () => {
    const [tabActive, setTabActive] = useState<SecondTabType>('EXTRA_CHARGE')
    const bottomMenu = [{ label: 'Extra Charge', value: 'EXTRA_CHARGE' }, { label: 'Add On Service', value: 'ADD_ON_SERVICE' }]
    const header = ['Airport', 'Zone', 'Economy 4 seater', 'Economy 6 seater', 'Comfort 7 - Toyota Noah',
        'Comfort 5 seater TOYOTA CAMRY OR EQUIVALENT', 'Bus 13 Seater Hiace', 'Business Sedan E CLASS', 'Business Mpv Alphard',
        'Business Van V Class', 'First Class - Mercedes S class', '40 Seater Bus', 'MERCEDES V Class - Tw',
        'Toyota Alphard - malaysia', 'MERCEDES - EQE E CLASS', 'Toll Rate'
    ]
    return (
        <div className="border-2 border-neutral-700 rounded-lg w-full h-fit p-4">
            <div className="grid grid-cols-6 grid-flow-row gap-4 p-4">
                {header.map((val) => (
                    <div>
                        <InputLabel className="text-sm -mb-2">{val}*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            onChange={(e) => { }}
                        />
                    </div>
                ))}
            </div>
            <div className="flex flex-row px-4">
                {bottomMenu.map((tabs) => (
                    <div onClick={() => setTabActive(tabs.value as SecondTabType)}
                        className={`hover:cursor-pointer hover:opacity-80 
                        ${tabs.value === tabActive ? 'bg-[#0A2638]' : 'bg-[#EDBA37]'}
                        ${tabs.value === tabActive ? 'text-[#EDBA37]' : 'text-[#0A2638]'}
                        px-8 py-4 drop-shadow-xl rounded-t-lg`}>
                        {tabs.label}
                    </div>
                ))}
            </div>
            {tabActive === 'EXTRA_CHARGE' && (
                <div className="grid grid-cols-6 grid-flow-row gap-4 p-4 border-2 border-neutral-700 rounded-lg mx-4">
                    <div>
                        <InputLabel className="text-sm -mb-2">Extra Charge Name*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            onChange={(e) => { }}
                        />
                    </div>
                    <div>
                        <InputLabel className="text-sm -mb-2">From*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            onChange={(e) => { }}
                        />
                    </div>
                    <div>
                        <InputLabel className="text-sm -mb-2">To*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            onChange={(e) => { }}
                        />
                    </div>
                    <div>
                        <InputLabel className="text-sm -mb-2">Type*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            onChange={(e) => { }}
                        />
                    </div>
                    <div>
                        <InputLabel className="text-sm -mb-2">Rate*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            onChange={(e) => { }}
                        />
                    </div>
                </div>
            )}
            {tabActive === 'ADD_ON_SERVICE' && (
                <div className="grid grid-cols-6 grid-flow-row gap-4 p-4 border-2 border-neutral-700 rounded-lg mx-4">
                    <div>
                        <InputLabel className="text-sm -mb-2">Addon Service*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            onChange={(e) => { }}
                        />
                    </div>
                    <div>
                        <InputLabel className="text-sm -mb-2">Rate*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="email"
                            name="email"
                            onChange={(e) => { }}
                        />
                    </div>
                </div>
            )}
            <div className="flex flex-row-reverse p-4">
                <Button className="w-42 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#B6A269' }}>
                    <FontAwesomeIcon icon={faSave} />
                    Save
                </Button>
            </div>
        </div>
    )
}

export default AirportArrival