import { faChevronCircleLeft, faEye, faFilePen, faPlus, faRightToBracket, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, CardContent, MenuItem, Paper, Select, SelectChangeEvent, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { DriverListItem, SupplierListItem } from "./User.model";
import { getDriverBySupplierId } from "./services/User.services";
import LoadingPage from "../common/Loading.page";

const DriverManagement = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(location.search);
    const id: string = queryParams.get('id') ?? ''

    const [loading, setLoading] = useState<boolean>(false)
    const [companyName, setCompanyName] = useState<any>('')
    const [driverList, setDriverList] = useState<DriverListItem[]>([])
    const fetchDriverList = (supplierId: string) => {
        setLoading(true)
        getDriverBySupplierId(supplierId)
        .then((res) => {    
            setDriverList(res?.data?.driverDetails)
            setCompanyName(res?.data?.companyDetails?.companyName)
        })
        .catch((err) => console.log(err)
        )
        .finally(() => {
            setLoading(false)
        })
    }
    

    useEffect(() => {
        
        if(id){
            fetchDriverList(id)
        }
    }, [id])
    return (
        <div className="flex flex-col">
            <LoadingPage loading={loading}/>
            <div className="flex flex-row w-full justify-between p-4">
                <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                    <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                    <Typography variant="h5" className="pt-2 text-[#333333]">Back</Typography>
                </div>
            </div>
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-72 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Driver Management</Typography>
                    <span>Company: <span className="font-bold">{companyName}</span></span>
                </div>
                <div className="flex flex-row p-4 justify-center w-full gap-8 -ml-72">
                    <TextField
                        id="outlined-basic"
                        placeholder="Search Name/Email/Phone/Supplier Name"
                        variant="outlined"
                        size="small"
                        className="rounded-lg w-[30%]"
                    />
                    <Button variant="contained" style={{ backgroundColor: '#0A2638' }}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </div>
            </div>
            <div className="flex flex-row-reverse px-20 gap-4">
                <Button className="w-32" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}>
                    Export
                </Button>
            </div>
            <div>
                <Box>
                    <CardContent>
                        <TableContainer component={Paper} className="px-8">
                            <span className="text-sm">{`Total Driver: ${driverList.length}`}</span>
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>DRIVER NAME</TableCell>
                                        <TableCell>SUPPLIER NAME</TableCell>
                                        <TableCell>EMAIL</TableCell>
                                        <TableCell>CATEGORY</TableCell>
                                        <TableCell>PHONE</TableCell>
                                        <TableCell>ACTION</TableCell>
                                        <TableCell>STATUS</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        driverList.map((val, idx) => (
                                            <TableRow
                                                key={val.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {val.id}
                                                </TableCell>
                                                <TableCell>{val.driverName}</TableCell>
                                                <TableCell>{companyName}</TableCell>
                                                <TableCell>{val.email}</TableCell>
                                                <TableCell>{val.category}</TableCell>
                                                <TableCell>{val.phoneNumber}</TableCell>
                                                <TableCell>
                                                    <div className="flex w-full gap-2">
                                                        <FontAwesomeIcon icon={faEye} className="hover:cursor-pointer h-6 w-6 pl-4"
                                                            onClick={() => {
                                                                navigate(`/user/driver-management/detail?id=${val.id}`, {state: val})
                                                            }} />
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <Switch
                                                        size="small"
                                                        checked={false}
                                                        onChange={() => { }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>

            </div>
        </div>
    )
}

export default DriverManagement