import { faChevronCircleLeft, faImage } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { Button, Checkbox, InputLabel, TextField, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import LoadingPage from "../../../common/Loading.page"

const EditVehicleTypePage = () => {

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate()
    const [countryList, setCountryList] = useState<any[]>([])
    const [stateList, setStateList] = useState<any[]>([])
    const [cityList, setCityList] = useState<any[]>([])
    const [showPw, setShowPw] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [pageAction, setPageAction] = useState<'ADD' | 'EDIT'>('ADD')

    const initialState: any = {
        id: ""
    }

    const formik = useFormik({
        initialValues: initialState,
        enableReinitialize: true,
        onSubmit: () => {
        },
    });


    return (
        <div className="flex flex-1 flex-col">
            <LoadingPage loading={isLoading} />
            <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-row w-full p-4 justify-between">
                    <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                        <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                        <Typography variant="h5" className="pt-2 text-[#333333]">
                            {`Edit Vehicle Type`}
                        </Typography>
                    </div>
                </div>
                <div className="px-4">
                    <div className="flex flex-row-reverse pr-12">
                        <Button type="submit" variant="contained" style={{ backgroundColor: '#C4A34F' }} className=" w-32">
                            <span className="text-neutral-800 font-semibold">
                                {'Save'}
                            </span>
                        </Button>
                    </div>
                </div>
                <div className="flex flex-col p-4 mx-8">
                    <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                        Vehicle Type
                    </span>
                    <div className="flex flex-row border border-[#EDBA37] rounded-lg p-4 pt-6 gap-4">
                        <div className="grid grid-cols-4 gap-0 p-4 w-full">
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">Vehicle Type*</InputLabel>
                                <TextField
                                    className="w-full h-10"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    id="accountName"
                                    name="accountName"
                                    value={formik.values.accountName}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">No of Passenger*</InputLabel>
                                <TextField
                                    className="w-full h-10"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    id="accountName"
                                    name="accountName"
                                    value={formik.values.accountName}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">No of Hand Carry*</InputLabel>
                                <TextField
                                    className="w-full h-10"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    id="accountName"
                                    name="accountName"
                                    value={formik.values.accountName}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">No of Check In Bag*</InputLabel>
                                <TextField
                                    className="w-full h-10"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    id="accountName"
                                    name="accountName"
                                    value={formik.values.accountName}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">No of Child/Baby/Booster Seats*</InputLabel>
                                <TextField
                                    className="w-full h-10"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    id="accountName"
                                    name="accountName"
                                    value={formik.values.accountName}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">Featured Image*</InputLabel>
                                <div className="flex flex-row gap-2">
                                    <TextField
                                        className="w-full h-10"
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        id="accountName"
                                        name="accountName"
                                        value={formik.values.accountName}
                                        onChange={formik.handleChange}
                                    />
                                    <Button
                                        className="disabled:text-white disabled:cursor-not-allowed h-10 align-middle self-center"
                                        variant="contained"
                                        size="small"
                                        style={{ backgroundColor: '#0A2638' }}>
                                        <FontAwesomeIcon icon={faImage} />
                                    </Button>
                                </div>

                            </div>

                            <div className="flex flex-row w-fit">
                                <Checkbox defaultChecked size="small" name="isFeature" onChange={formik.handleChange} checked={Boolean(formik.values.isFeaturedSupplier)} />
                                <a className="align-middle self-center justify-center">Admin vehicle type</a>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EditVehicleTypePage