import { faEye, faFilePen, faPlus, faRightToBracket, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, CardContent, MenuItem, Paper, Select, SelectChangeEvent, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { CorporateTAListItem, CustomerListItem } from "./User.model";
import { statusList } from "../../utils/GeneralUtils";
import { handleError } from "../../services/axiosInstance";
import { getSupplier } from "./services/User.services";
import LoadingPage from "../common/Loading.page";

const CorporateTAManagement = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [corporateList, setCorporateList] = useState<CustomerListItem[]>([])
    const [status, setStatus] = useState('');

    const fetchCorporateList = () => {
        setLoading(true)
        getSupplier({
            pageableSize: 10,
            pageablePage: 0,
            accountType: 'CORPORATE'
        })
            .then((res) => {
                setCorporateList(res?.data?.elements)
            })
            .catch((err) => {
                handleError(err, navigate)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchCorporateList()
    }, [])
    return (
        <div className="flex flex-col">
            <LoadingPage loading={loading}/>
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Corporate/TA Management</Typography>
                </div>
                <div className="flex flex-row p-4 justify-center w-full gap-8 -ml-72">
                    <TextField
                        id="outlined-basic"
                        placeholder="Search Name/Email/Phone"
                        variant="outlined"
                        size="small"
                        className="rounded-lg w-[30%]"
                    />
                    <Select
                        className="w-32"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        size="small"
                        defaultValue={status === '' ? 'status' : ''}
                        onChange={() => { }}
                    >
                        <MenuItem value={'status'}>Status</MenuItem>
                        {statusList.map((_) => (
                            <MenuItem value={_.value}>{_.label}</MenuItem>
                        ))}
                    </Select>
                    <Select
                        className="w-32"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        size="small"
                        defaultValue={status === '' ? 'status' : ''}
                        onChange={() => { }}
                    >
                        <MenuItem value={'status'}>Status</MenuItem>
                        {statusList.map((_) => (
                            <MenuItem value={_.value}>{_.label}</MenuItem>
                        ))}
                    </Select>
                    <Button variant="contained" style={{ backgroundColor: '#0A2638' }}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </div>
            </div>
            <div className="flex flex-row-reverse px-20 gap-4">
                <Button className="w-32" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}>
                    Export
                </Button>
                <Button className="w-32 gap-4" variant="contained" size="small" style={{ backgroundColor: '#C4A34F' }}
                    onClick={() => navigate('/user/corporate-ta-management/add-new')}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    Add New
                </Button>
            </div>
            <div>
                <Box>
                    <CardContent>
                        <TableContainer component={Paper} className="px-8">
                            <span className="text-sm">{`Total Customers: ${corporateList.length}`}</span>
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>COMPANY NAME</TableCell>
                                        <TableCell>PARENT NAME</TableCell>
                                        <TableCell>EMAIL</TableCell>
                                        <TableCell>PHONE</TableCell>
                                        <TableCell>ROLE</TableCell>
                                        <TableCell>ACTION</TableCell>
                                        <TableCell>STATUS</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        corporateList?.map((val, idx) => (
                                            <TableRow
                                                key={val.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {val.id}
                                                </TableCell>
                                                <TableCell>{val.accountName}</TableCell>
                                                <TableCell>{"<val.parentName>"}</TableCell>
                                                <TableCell>{val.email}</TableCell>
                                                <TableCell>{val.phoneNumber}</TableCell>
                                                <TableCell>{val.rolesDetails.rolesName ?? '-'}</TableCell>
                                                <TableCell>
                                                    <div className="flex w-full gap-2" title="Edit">
                                                        <FontAwesomeIcon icon={faFilePen} className="hover:cursor-pointer h-6 w-6 pl-4"
                                                            onClick={() => {
                                                                navigate(`/user/corporate-ta-management/detail?id=${val.id}`)
                                                            }} />
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <Switch
                                                        size="small"
                                                        // checked={val.status}
                                                        onChange={() => { }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>

            </div>
        </div>
    )
}

export default CorporateTAManagement