import { TripDetail } from '../model/Trip.model'

export const tripManagementHeader = [
    {
        label: 'ACTION',
        value: '',
    },
    {
        label: 'CUSTOMER',
        value: 'customer',
    },
    {
        label: 'STATUS REF NO',
        value: 'status_ref_no',
    },
    {
        label: 'VEHICLE TYPE',
        value: 'vehicle_type',
    },
    {
        label: 'TYPE',
        value: 'type',
    },
    {
        label: 'LOCAL PU TIME',
        value: 'local_pu_time',
    },
    {
        label: 'FLIGHT/CRUISE',
        value: 'flight',
    },
    {
        label: 'NAME',
        value: 'name',
    },
    {
        label: 'TRIP',
        value: 'trip',
    },
    {
        label: 'DRIVER-VEHICLE',
        value: 'driver-vehicle',
    }
]

export const PaymentType = [
    { label: 'Manual Payment', value: 'MANUAL' },
    { label: 'Wallet', value: 'WALLET' },
]

export const ArrivalMethod = [
    { label: 'Cruise', value: 'CRUISE' },
    { label: 'Other', value: 'OTHER' },
]

export const BufferTime = [
    { label: '5 minutes', value: '5 minutes' },
    { label: '10 minutes', value: '10 minutes' },
    { label: '15 minutes', value: '15 minutes' },
    { label: '30 minutes', value: '30 minutes' },
    { label: '60 minutes', value: '60 minutes' },
]
export const FlightType = [
    { label: 'International', value: 'INT' },
    { label: 'Domestic', value: 'DOM' },
]

export const UserType = [
    { label: 'Travel Agent', value: 'TRAVELAGENT' },
    { label: 'Corporate', value: 'CORPORATE' },
    { label: 'Customer', value: 'CUSTOMER' },
    { label: 'Guest', value: 'GUEST' },
]

export const ServiceType = [
    { label: 'Airport Departure', value: 'AIRPORT DEPARTURE' },
    { label: 'Airport Arrival', value: 'AIRPORT ARRIVAL' },
    { label: 'Point to Point', value: 'POINT TO POINT' },
    { label: 'Hourly', value: 'HOURLY' },
]

export const userDummy = [
    {
        label: "Alice Smith",
        value: "Alice Smith"
    },
    {
        label: "Bob Johnson",
        value: "Bob Johnson"
    },
    {
        label: "Charlie Davis",
        value: "Charlie Davis"
    },
    {
        label: "Diana Evans",
        value: "Diana Evans"
    },
    {
        label: "Ethan Green",
        value: "Ethan Green"
    },
    {
        label: "Fiona Harris",
        value: "Fiona Harris"
    },
    {
        label: "George Wilson",
        value: "George Wilson"
    },
    {
        label: "Hannah Thompson",
        value: "Hannah Thompson"
    },
    {
        label: "Ivan Martinez",
        value: "Ivan Martinez"
    },
    {
        label: "Julia Anderson",
        value: "Julia Anderson"
    }
]

export const createTripInitialState: TripDetail = {
    basicDetails: {
        id: "",
        name: "",
        email: "",
        phone: "",
        adults: 0,
        childSeat: 0,
        babySeat: 0,
        booster: 0,
        luggage: 0,
        handCarry: 0,
        specialInstruction: "",
        roleType: "",
        accountName: ""
    },
    timeLocationDetails: {
        pickupDate: "",
        pickupTime: "",
        customerReference: "",
        pickUpLocationDetails: {
            placeName: '',
            latitudePickupLocation: 0,
            longitudePickupLocation: 0
        },
        dropOffLocationDetails: {
            placeName: '',
            latitudeDropOffLocation: 0,
            longitudeDropOffLocation: 0
        }
    },
    paymentDetails: {
        id: "",
        paymentType: "",
        transactionId: ""
    },
    arrivalInformationDetails: {
        arrivalMethod: "",
        cruiseName: "",
        disembarkment: "",
        bufferTime: ""
    },
    vehicleDetails: {
        serviceType: "",
        vehicleName: "",
        vehicleAmount: 0,
        vehicleNumber:"",
        vehicleColor:"",
        isManualPrice: false
        
    },
    flightDetails: {
        flightType: "",
        flightNumber: "",
        flightDate: "",
        flightTime: ""
    },
}

export const payloadMapping = (data: any) => {
    const payload: TripDetail = {
        basicDetails: {
            id: data?.basicDetails?.accountName?.id,
            name: data?.basicDetails?.name,
            email: data?.basicDetails?.email,
            phone: data?.basicDetails?.phone,
            adults: data?.basicDetails?.adults,
            childSeat: data?.basicDetails?.childSeat,
            babySeat: data?.basicDetails?.babySeat,
            booster: data?.basicDetails?.booster,
            luggage: data?.basicDetails?.luggage,
            handCarry: data?.basicDetails?.handCarry,
            specialInstruction: data?.basicDetails?.specialInstruction,
            roleType: data?.basicDetails?.roleType,
            accountName: data?.basicDetails?.accountName?.accountName
        },
        timeLocationDetails: data?.timeLocationDetails,
        paymentDetails: data?.paymentDetails,
        arrivalInformationDetails: data?.arrivalInformationDetails,
        vehicleDetails: {
            serviceType: data?.vehicleDetails?.serviceType,
            vehicleName: `${data?.vehicleDetails?.vehicleName?.vehicleBrand} - ${data?.vehicleDetails?.vehicleName?.vehicleModel}`,
            vehicleAmount: data?.vehicleDetails?.vehicleAmount,
            vehicleNumber: data?.vehicleDetails?.vehicleNumber,
            vehicleColor: data?.vehicleDetails?.vehicleColor,
            isManualPrice: data?.vehicleDetails?.isManualPrice
        },
        flightDetails: data?.flightDetails,
        status: "INPROGRESS",
        version: 1,
        createdBy: "system",
        createdAt: new Date().toISOString(),
        updatedBy: null,
        updatedAt: null,
        deletedBy: null,
        deletedAt: null,
    }

    return payload
}

export const loadScript = (url: string, callback: () => void) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.defer = true;
    script.onload = callback;
    document.body.appendChild(script);
  };