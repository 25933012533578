import axios from 'axios';
import { toast } from 'react-toastify';
import { performLogout } from '../utils/GeneralUtils';

export const axiosInstance = axios.create({
  baseURL: 'https://api.ugkslimousine.com/ms-data-master-account/api/v1',
});
export const axiosBooking = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL_BOOKING,
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('AUTH_TOKEN');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized errors here (e.g., redirect to login)
    }
    return Promise.reject(error);
  }
);
axiosBooking.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('AUTH_TOKEN');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosBooking.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized errors here (e.g., redirect to login)
    }
    return Promise.reject(error);
  }
);

// export default axiosInstance 

export const handleError = (err: any, navigate: any) => {
  if (err?.response?.status === 403) {
    toast.error("Your session has expired")
    performLogout(navigate)
  }
}
