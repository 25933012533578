import React, { useState } from "react"
import LoadingPage from "../../common/Loading.page"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { Button, InputLabel, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"

const EditRolePage = () => {

    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: (values: any) => {
            console.log(values)
        },
    });

    const [isLoading, setLoading] = useState<boolean>(false)
    return (
        <div className="flex flex-1 flex-col">
            <LoadingPage loading={isLoading} />
            <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-row w-full p-4 justify-between">
                    <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                        <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                        <Typography variant="h5" className="pt-2 text-[#333333]">
                            {`Edit Role`}
                        </Typography>
                    </div>
                </div>
                <div className="px-4">
                    <div className="flex flex-row-reverse pr-12">
                        <Button type="submit" variant="contained" style={{ backgroundColor: '#C4A34F' }} className=" w-32">
                            <span className="text-neutral-800 font-semibold">
                                {'Save'}
                            </span>
                        </Button>
                    </div>
                </div>
                <div className="p-4">
                    <div className="flex flex-col w-fit">
                        <InputLabel className="text-sm -mb-2">Name</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id=""
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EditRolePage