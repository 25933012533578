import axios from "axios"
import api from "../../../services/axiosConfig"
import { LoginParam } from "../model/Login.model"
import { axiosInstance } from "../../../services/axiosInstance"

const LOGIN = '/authentication/login'
const REGISTER = '/authentication/signup'
const DATA_ACCOUNT_URL = '/account/'

export const loginByEmail = (param: LoginParam) => {
    return axiosInstance.post(LOGIN, param)
}

export const register = (param: any) => {
    return axiosInstance.post(REGISTER, param)
}

export const getDataAccount = () => {
    return api.get(DATA_ACCOUNT_URL)
}