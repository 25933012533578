import { faCircleChevronLeft, faLocationDot, faLocationPin } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Autocomplete, AutocompleteRenderInputParams, Button, Checkbox, FormLabel, InputAdornment, InputLabel, MenuItem, Select, TextField, createTheme } from "@mui/material"
import React, { ChangeEvent, SetStateAction, useEffect, useState } from "react"
import { ArrivalMethod, BufferTime, FlightType, PaymentType, ServiceType, UserType, createTripInitialState, loadScript, payloadMapping, userDummy } from "./Data/form-data"
import { useNavigate } from "react-router-dom"
import LoadingPage from "../common/Loading.page"
import { debounce } from "../../utils/GeneralUtils"
import PlacesAutocompleteInput from "./components/PlacesAutocompleteInput"
import { replace, useFormik } from "formik"
import axios from "axios"
import { getVehicleList, submitTrip } from "./services/TripsServices.services"
import { VehicleItem } from "./model/Trip.model"
import { getDataAccount } from "../auth/services/Auth.services"
import { getAllUser, getSupplier } from "../user/services/User.services"
import { SelectModel } from "../common/CommonModel.model"
import AutocompleteInput from "./components/AutocompleteInput"
import { toast } from "react-toastify"
import { AccountType } from "../user/services/UserModel"

const CreateTripPage = () => {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [vehiceList, setVehicleList] = useState<VehicleItem[]>([])
    const [vehicleOptions, setVehicleOptions] = useState<SelectModel[]>([])
    const [accountOptions, setAccountOptions] = useState<SelectModel[]>([])

    const formik = useFormik({
        initialValues: createTripInitialState,
        onSubmit: (values) => {
            setLoading(true)
            submitTrip(payloadMapping(formik?.values))
                .then((res) => {
                    toast.success('Booking created')
                    navigate(-1)
                })
                .catch((err) => {
                    throw new Error(err);
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    })


    const fetchVehicle = () => {
        setLoading(true)
        getVehicleList()
            .then((res: any) => {
                console.log('RES', res)
                setVehicleList(res?.data?.elements)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const fetchAccount = (accountType: AccountType) => {
        setLoading(true)
        getSupplier({ pageableSize: 24, pageablePage: 0, accountType: accountType })
            .then((res: any) => {
                const accountOptionsMap: SelectModel[] = res.data?.elements?.map((e: any) => {
                    return { label: e?.accountName, value: e }
                })
                setAccountOptions(accountOptionsMap)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }


    useEffect(() => {
        const googleApiKey = 'AIzaSyAiPQF2KnUfZpG4DOaoM9j2ouAAWd597oQ' //should use .env file
        if (googleApiKey) {
            loadScript(`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`, () => {
                setScriptLoaded(true);
            });
        }
        fetchVehicle()
        // fetchAccount()
    }, [])

    const handlePickUp = (place: google.maps.places.PlaceResult) => {
        if (place.geometry) {
            const lat = place.geometry.location?.lat();
            const lng = place.geometry.location?.lng();
            formik.setFieldValue('timeLocationDetails.pickUpLocationDetails.placeName', place.name)
            formik.setFieldValue('timeLocationDetails.pickUpLocationDetails.latitudePickupLocation', lat)
            formik.setFieldValue('timeLocationDetails.pickUpLocationDetails.longitudePickupLocation', lng)
        }
    };
    const handleDropOff = (place: google.maps.places.PlaceResult) => {
        if (place.geometry) {
            const lat = place.geometry.location?.lat();
            const lng = place.geometry.location?.lng();
            formik.setFieldValue('timeLocationDetails.dropOffLocationDetails.placeName', place.name)
            formik.setFieldValue('timeLocationDetails.dropOffLocationDetails.latitudeDropOffLocation', lat)
            formik.setFieldValue('timeLocationDetails.dropOffLocationDetails.longitudeDropOffLocation', lng)
        }
    };

    useEffect(() => {
        const vehicleOptions = vehiceList?.map((vehc) => {
            return { label: `${vehc?.vehicleBrand} - ${vehc?.vehicleModel}[${vehc?.vehicleNumber}]`, value: vehc }
        })
        setVehicleOptions(vehicleOptions)
    }, [vehiceList])

    const navigate = useNavigate()
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-1 flex-col h-full p-4">
                <LoadingPage loading={loading} />
                <div className="flex flex-row w-full justify-between">
                    <div className="hover:cursor-pointer flex"
                        onClick={() => { navigate(-1) }}
                    >
                        <FontAwesomeIcon icon={faCircleChevronLeft}
                            className="h-12 w-12 pl-4 text-[#333333]"
                        />
                        <span className="text-2xl text-[#333333] justify-center h-full p-2">Trip Save</span>
                    </div>
                    <Button
                        className="w-32 disabled:text-white disabled:cursor-not-allowed"
                        color="primary"
                        variant="contained"
                        style={{ backgroundColor: '#B6A269' }}
                        size="small"
                        type="submit"
                    >
                        SAVE
                    </Button>
                </div>
                <div className="mt-4 pt-4">
                    <span className="text-neutral-800 font-semibold -mt-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-50 absolute rounded-md">Trip Details</span>
                    <div className="flex flex-row border border-[#EDBA37] rounded-lg p-4 pt-6 gap-4">
                        <div className="flex flex-row w-1/2 overflow-ellipsis">
                            <div className="mt-4 pt-4 w-full">
                                <>
                                    {/* TIME AND LOCATION */}
                                    <span className="text-neutral-800 font-semibold -mt-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-50 absolute rounded-md">
                                        Time and Location
                                    </span>
                                    <div className="flex flex-col border border-[#EDBA37] rounded-lg p-6 pt-6 gap-4">
                                        <div className="flex flex-row justify-between w-full">
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Pick Up Date</span>
                                                <TextField
                                                    className="h-10"
                                                    type="date"
                                                    size="small"
                                                    id="timeLocationDetails.pickupDate"
                                                    name="timeLocationDetails.pickupDate"
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Pick Up Time</span>
                                                <TextField
                                                    className="h-10"
                                                    type="time"
                                                    size="small"
                                                    id="timeLocationDetails.pickupTime"
                                                    name="timeLocationDetails.pickupTime"
                                                    value={formik.values.timeLocationDetails.pickupTime}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Customer Reference</span>
                                                <TextField
                                                    className="h-10"
                                                    type="text"
                                                    size="small"
                                                    id="timeLocationDetails.customerReference"
                                                    name="timeLocationDetails.customerReference"
                                                    value={formik.values.timeLocationDetails.customerReference}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-between gap-4">
                                            <div className="flex flex-col w-1/2" >
                                                <span className="text-sm">Pickup Location</span>
                                                {scriptLoaded ? (
                                                    <AutocompleteInput
                                                        placeholder="Type a location"
                                                        onPlaceSelected={handlePickUp}
                                                    />
                                                ) : (
                                                    <div>Loading Google Maps...</div>
                                                )}
                                            </div>
                                            <div className="flex flex-col w-1/2" >
                                                <span className="text-sm">Drop Off Location</span>
                                                {scriptLoaded ? (
                                                    <AutocompleteInput
                                                        placeholder="Type a location"
                                                        onPlaceSelected={handleDropOff}
                                                    />
                                                ) : (
                                                    <div>Loading Google Maps...</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>

                                <div className="mt-8">
                                    {/* PAYMENT DETAIL */}
                                    <span className="text-neutral-800 font-semibold -mt-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-50 absolute rounded-md">
                                        Payment Detail
                                    </span>
                                    <div className="flex flex-row border border-[#EDBA37] rounded-lg p-4 pt-6 gap-4">
                                        <div className="flex flex-row w-full justify-between gap-4">
                                            <div className="flex flex-col w-1/2" >
                                                <span className="text-sm">Payment Type</span>
                                                <Select size="small" placeholder="Select Payment"
                                                    id="paymentDetails.paymentType"
                                                    name="paymentDetails.paymentType"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.paymentDetails?.paymentType}
                                                >
                                                    {PaymentType.map((payment) => (
                                                        <MenuItem value={payment.value}>{payment.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className="flex flex-col w-1/2" >
                                                <span className="text-sm">Transaction ID</span>
                                                <TextField
                                                    className="h-10"
                                                    type="text"
                                                    size="small"
                                                    id="paymentDetails.transactionId"
                                                    name="paymentDetails.transactionId"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.paymentDetails?.transactionId}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="mt-8">
                                    {/* ARRIVAL INFORMATION */}
                                    <span className="text-neutral-800 font-semibold -mt-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-50 absolute rounded-md">
                                        Arrival Information
                                    </span>
                                    <div className="flex flex-row border border-[#EDBA37] rounded-lg p-4 pt-6 gap-4">
                                        <div className="flex flex-row justify-between w-full gap-2">
                                            <div className="flex flex-col w-1/5" >
                                                <span className="text-sm">Arrival Method</span>
                                                <Select size="small"
                                                    id="arrivalInformationDetails.arrivalMethod"
                                                    name="arrivalInformationDetails.arrivalMethod"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.arrivalInformationDetails.arrivalMethod}
                                                >
                                                    {ArrivalMethod.map((arrival) => (
                                                        <MenuItem value={arrival.value}>{arrival.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Name of Cruise</span>
                                                <TextField
                                                    className="h-10"
                                                    type="text"
                                                    placeholder="Name of Cruise"
                                                    size="small"
                                                    id="arrivalInformationDetails.cruiseName"
                                                    name="arrivalInformationDetails.cruiseName"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.arrivalInformationDetails.cruiseName}
                                                />
                                            </div>
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Time of Disembarkment</span>
                                                <TextField
                                                    className="h-10"
                                                    type="time"
                                                    size="small"
                                                    id="arrivalInformationDetails.disembarkment"
                                                    name="arrivalInformationDetails.disembarkment"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.arrivalInformationDetails.disembarkment}
                                                />
                                            </div>
                                            <div className="flex flex-col w-1/5" >
                                                <span className="text-sm">Buffer Time</span>
                                                <Select size="small"
                                                    id="arrivalInformationDetails.bufferTime"
                                                    name="arrivalInformationDetails.bufferTime"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.arrivalInformationDetails.bufferTime}
                                                >
                                                    {BufferTime.map((buffer) => (
                                                        <MenuItem value={buffer.value}>{buffer.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="mt-8">
                                    {/* Flights Detail */}
                                    <span className="text-neutral-800 font-semibold -mt-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-50 absolute rounded-md">
                                        Flight Detail
                                    </span>
                                    <div className="flex flex-row border border-[#EDBA37] rounded-lg p-4 pt-6 gap-4">
                                        <div className="flex flex-row justify-between w-full gap-2">
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Flight Type</span>
                                                <Select size="small"
                                                    id="flightDetails.flightType"
                                                    name="flightDetails.flightType"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.flightDetails.flightType}
                                                >
                                                    {FlightType.map((arrival) => (
                                                        <MenuItem value={arrival.value}>{arrival.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Flight No</span>
                                                <TextField
                                                    className="h-10"
                                                    type="text"
                                                    placeholder="Flight No"
                                                    size="small"
                                                    id="flightDetails.flightNumber"
                                                    name="flightDetails.flightNumber"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.flightDetails.flightNumber}
                                                />
                                            </div>
                                            <div className="flex flex-col w-1/5" >
                                                <span className="text-sm">Flight Date</span>
                                                <TextField
                                                    className="h-10"
                                                    type="date"
                                                    size="small"
                                                    id="flightDetails.flightDate"
                                                    name="flightDetails.flightDate"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.flightDetails.flightDate}
                                                />
                                            </div>
                                            <div className="flex flex-col w-1/5" >
                                                <span className="text-sm">Flight Time</span>
                                                <TextField
                                                    className="h-10"
                                                    type="time"
                                                    size="small"
                                                    id="flightDetails.flightTime"
                                                    name="flightDetails.flightTime"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.flightDetails.flightTime}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="flex flex-row w-1/2">
                            <div className="mt-4 pt-4 w-full">
                                <>
                                    {/* Basic Detail */}
                                    <span className="text-neutral-800 font-semibold -mt-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-50 absolute rounded-md">
                                        Basic Detail
                                    </span>
                                    <div className="flex flex-col border border-[#EDBA37] rounded-lg p-4 pt-6 gap-4">
                                        <div className="flex flex-row justify-between w-full">
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Name</span>
                                                <TextField
                                                    className="h-10"
                                                    type="text"
                                                    size="small"
                                                    id="basicDetails.name"
                                                    name="basicDetails.name"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.basicDetails.name}
                                                />
                                            </div>
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Email Address</span>
                                                <TextField
                                                    className="h-10"
                                                    type="text"
                                                    size="small"
                                                    id="basicDetails.email"
                                                    name="basicDetails.email"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.basicDetails.email}
                                                />
                                            </div>
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Phone</span>
                                                <TextField
                                                    className="h-10"
                                                    type="text"
                                                    size="small"
                                                    id="basicDetails.phone"
                                                    name="basicDetails.phone"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.basicDetails.phone}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-between w-full gap-2">
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Adults</span>
                                                <TextField
                                                    defaultValue={0}
                                                    className="h-10"
                                                    type="number"
                                                    size="small"
                                                    id="basicDetails.adults"
                                                    name="basicDetails.adults"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.basicDetails.adults}
                                                />
                                            </div>
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Child Seats</span>
                                                <TextField
                                                    defaultValue={0}
                                                    className="h-10"
                                                    type="number"
                                                    size="small"
                                                    id="basicDetails.childSeat"
                                                    name="basicDetails.childSeat"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.basicDetails.childSeat}
                                                />
                                            </div>
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Baby Seats</span>
                                                <TextField
                                                    defaultValue={0}
                                                    className="h-10"
                                                    type="number"
                                                    size="small"
                                                    id="basicDetails.babySeat"
                                                    name="basicDetails.babySeat"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.basicDetails.babySeat}
                                                />
                                            </div>
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Booster</span>
                                                <TextField
                                                    defaultValue={0}
                                                    className="h-10"
                                                    type="number"
                                                    size="small"
                                                    id="basicDetails.booster"
                                                    name="basicDetails.booster"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.basicDetails.booster}
                                                />
                                            </div>
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Luggage</span>
                                                <TextField
                                                    defaultValue={0}
                                                    className="h-10"
                                                    type="number"
                                                    size="small"
                                                    id="basicDetails.luggage"
                                                    name="basicDetails.luggage"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.basicDetails.luggage}
                                                />
                                            </div>
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Hand Carry</span>
                                                <TextField
                                                    defaultValue={0}
                                                    className="h-10"
                                                    type="number"
                                                    size="small"
                                                    id="basicDetails.handCarry"
                                                    name="basicDetails.handCarry"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.basicDetails.handCarry}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-col" >
                                            <span className="text-sm">Special Instructions</span>
                                            <TextField
                                                placeholder="Special Instructions"
                                                className="h-10"
                                                type="text"
                                                size="small"
                                                id="basicDetails.specialInstruction"
                                                name="basicDetails.specialInstruction"
                                                onChange={formik.handleChange}
                                                value={formik.values.basicDetails.specialInstruction}
                                            />
                                        </div>
                                        <div className="flex flex-row justify-between w-full gap-2">
                                            <div className="flex flex-col w-2/5" >
                                                <span className="text-sm">User Type</span>
                                                <Select size="small"
                                                    id="basicDetails.roleType"
                                                    name="basicDetails.roleType"
                                                    onChange={(e) => {
                                                        formik.handleChange(e)
                                                        fetchAccount(e.target.value as AccountType ?? '')
                                                    }}
                                                    value={formik.values.basicDetails.roleType}
                                                >
                                                    {UserType?.map((user) => (
                                                        <MenuItem value={user.value}>{user.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className="flex flex-col w-3/5" >
                                                <span className="text-sm">User</span>
                                                <Autocomplete
                                                    disabled={accountOptions?.length === 0}
                                                    size="small"
                                                    disablePortal
                                                    className="flex flex-1 w-full"
                                                    options={accountOptions}
                                                    getOptionLabel={(option) => option.label}
                                                    onChange={(event, value) => formik.setFieldValue('basicDetails.accountName', value?.value || '')}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            className="flex w-full" {...params}
                                                            value={formik.values.basicDetails.accountName}
                                                        />}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>


                                <div className="mt-8">
                                    {/* Vehicle Detail */}
                                    <span className="text-neutral-800 font-semibold -mt-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-50 absolute rounded-md">
                                        Vehicle Detail
                                    </span>
                                    <div className="flex flex-col border border-[#EDBA37] rounded-lg p-4 pt-6 gap-4">
                                        <div className="flex flex-col justify-between w-full gap-2">
                                            <div className="flex flex-row" >
                                                <Checkbox
                                                    id="vehicleDetails.isManualPrice"
                                                    name="vehicleDetails.isManualPrice"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.vehicleDetails.isManualPrice}
                                                />
                                                <span className="text-sm text-center self-center align-middle">Manual Price</span>
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-between w-full gap-2">
                                            <div className="flex flex-col w-2/5" >
                                                <span className="text-sm">Service Type</span>
                                                <Select size="small"
                                                    id="vehicleDetails.serviceType"
                                                    name="vehicleDetails.serviceType"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.vehicleDetails.serviceType}
                                                >
                                                    {ServiceType.map((service) => (
                                                        <MenuItem value={service.value}>{service.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className="flex flex-col w-2/5" >
                                                <span className="text-sm">Vehicle*</span>
                                                <Select size="small"
                                                    id="vehicleDetails.vehicleName"
                                                    name="vehicleDetails.vehicleName"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.vehicleDetails.vehicleName}
                                                >
                                                    {vehicleOptions.map((vehc) => (
                                                        <MenuItem value={vehc.value}>{vehc.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className="flex flex-col" >
                                                <span className="text-sm">Amount(S$)</span>
                                                <TextField
                                                    placeholder="Ammount"
                                                    className="h-10"
                                                    type="number"
                                                    size="small"
                                                    id="vehicleDetails.vehicleAmount"
                                                    name="vehicleDetails.vehicleAmount"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.vehicleDetails.vehicleAmount}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </form>

    )
}

export default CreateTripPage