import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import LoadingPage from "../common/Loading.page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight, faCalendar, faChevronCircleLeft, faCircleChevronDown, faClock, faLocationDot, faMoneyBill1Wave } from "@fortawesome/free-solid-svg-icons";
import { Modal, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { TripDetail } from "./model/Trip.model";
import dayjs from "dayjs";
import MapWithRoute from "./components/MapWithRoutes";
import { calculateDistanceAndDuration, getDistance, getTripDetail } from "./services/TripsServices.services";

const TripDetailPage = () => {

    const location = useLocation();
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(location.search);
    const [loading, setLoading] = useState<boolean>(false)
    const [tripDetail, setTripDetail] = useState<TripDetail>()
    const [showMaps, setShowMaps] = useState<boolean>(false)
    const [duration, setDuration] = useState<string>('-')
    const [distance, setDistance] = useState<string>('-')

    const id: string = queryParams.get('id') ?? ''

    const fetchTripById = (id: string) => {
        getTripDetail(id)
            .then((res: any) => {
                setTripDetail(res?.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const timeline = [
        { label: 'started', time: 'n/a' },
        { label: 'Arr PU', time: 'n/a' },
        { label: 'On-board', time: 'n/a' },
        { label: 'Arr-DO', time: 'n/a' },
    ]
    
    useEffect(() => {
        if (id) {
            fetchTripById(id)
        }
    }, [id])

    useEffect(() => {
        if (tripDetail?.timeLocationDetails?.pickUpLocationDetails?.latitudePickupLocation) {
            const fetchDistanceAndDuration = async () => {
                try {
                    const origin = {
                        lat: tripDetail?.timeLocationDetails?.pickUpLocationDetails?.latitudePickupLocation,
                        lng: tripDetail?.timeLocationDetails?.pickUpLocationDetails?.longitudePickupLocation
                    }
                    const destination = {
                        lat: tripDetail?.timeLocationDetails?.dropOffLocationDetails?.latitudeDropOffLocation,
                        lng: tripDetail?.timeLocationDetails?.dropOffLocationDetails?.longitudeDropOffLocation
                    }
                    const result: any = await calculateDistanceAndDuration(origin, destination);
                    setDistance(result?.distance ?? '0')
                    setDuration(result?.duration ?? '0')
                } catch (error) {
                    console.error(error);
                }
            };

            fetchDistanceAndDuration();
        }

    }, [tripDetail?.timeLocationDetails])

    return (
        <div className="flex flex-1 flex-col p-4">
            <LoadingPage loading={loading} />
            <Modal
                open={showMaps}
                onClose={() => setShowMaps(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="flex flex-1 flex-col w-screen h-screen justify-center"
            >
                <div className="h-2/3 w-1/2 bg-neutral-800 rounded-lg self-center p-4">
                    <Typography variant="h5" color={"white"}>Route</Typography>
                    <MapWithRoute
                        origin={[
                            tripDetail?.timeLocationDetails?.pickUpLocationDetails?.latitudePickupLocation,
                            tripDetail?.timeLocationDetails?.pickUpLocationDetails?.longitudePickupLocation
                        ]}
                        destination={[
                            tripDetail?.timeLocationDetails?.dropOffLocationDetails?.latitudeDropOffLocation,
                            tripDetail?.timeLocationDetails?.dropOffLocationDetails?.longitudeDropOffLocation
                        ]}

                    />
                </div>
            </Modal>
            <div className="flex flex-row w-full justify-between p-4">
                <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                    <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                    <Typography variant="h5" className="pt-2 text-[#333333]">Back</Typography>
                </div>
            </div>
            <div className="flex flex-col gap-2 border bg-[#e1c375] rounded-lg p-8 w-[60%] self-center">
                <div className="flex flex-col">
                    <span className="text-center w-full font-semibold text-2xl align-middle self-center">Drop Off</span>
                    <div className="flex flex-row justify-center divide-x-2 divide-neutral-700">
                        <div>
                            <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                            <span>{dayjs(tripDetail?.timeLocationDetails?.pickupDate).format('DD MMM YYYY')}</span>
                        </div>
                        <div className="ml-2">
                            <FontAwesomeIcon icon={faClock} className="mx-2" />
                            <span>{tripDetail?.timeLocationDetails?.pickupTime}</span>
                        </div>
                        <div className="ml-2">
                            <FontAwesomeIcon icon={faMoneyBill1Wave} className="mx-2" />
                            <span>{tripDetail?.paymentDetails?.paymentType}</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-evenly">
                    <div className="flex flex-col gap-4 w-[30%]">
                        <FontAwesomeIcon icon={faLocationDot} color="red"
                            className="h-10 w-10 self-center justify-center" />
                        <span className="text-center text-lg font-semibold">
                            {tripDetail?.timeLocationDetails?.pickUpLocationDetails?.placeName}
                        </span>
                    </div>
                    <div className="flex flex-col justify-center w-[40%]">
                        <FontAwesomeIcon icon={faAnglesRight} className="w-10 h-10 self-center" />
                        <span className="text-center text-lg font-semibold mt-2">
                            {`Distance [${distance}]`}
                        </span>
                        <span className="text-center text-lg font-semibold mt-2">
                            {`Duration [${duration}]`}
                        </span>
                    </div>
                    <div className="flex flex-col gap-4 w-[30%]">
                        <FontAwesomeIcon icon={faLocationDot} color="#00008B" className="h-10 w-10 self-center" />
                        <span className="text-center text-lg font-semibold">
                            {tripDetail?.timeLocationDetails?.dropOffLocationDetails?.placeName}
                        </span>
                    </div>
                </div>
                <div className="flex flex-row justify-center">
                    <div className="flex flex-col cursor-pointer hover:opacity-50"
                        onClick={() => setShowMaps(true)}
                    >
                        <span>Show on Maps</span>
                        <FontAwesomeIcon icon={faCircleChevronDown} />
                    </div>

                </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
                <div className="border border-[#e1c375] rounded-lg">
                    <div className="bg-[#e1c375] w-full rounded-t-lg p-2">
                        <span className="text-2xl font-semibold text-neutral-700">
                            {`Billing - [${tripDetail?.paymentDetails.transactionId}]`}
                        </span>
                    </div>
                    <div className="grid grid-rows-2 grid-flow-col mt-2 gap-2 p-2">
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Service City`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {`<<Singapore>>`}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Bill to`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {"<<XXXX>>"}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Customer Reference`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {`${tripDetail?.paymentDetails?.transactionId}`}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col border border-[#e1c375] rounded-lg">
                    <div className="bg-[#e1c375] w-full rounded-t-lg p-2">
                        <span className="text-2xl font-semibold text-neutral-700">
                            {`Status`}
                        </span>
                    </div>
                    <div className="flex flex-1 h-full flex-col p-2 justify-center">
                        <Stepper className="self-center w-full" activeStep={3}>
                            {timeline?.map((item) => (
                                <Step>
                                    <StepLabel>
                                        <div className="flex flex-col">
                                            <span>{item?.label}</span>
                                            <span>{item?.time}</span>
                                        </div>
                                    </StepLabel>

                                </Step>
                            ))}
                        </Stepper>
                    </div>
                </div>

                <div className="border border-[#e1c375] rounded-lg">
                    <div className="bg-[#e1c375] w-full rounded-t-lg p-2">
                        <span className="text-2xl font-semibold text-neutral-700">
                            {`Trip Details`}
                        </span>
                    </div>
                    <div className="grid grid-cols-2 grid-flow-row mt-2 gap-2 p-2">
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Instructions`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.basicDetails?.specialInstruction}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="border border-[#e1c375] rounded-lg">
                    <div className="bg-[#e1c375] w-full rounded-t-lg p-2">
                        <span className="text-2xl font-semibold text-neutral-700">
                            {`Vehicle`}
                        </span>
                    </div>
                    <div className="grid grid-cols-2 grid-flow-row mt-2 gap-2 p-2">
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Schedule`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {`${tripDetail?.vehicleDetails?.serviceType} - ${dayjs(tripDetail?.timeLocationDetails?.pickupDate).format("DD MMM YYYY")}
                                ${tripDetail?.timeLocationDetails.pickupTime}`}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Status `}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.status}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Vehicle`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.vehicleDetails.vehicleName}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Vehicle Color`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.vehicleDetails.vehicleColor}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Driver`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {'<<MAZLAN BIN MEON - (+6586681156)>>'}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Booking Voucher`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {'<<XXXX-XXXX-XXXX-XXXX>>'}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="border border-[#e1c375] rounded-lg">
                    <div className="bg-[#e1c375] w-full rounded-t-lg p-2">
                        <span className="text-2xl font-semibold text-neutral-700">
                            {`Passenger`}
                        </span>
                    </div>
                    <div className="grid grid-cols-2 grid-flow-row mt-2 gap-2 p-2">
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Name`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.basicDetails?.name}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Mobile`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.basicDetails?.phone}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Email`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.basicDetails?.email}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Adult`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.basicDetails?.adults}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Child Seat`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.basicDetails?.childSeat}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Baby Seat`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.basicDetails?.babySeat}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Booster`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.basicDetails?.booster}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Luggage`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.basicDetails?.luggage}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Hand Carry`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.basicDetails?.handCarry}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Flight/Cruise Method`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.arrivalInformationDetails?.arrivalMethod}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Flight/Cruise Number`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.arrivalInformationDetails?.cruiseName}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Disembarkment`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.arrivalInformationDetails?.disembarkment}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Buffer Time`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.arrivalInformationDetails?.bufferTime}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="border border-[#e1c375] rounded-lg">
                    <div className="bg-[#e1c375] w-full rounded-t-lg p-2">
                        <span className="text-2xl font-semibold text-neutral-700">
                            {`Flight Info`}
                        </span>
                    </div>
                    <div className="grid grid-cols-2 grid-flow-row mt-2 gap-2 p-2">
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Flight Type`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.flightDetails?.flightType}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Flight Number`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {tripDetail?.flightDetails?.flightNumber}
                            </span>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-lg font-semibold text-neutral-800">
                                {`Flight Date & Time`}
                            </span>
                            <span className="text-sm font-semibold text-neutral-700]">
                                {`${dayjs(tripDetail?.flightDetails?.flightDate).format('DD MMM YYYY')}
                                 ${(tripDetail?.flightDetails?.flightTime)}`}
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default TripDetailPage