import React from "react"

const NotFound = () => {
    return (
        <div>
            <p>NotFound</p>
        </div>
    )
}

export default NotFound