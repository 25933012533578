// App.tsx
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './modules/home/Home.page';
import LoginPage from './modules/auth/Login.page';
import DashboardPage from './modules/dashboard/Dashboard.page';
import Layout from './modules/common/Layout';
import NotFound from './public-page/NotFound.page';
import SupplierManagement from './modules/user/SupplierManagement.page';
import AddNewSupplierManagement from './modules/user/AddNewSupplierManagement.page';
import DriverManagement from './modules/user/DriverManagement.page';
import CustomerManagement from './modules/user/CustomerManagement.page';
import CorporateTAManagement from './modules/user/CorporateTAManagement.page';
import StaffRoleManagement from './modules/user/StaffRoleManagement.page';
import RoleManagement from './modules/user/RoleManagement.page';
import APIPartnermanagement from './modules/user/APIPartnerManagement.page';
import TripManagementPage from './modules/trips/TripManagement.page';
import CreateTripPage from './modules/trips/CreateTrip.page';
import TripDetailPage from './modules/trips/TripDetail.page';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomerDetailPage from './modules/user/customer/CustomerDetail.page';
import CorporateDetailPage from './modules/user/corporate/CorporateDetail.page';
import StaffRoleDetail from './modules/user/staff-role/StaffRoleDetail.page';
import EditRolePage from './modules/user/role/EditRole.page';
import DriverDetailsPage from './modules/user/driver/DriverDetails.page';
import VehicleManagementPage from './modules/car-type/vehicle-management/list/VehicleManagement.page';
import VehicleTypeManagementPage from './modules/car-type/vehicle-type-management/list/VehicleTypeManagement.page';
import VehicleListPage from './modules/car-type/vehicle-management/list/VehicleList.page';
import VehicleDetailsPage from './modules/car-type/vehicle-management/detail/VehicleDetail.page';
import EditVehicleTypePage from './modules/car-type/vehicle-type-management/edit/EditVehicleType.page';
import SellingPricePage from './modules/user/price/selling-price/SellingPrice.page';
import ExtraSeatCharge from './modules/user/price/extra-seat-charge/ExtraSeatCharge.page';
import EditSellingPricePage from './modules/user/price/selling-price/EditSellingPrice.page';
import EditExtraSeatCharge from './modules/user/price/extra-seat-charge/EditExtraSeatCharge.page';
import ZoneManagementPage from './modules/zone/ZoneMagement.page';
import WaitingTimePage from './modules/zone/WaitingTime.page';
import EditWaitingTimePage from './modules/zone/waiting-time/EditWaitingTime.page';
import EditZoneManagementPage from './modules/zone/zone-management/EditZoneManagement.page';
import DebitTopupPage from './modules/payments/DebitTopup.page';
import AddDebitTopupPage from './modules/payments/AddDebitTopup.page';
import CreditSettlement from './modules/payments/CreditSettlement.page';
import ManageBankPage from './modules/payments/ManageBank.page';
import EditBank from './modules/payments/EditBank.page';
import ManualPaymentSettlement from './modules/payments/ManualPayment.page';
import CouponsManagementPage from './modules/modules/CouponsManagement.page';
import EditCouponPage from './modules/modules/EditCoupon.page';
import DiscountManagementPage from './modules/modules/DiscountManagement.page';
import EditDiscountPage from './modules/modules/EditDiscount.page';
import CountriesManagementPage from './modules/modules/CountriesManagement.page';
import EditCountryPage from './modules/modules/EditCountries.page';
import AddOnServicesManagementPage from './modules/modules/AddOnServices.page';
import EditAddOnServices from './modules/modules/EditAddOnServices.page';
import CurrencyConversionPage from './modules/modules/CurrencyConversion.page';
import EditCurrencyRatePage from './modules/modules/EditCurrencyRate.page';
import AddOnItemsManagementPage from './modules/modules/AddOnItemManagement.page';
import EditAddOnItemsPage from './modules/modules/EditAddOnItems.page';
import SalesReportPage from './modules/reports/SalesReport.page';
import VehicleReportPage from './modules/reports/VehicleReport.page';
import TripReportPage from './modules/reports/TripReport.page';
import DriverReportPage from './modules/reports/DriverReport.page';
import CustomerReportPage from './modules/reports/CustomerReport.page';
import BillingReportPage from './modules/reports/BillingReport.page';
import SettingsPage from './modules/settings/Settings.page';
import AddAPIPartnerPage from './modules/user/api-partner/AddAPIPartner.page';
import AssignDriver from './modules/trips/AssignDriver.page';
import SupplierLayout from './modules/supplier/components/SupplierLayout';
import SupplierDriverManagementPage from './modules/supplier/page/driver/SupplierDriverManagement.page';
import SupplierDriverRatesPage from './modules/supplier/page/driver/SupplierDriverRates.page';
import SupplierPaymentSettlementPage from './modules/supplier/page/driver/SupplierPaymentSettlement.page';
import SupplierVehiclePage from './modules/supplier/page/vehicles/SupplierVehicle.page';
import SupplierWaitingTime from './modules/supplier/page/settings/SupplierWaitingTime.page';
import SupplierExtraSeatChargesPage from './modules/supplier/page/settings/SupplierExtraSeatCharge.page';
import SupplierCostPricePage from './modules/supplier/page/cost-price/SupplierCostPrice.page';
import SupplierTripsPage from './modules/supplier/page/trips/SupplierTrips.page';
import SupplierBillingPage from './modules/supplier/page/billing/SupplierBilling.page';
import AddDriverPage from './modules/supplier/page/driver/AddDriver.page';
import AddDriverRatePage from './modules/supplier/page/driver/AddDriverRate.page';
import AddNewVehiclePage from './modules/supplier/page/vehicles/AddNewVehicle.page';
import PricePlanManagementPage from './modules/supplier/page/cost-price/PricePlanManagement.page';
import NewPricePage from './modules/supplier/page/cost-price/NewPrice.page';
import MyProfilePage from './modules/supplier/page/profile/MyProfile.page';
import { useNavigate } from 'react-router-dom';
import PrivateRoute from './modules/private/private-route';


function App() {
  

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<PrivateRoute />}>
        <Route path="/" element={<Layout />} >
          <Route index path="/dashboard" element={<DashboardPage />} />
          <Route path="/user/supplier-management" element={<SupplierManagement />} />
          <Route path="/user/supplier-management/add-new" element={<AddNewSupplierManagement />} />
          <Route path="/user/supplier-management/detail" element={<AddNewSupplierManagement />} />
          <Route path="/user/driver-management" element={<DriverManagement />} />
          <Route path="/user/driver-management/detail" element={<DriverDetailsPage />} />
          <Route path="/user/customer-management" element={<CustomerManagement />} />
          <Route path="/user/customer-management/add-new" element={<CustomerDetailPage />} />
          <Route path="/user/customer-management/detail" element={<CustomerDetailPage />} />
          <Route path="/user/corporate-ta-management" element={<CorporateTAManagement />} />
          <Route path="/user/corporate-ta-management/add-new" element={<CorporateDetailPage />} />
          <Route path="/user/corporate-ta-management/detail" element={<CorporateDetailPage />} />
          <Route path="/user/staff-role-management" element={<StaffRoleManagement />} />
          <Route path="/user/staff-role-management/add-new" element={<StaffRoleDetail />} />
          <Route path="/user/staff-role-management/detail" element={<StaffRoleDetail />} />
          <Route path="/user/account-management" element={<RoleManagement />} />
          <Route path="/user/account-management/add-new" element={<EditRolePage />} />
          <Route path="/user/account-management/detail" element={<EditRolePage />} />
          <Route path="/user/api-partner-management" element={<APIPartnermanagement />} />
          <Route path="/user/api-partner-management/add" element={<AddAPIPartnerPage />} />
          <Route path="/user/api-partner-management/edit" element={<AddAPIPartnerPage />} />
          <Route path="/trip/trip-management" element={<TripManagementPage />} />
          <Route path="/trip/trip-management/create" element={<CreateTripPage />} />
          <Route path="/trip/trip-management/detail" element={<TripDetailPage />} />
          <Route path="/trip/assign-driver" element={<AssignDriver />} />
          <Route path="/vehicle/vehicle-management" element={<VehicleManagementPage />} />
          <Route path="/vehicle/vehicle-management/list" element={<VehicleListPage />} />
          <Route path="/vehicle/vehicle-management/list/detail" element={<VehicleDetailsPage />} />
          <Route path="/vehicle/vehicle-type-management" element={<VehicleTypeManagementPage />} />
          <Route path="/vehicle/vehicle-type-management/edit" element={<EditVehicleTypePage />} />
          <Route path="/price/selling-price" element={<SellingPricePage />} />
          <Route path="/price/selling-price/add" element={<EditSellingPricePage />} />
          <Route path="/price/selling-price/edit" element={<EditSellingPricePage />} />
          <Route path="/price/extra-seat-charge" element={<ExtraSeatCharge />} />
          <Route path="/price/extra-seat-charge/edit" element={<EditExtraSeatCharge />} />
          <Route path="/price/extra-seat-charge/add" element={<EditExtraSeatCharge />} />
          <Route path="/zone/zone-management" element={<ZoneManagementPage />} />
          <Route path="/zone/zone-management/add" element={<EditZoneManagementPage />} />
          <Route path="/zone/zone-management/edit" element={<EditZoneManagementPage />} />
          <Route path="/zone/waiting-time" element={<WaitingTimePage />} />
          <Route path="/zone/waiting-time/add" element={<EditWaitingTimePage />} />
          <Route path="/zone/waiting-time/edit" element={<EditWaitingTimePage />} />
          <Route path="/payments/debit-topup" element={<DebitTopupPage />} />
          <Route path="/payments/debit-topup/add" element={<AddDebitTopupPage />} />
          <Route path="/payments/debit-topup/edit" element={<AddDebitTopupPage />} />
          <Route path="/payments/credit-settlement" element={<CreditSettlement />} />
          <Route path="/payments/bank-details" element={<ManageBankPage />} />
          <Route path="/payments/bank-details/add" element={<EditBank />} />
          <Route path="/payments/bank-details/edit" element={<EditBank />} />
          <Route path="/payments/manual-payment" element={<ManualPaymentSettlement />} />
          <Route path="/modules/coupons-management" element={<CouponsManagementPage />} />
          <Route path="/modules/coupons-management/add" element={<EditCouponPage />} />
          <Route path="/modules/coupons-management/edit" element={<EditCouponPage />} />
          <Route path="/modules/discount-management" element={<DiscountManagementPage />} />
          <Route path="/modules/discount-management/add" element={<EditDiscountPage />} />
          <Route path="/modules/discount-management/edit" element={<EditDiscountPage />} />
          <Route path="/modules/country-management" element={<CountriesManagementPage />} />
          <Route path="/modules/country-management/add" element={<EditCountryPage />} />
          <Route path="/modules/country-management/edit" element={<EditCountryPage />} />
          <Route path="/modules/add-on-services-management" element={<AddOnServicesManagementPage />} />
          <Route path="/modules/add-on-services-management/add" element={<EditAddOnServices />} />
          <Route path="/modules/add-on-services-management/edit" element={<EditAddOnServices />} />
          <Route path="/modules/add-on-items-management" element={<AddOnItemsManagementPage />} />
          <Route path="/modules/add-on-items-management/add" element={<EditAddOnItemsPage />} />
          <Route path="/modules/add-on-items-management/edit" element={<EditAddOnItemsPage />} />
          <Route path="/modules/currency-conversion" element={<CurrencyConversionPage />} />
          <Route path="/modules/currency-conversion/add" element={<EditCurrencyRatePage />} />
          <Route path="/modules/currency-conversion/edit" element={<EditCurrencyRatePage />} />
          <Route path="/reports/sales-reports" element={<SalesReportPage />} />
          <Route path="/reports/vehicle-reports" element={<VehicleReportPage />} />
          <Route path="/reports/trip-reports" element={<TripReportPage />} />
          <Route path="/reports/driver-reports" element={<DriverReportPage />} />
          <Route path="/reports/customer-reports" element={<CustomerReportPage />} />
          <Route path="/reports/billing-reports" element={<BillingReportPage />} />
          <Route path="/settings" element={<SettingsPage />} />
        </Route>
        </Route>
        <Route path="/" element={<Layout />}>
          
        </Route>
       
        <Route path="/supplier" element={<SupplierLayout />} >
          <Route path="/supplier/driver-management" element={<SupplierDriverManagementPage />} />
          <Route path="/supplier/driver-rates" element={<SupplierDriverRatesPage />} />
          <Route path="/supplier/payment-settlement" element={<SupplierPaymentSettlementPage />} />
          <Route path="/supplier/driver-management" element={<SupplierPaymentSettlementPage />} />
          <Route path="/supplier/driver-management/add-driver" element={<AddDriverPage />} />
          <Route path="/supplier/driver-management/add-driver-rate" element={<AddDriverRatePage />} />
          <Route path="/supplier/vehicle-management" element={<SupplierVehiclePage />} />
          <Route path="/supplier/vehicle-management/add-vehicle" element={<AddNewVehiclePage />} />
          <Route path="/supplier/waiting-time" element={<SupplierWaitingTime />} />
          <Route path="/supplier/extra-seat-charges" element={<SupplierExtraSeatChargesPage />} />
          <Route path="/supplier/cost-price" element={<SupplierCostPricePage />} />
          <Route path="/supplier/cost-price/add-price-plan" element={<PricePlanManagementPage />} />
          <Route path="/supplier/cost-price/new-price" element={<NewPricePage />} />
          <Route path="/supplier/trips" element={<SupplierTripsPage />} />
          <Route path="/supplier/billing" element={<SupplierBillingPage />} />
          <Route path="/supplier/profile" element={<MyProfilePage />} />
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
