import { Box, Button, CardContent, FormLabel, InputLabel, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { tripManagementHeader } from "./Data/form-data";
import { TripList } from "./model/Trip.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faCopy, faDollarSign, faFilePen } from "@fortawesome/free-solid-svg-icons";
import LoadingPage from "../common/Loading.page";
import dayjs from "dayjs";
import { getTripList } from "./services/TripsServices.services";
import { handleError } from "../../services/axiosInstance";

const TripManagementPage = () => {
    const navigate = useNavigate();
    type TripMenuType = '' | '' | 'INPROGRESS' | 'UNCONFIRMED' | 'UNSCHEDULED' | 'CANCELLED' | 'COMPLETED';

    const [selectedMenu, setSelectedMenu] = useState<TripMenuType>('');
    const [tripList, setTripList] = useState<TripList[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);

    const [modalRefund, setModalRefund] = useState<boolean>(false);
    const [selectedTrip, setSelectedTrip] = useState<TripList>();
    const [refundAmount, setRefundAmount] = useState<string>('');
    const [modalCopy, setModalCopy] = useState<boolean>(false);

    const listTripMenu = [
        { label: 'All Trip', value: '' },
        { label: 'In Progress', value: 'INPROGRESS' },
        { label: 'Unconfirmed', value: 'UNCONFIRMED' },
        { label: 'Unscheduled', value: 'UNSCHEDULED' },
        { label: 'Cancelled', value: 'CANCELLED' },
        { label: 'Completed', value: 'COMPLETED' },
    ];

    const fetchTrip = () => {
        setLoading(true);
        getTripList(selectedMenu)
            .then((res: any) => {
                setTripList(res?.data?.elements);
            })
            .catch((err) => {
                handleError(err, navigate);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchTrip();
    }, [selectedMenu]);

    return (
        <div className="flex flex-col">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center">
                    <Typography variant="h5" className="p-4">Trip Management</Typography>
                </div>
            </div>
            <div className="flex flex-1 flex-row justify-between h-full px-4">
                {listTripMenu.map((item, index) => (
                    <div
                        key={index}
                        className={`flex flex-1 rounded-t-lg p-2 py-4 shadow-lg hover:cursor-pointer hover:bg-opacity-90 text-white ${item.value === selectedMenu ? 'bg-[#333333]' : 'bg-[#B6A269]'}`}
                        onClick={() => setSelectedMenu(item.value as TripMenuType)}
                    >
                        <span className="font-semibold">{item.label}</span>
                    </div>
                ))}
            </div>
            <div className={`flex flex-row m-4 justify-between`}>
                <div className="flex flex-row w-1/3 rounded-lg border-2 border-orange-200 p-2 gap-2">
                    <div className="flex flex-col">
                        <FormLabel>Start Date</FormLabel>
                        <TextField size="small" type="date" InputProps={{ style: { height: 24 } }} />
                    </div>
                    <div className="flex flex-col">
                        <FormLabel>End Date</FormLabel>
                        <TextField size="small" type="date" InputProps={{ style: { height: 24 } }} />
                    </div>
                    <Button className="w-32 disabled:text-white disabled:cursor-not-allowed" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}>
                        Search
                    </Button>
                </div>
                <div className="flex flex-row self-center gap-2">
                    <Button className="w-32 mr-2 disabled:text-white disabled:cursor-not-allowed" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}
                        onClick={() => navigate('/trip/trip-management/create')}>
                        Create
                    </Button>
                    <Button className="w-32 disabled:text-white disabled:cursor-not-allowed" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}>
                        Assign
                    </Button>
                    <Button className="w-32 disabled:text-white disabled:cursor-not-allowed" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}>
                        Import
                    </Button>
                </div>
            </div>
            <div>
                <Box>
                    <CardContent>
                        <TableContainer component={Paper} className="px-8">
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead className="bg-neutral-300">
                                    <TableRow>
                                        {tripManagementHeader.map((header, idx) => (
                                            <TableCell key={idx}>{header.label}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tripList?.map((val: TripList, idx) => (
                                        <TableRow
                                            key={val.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <div className="flex flex-col gap-4">
                                                    <div className="flex flex-row gap-4">
                                                        <FontAwesomeIcon
                                                            className="h-5 w-5 cursor-pointer"
                                                            title="Detail"
                                                            icon={faFilePen}
                                                            onClick={() => navigate(`/trip/trip-management/detail?id=${val.id}`)}
                                                        />
                                                        <FontAwesomeIcon 
                                                            className="h-5 w-5 cursor-pointer" 
                                                            title="Assign Driver" 
                                                            icon={faCar} 
                                                            onClick={() => {
                                                                navigate(`/trip/assign-driver?id=${val.id}`)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="flex flex-row gap-4">
                                                        <FontAwesomeIcon
                                                            className="h-5 w-5 cursor-pointer"
                                                            title="Refund"
                                                            icon={faDollarSign}
                                                            onClick={() => {
                                                                setModalRefund(true);
                                                                setSelectedTrip(val);
                                                            }}
                                                        />
                                                        <FontAwesomeIcon
                                                            className="h-5 w-5 cursor-pointer"
                                                            title="Copy"
                                                            icon={faCopy}
                                                            onClick={() => setModalCopy(true)}
                                                        />
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell>{val?.basicDetails?.accountName ?? '-'}</TableCell>
                                            <TableCell>{val?.status}</TableCell>
                                            <TableCell>{val.vehicleDetails?.vehicleName}</TableCell>
                                            <TableCell>{val.vehicleDetails?.serviceType}</TableCell>
                                            <TableCell>{`${dayjs(val?.timeLocationDetails?.pickupDate, 'YYYY-MM-DD').format('DD-MMM-YYYY')} ${val?.timeLocationDetails?.pickupTime}`}</TableCell>
                                            <TableCell>{val?.flightDetails?.flightNumber}</TableCell>
                                            <TableCell>{val?.basicDetails?.name}</TableCell>
                                            <TableCell>
                                                <div className="flex flex-col">
                                                    <span
                                                        title={val?.timeLocationDetails?.pickUpLocationDetails?.placeName}>
                                                        {"From: " + val?.timeLocationDetails?.pickUpLocationDetails?.placeName}
                                                    </span>
                                                    <span title={val?.timeLocationDetails?.dropOffLocationDetails?.placeName}>
                                                        {"To: " + val?.timeLocationDetails?.dropOffLocationDetails?.placeName}
                                                    </span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>
            </div>
            {/* Refund Modal */}
            <Modal open={modalRefund} onClose={() => setModalRefund(false)}>
                <Box className="w-[35%] bg-white shadow-lg">
                    <div className="bg-[#333333] p-2 flex justify-center">
                        <h2 className="text-white">Refund Amount</h2>
                    </div>
                    <div className="p-4">
                        <TextField
                            label="Refund Amount"
                            variant="outlined"
                            fullWidth
                            value={refundAmount}
                            onChange={(e) => setRefundAmount(e.target.value)}
                        />
                        <div className="flex justify-end gap-4 mt-4">
                            <Button onClick={() => setModalRefund(false)}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    // Handle refund logic here
                                    setModalRefund(false);
                                }}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
            {/* Copy Modal */}
            <Modal open={modalCopy} onClose={() => setModalCopy(false)}>
                <Box className="w-[35%] bg-white shadow-lg">
                    <div className="bg-[#333333] p-2 flex justify-center">
                        <h2 className="text-white">Copy Trip</h2>
                    </div>
                    <div className="p-4">
                        {/* Implement the copy functionality */}
                        <div className="flex justify-end gap-4 mt-4">
                            <Button onClick={() => setModalCopy(false)}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    // Handle trip copy logic here
                                    setModalCopy(false);
                                }}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default TripManagementPage;
