import { StaffRoleModel } from "./StaffRole.model";

export const StaffRoleInitialData: StaffRoleModel = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    role: ""
}

export const roleOptionsList: {label: string, value: any}[] = [
    {label: 'Admin', value: 'admin'},
    {label: 'Customer', value: 'customer'},
    {label: 'Supplier', value: 'supplier'},
    {label: 'Guest', value: 'guest'},
    {label: 'Corporate', value: 'corporate'},
    {label: 'Travel Agent', value: 'ta'},
    {label: 'Staff', value: 'staff'},
]