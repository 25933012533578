import { faChevronCircleLeft, faPlus, faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import LoadingPage from "../../common/Loading.page"
import { Circle, GoogleMap, LoadScript, Polygon, useLoadScript } from "@react-google-maps/api"

const EditZoneManagementPage = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const mapRef = useRef<google.maps.Map | null>(null);
    const [paths, setPaths] = useState<google.maps.LatLng[]>([]);
    const onMapClick = useCallback((event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
            setPaths(prevPaths => [...prevPaths!, event.latLng!]);
        }
    }, []);

    // Define the container style
    const containerStyle = {
        width: '100%',
        height: '450px',
        cursor: 'pointer'
    };

    // Define a default center for the map (optional)
    const defaultCenter = {
        lat: 1.3644,
        lng: 103.9915,
    };


    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAiPQF2KnUfZpG4DOaoM9j2ouAAWd597oQ',
        libraries: ["places"],
    });

    const onMapLoad = useCallback((map: google.maps.Map) => {
        mapRef.current = map;
    }, []);

    if (loadError) {
        return (<div>Error loading maps</div>);
    }

    if (!isLoaded) {
        return <div>Loading Maps...</div>;
    }

    return (
        <div className="flex flex-col p-4">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                    <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                    <Typography variant="h5">Edit Zone</Typography>
                </div>
            </div>
            <div className="flex flex-row-reverse px-20 gap-4">
                <Button className="w-32" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}
                    onClick={() => navigate(-1)}
                >
                    <FontAwesomeIcon icon={faSave} className="mr-4" />
                    Save
                </Button>
            </div>

            <div className="flex flex-col p-4 mx-8">
                <div className="flex flex-row h-4 z-40">
                    <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                        Zone Details
                    </span>
                </div>
                <div className="flex flex-row justify-between border border-[#EDBA37] rounded-lg pt-6 py-4 px-4 gap-2 h-fit">
                    <div className="flex flex-col w-60">
                        <InputLabel className="text-sm">Country*</InputLabel>
                        <Select
                            className="flex w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="seat"
                            name="seat"
                            placeholder=""
                        >
                            <MenuItem></MenuItem>
                        </Select>
                    </div>
                    <div className="flex flex-col w-60">
                        <InputLabel className="text-sm">State*</InputLabel>
                        <Select
                            className="flex w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="seat"
                            name="seat"
                            placeholder=""
                        >
                            <MenuItem></MenuItem>
                        </Select>
                    </div>
                    <div className="flex flex-col w-60">
                        <InputLabel className="text-sm">City*</InputLabel>
                        <Select
                            className="flex w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="seat"
                            name="seat"
                            placeholder=""
                        >
                            <MenuItem></MenuItem>
                        </Select>
                    </div>
                    <div className="flex flex-col w-60">
                        <InputLabel className="text-sm">Type*</InputLabel>
                        <Select
                            className="flex w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="seat"
                            name="seat"
                            placeholder=""
                        >
                            <MenuItem></MenuItem>
                        </Select>
                    </div>
                    <div className="flex flex-col w-60">
                        <InputLabel className="text-sm">Name*</InputLabel>
                        <Select
                            className="flex w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="seat"
                            name="seat"
                            placeholder=""
                        >
                            <MenuItem></MenuItem>
                        </Select>
                    </div>
                    <div className="flex flex-col w-60">
                        <InputLabel className="text-sm">Hub Type*</InputLabel>
                        <Select
                            className="flex w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="seat"
                            name="seat"
                            placeholder=""
                        >
                            <MenuItem></MenuItem>
                        </Select>
                    </div>

                </div>
                <div className="flex flex-row-reverse py-4">
                    <Button className="w-64" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}
                        onClick={() => setPaths([])}
                    >
                        Clear Location
                    </Button>
                </div>
                <div className="hover:cursor-pointer">
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={paths.length > 0 ? paths[0] : defaultCenter}
                        zoom={10}
                        onClick={onMapClick}
                        onLoad={onMapLoad}
                        mapContainerClassName="hover: cursor-pointer"
                    >
                        {paths.length > 0 && (
                            <Polygon
                                paths={paths}
                                options={{
                                    fillColor: '#B6A269',
                                    fillOpacity: 0.4,
                                    strokeColor: '#B6A269',
                                    strokeOpacity: 0.8,
                                    strokeWeight: 2
                                }}
                            />
                        )}
                        <Circle
                            center={defaultCenter}
                            radius={2000} // Radius in meters (5 kilometers)
                            options={{
                                fillColor: '#FF0000',
                                fillOpacity: 0.35,
                                strokeColor: '#FF0000',
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                            }}
                        />
                    </GoogleMap>
                </div>
            </div>
        </div>
    )
}

export default EditZoneManagementPage