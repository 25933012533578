import React, { useEffect, useState } from "react"
import Images from "../../utils/ImageUtils"
import { Button, Checkbox, FormLabel, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material"
import { getDataAccount, loginByEmail, register } from "./services/Auth.services"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { CountryCodeList, CountryList } from "../common/DataUtils"
import { CheckBox } from "@mui/icons-material"
import { useFormik } from "formik"
import LoadingPage from "../common/Loading.page"
import { toast } from "react-toastify"

const LoginPage = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [email, setEmail] = useState<any>('')
    const [password, setPassword] = useState<any>('')
    const [isRegister, setIsRegister] = useState<boolean>(false)
    const [showPw, setShowPw] = useState<boolean>(false)
    const [showConfirmPw, setShowConfirmPw] = useState<boolean>(false)
    const [checkedPolicy, setCheckedPolicy] = useState<boolean>(false)
    const fetchAccountData = () => {
        setLoading(true)
        getDataAccount()
            .then((res) => {
                console.log(res)
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const submitLogin = () => {
        setLoading(true)
        const param = {
            email: email,
            password: password
        }
        loginByEmail(param)
            .then((res: any) => {
                if (res.status === 200) {
                    localStorage.setItem('AUTH_TOKEN', res?.data?.token)
                    navigate('/dashboard', { replace: true })
                    toast.success("Success Login!")
                }
            })
            .catch((err: any) => {
                console.log(err)
                toast.error(err?.response?.data?.description ?? 'Something Went Wrong')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const formik = useFormik({
        initialValues: {
            accountName: "",
            companyName: "",
            website: "",
            companyAddress: "",
            email: "",
            password: "",
            re_password: "",
            firstName: "",
            lastName: "",
            contactName: "",
            phoneNumber: "",
            location: "",
            state: "",
            city: "",
            countryCode: ""
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            setLoading(false)
            register(values)
                .then((res: any) => {
                    if (res.status === 200) {
                        console.log('RES', res)
                    }
                })
                .catch((err: any) => {
                    console.log(err)
                })
                .then(() => {
                    setLoading(false)
                })
        }
    })

    return (
        <div className="flex flex-row w-full h-screen z-50">
            <LoadingPage loading={loading}/>
            <div className="flex flex-1 h-full bg-orange-500">
                <img alt="" className="h-full w-full object-cover" src={Images.sideLoginImg} />
                <img alt="" className="z-10 absolute top-8 left-16 h-16" src={Images.primaryLogo} />
            </div>
            <div className="flex flex-1 flex-col justify-between h-screen">
                {isRegister ?
                    (
                        <form onSubmit={formik.handleSubmit}>
                            <div className={`flex flex-col flex-11 h-5/6 justify-center mt-10`}>
                                <Typography className="flex w-full text-center justify-center" variant="h5">Supplier Registration</Typography>
                                <Typography className="flex w-full text-center justify-center" variant="body1">Laready have an account?
                                    <span className="underline cursor-pointer"
                                        onClick={() => setIsRegister(false)}
                                    >
                                        Login
                                    </span>
                                </Typography>
                                <div className="flex w-[75%] flex-col justify-center mb-4 rounded-lg border border-neutral-600 gap-2 self-center p-4">
                                    <div className="self-center flex flex-row w-full gap-2">
                                        <div className="flex flex-1 flex-col h-fit">
                                            <FormLabel className="text-sm">Company Name*</FormLabel>
                                            <TextField
                                                className="w-full"
                                                margin="dense"
                                                size="small"
                                                id="outlined-basic"
                                                placeholder="Company Name"
                                                variant="outlined"
                                                name="companyName"
                                                value={formik.values.companyName}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className="flex flex-1 flex-col h-fit">
                                            <FormLabel className="text-sm">Website</FormLabel>
                                            <TextField
                                                className="w-full"
                                                margin="dense"
                                                size="small"
                                                id="outlined-basic"
                                                placeholder="Company Name"
                                                variant="outlined"
                                                name="website"
                                                value={formik.values.website}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="self-center flex flex-row w-full gap-2">
                                        <div className="flex flex-1 flex-col h-fit">
                                            <FormLabel className="text-sm">Company Address*</FormLabel>
                                            <TextField
                                                className="w-full"
                                                margin="dense"
                                                size="small"
                                                id="outlined-basic"
                                                placeholder="Company Address"
                                                variant="outlined"
                                                name="companyAddress"
                                                value={formik.values.companyAddress}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="self-center flex flex-row w-full gap-2">
                                        <div className="flex flex-1 flex-col h-fit">
                                            <FormLabel className="text-sm">Contact Name*</FormLabel>
                                            <TextField
                                                className="w-full"
                                                margin="dense"
                                                size="small"
                                                id="outlined-basic"
                                                placeholder="Contact Name"
                                                variant="outlined"
                                                name="accountName"
                                                value={formik.values.accountName}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className="flex flex-1 flex-col h-fit">
                                            <FormLabel className="text-sm">Email*</FormLabel>
                                            <TextField className="w-full" margin="dense" size="small" id="outlined-basic" placeholder="Email" variant="outlined"
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="self-center flex flex-row w-full gap-2">
                                        <div className="flex flex-1 flex-col h-fit">
                                            <FormLabel className="text-sm">Password*</FormLabel>
                                            <TextField
                                                className="w-full h-10"
                                                margin="dense"
                                                size="small"
                                                variant="outlined"
                                                placeholder="Password"
                                                id="password"
                                                name="password"
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                type={showPw ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <FontAwesomeIcon icon={showPw ? faEyeSlash : faEye} className="hover:cursor-pointer"
                                                                onClick={() => { setShowPw(e => !e) }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                        <div className="flex flex-1 flex-col h-fit">
                                            <FormLabel className="text-sm">Confirm Password*</FormLabel>
                                            <TextField
                                                className="w-full h-10"
                                                margin="dense"
                                                size="small"
                                                variant="outlined"
                                                placeholder="Confirm Password"
                                                id="password"
                                                name="re_password"
                                                value={formik.values.re_password}
                                                onChange={formik.handleChange}
                                                type={showConfirmPw ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <FontAwesomeIcon icon={showConfirmPw ? faEyeSlash : faEye} className="hover:cursor-pointer"
                                                                onClick={() => { setShowConfirmPw(e => !e) }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="self-center flex flex-row w-full gap-2">
                                        <div className="flex flex-1 flex-col h-fit">
                                            <FormLabel className="text-sm mb-2">Country*</FormLabel>
                                            <Select
                                                className="w-full"
                                                margin="dense" size="small" id="outlined-basic" placeholder="Contact Name" variant="outlined"
                                                name="location"
                                                value={formik.values.location}
                                                onChange={formik.handleChange}
                                            >
                                                {CountryList.map((_) => (
                                                    <MenuItem value={_.label}>{_.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className="flex flex-1 flex-col h-fit">
                                            <FormLabel className="text-sm">State</FormLabel>
                                            <TextField className="w-full" margin="dense" size="small" id="outlined-basic" placeholder="State" variant="outlined"
                                                name="state"
                                                value={formik.values.state}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className="flex flex-1 flex-col h-fit">
                                            <FormLabel className="text-sm">City</FormLabel>
                                            <TextField className="w-full" margin="dense" size="small" id="outlined-basic" placeholder="City" variant="outlined"
                                                name="city"
                                                value={formik.values.city}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="self-center flex flex-row w-full gap-2">
                                        <div className="flex flex-1 flex-col h-fit">
                                            <FormLabel className="text-sm">Mobile*</FormLabel>
                                            <div className="w-full">
                                                <Select
                                                    labelId="countryCode"
                                                    id="countryCode"
                                                    size="small"
                                                    className="h-10 mt-2 w-[30%]"
                                                    defaultValue=""
                                                    name="countryCode"
                                                    value={formik.values.countryCode}
                                                    onChange={formik.handleChange}
                                                >
                                                    {CountryCodeList.map((country) => (
                                                        <MenuItem value={country.value}>{country.label}</MenuItem>
                                                    ))}
                                                </Select>
                                                <TextField
                                                    className="w-[70%] h-10"
                                                    margin="dense"
                                                    size="small"
                                                    variant="outlined"
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    value={formik.values.phoneNumber}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                        </div>
                                        {/* CHAPTCHA GOES HERE */}
                                    </div>
                                    <div className="self-center flex flex-row w-full gap-2">
                                        <div className="flex flex-1 flex-row h-fit">
                                            <Checkbox
                                                onChange={() => { }}
                                                onClick={() => {
                                                    setCheckedPolicy(e => !e)
                                                }}
                                                checked={checkedPolicy}
                                            />
                                            <FormLabel className="text-sm self-center align-middle">
                                                I hereby acknowledge and agree that I have read and understood the
                                                <span
                                                    className="underline font-semibold cursor-pointer pl-1 text-sm"
                                                    onClick={() => {
                                                        window.location.href = "https://www.primeaceslimousine.sg/privacy-policy/"
                                                    }}
                                                >
                                                    Privacy Policy
                                                </span>
                                            </FormLabel>
                                        </div>
                                    </div>
                                </div>
                                {isRegister ? (
                                    <Button disabled={!checkedPolicy} variant="contained" className={`self-center pt-4 ${isRegister && 'hidden'}`} color="warning" type="submit"
                                    >Register</Button>)
                                    :
                                    (<Button variant="contained" className={`self-center pt-4 ${isRegister && 'hidden'}`} color="warning" type="button"
                                        onClick={() => {
                                            submitLogin()
                                        }}
                                    >Login</Button>
                                    )}

                                <Typography className={`self-center pt-2 ${isRegister && 'hidden'}`} variant="caption">
                                    Don't have an account yet?
                                    <span
                                        onClick={() => setIsRegister(true)}
                                        className="underline font-semibold cursor-pointer">
                                        Register
                                    </span>
                                </Typography>
                            </div>
                        </form>
                    )
                    :
                    (
                        <div className={`flex flex-col flex-11 h-5/6 justify-center mt-40`}>
                            <Typography className="flex w-full text-center justify-center" variant="h5">LOGIN</Typography>
                            <div className="flex flex-row justify-center mb-4">
                                <div className="w-1/2 p-4 self-center rounded-lg border border-neutral-600">
                                    <div>
                                        <p>Email / Mobile</p>
                                        <TextField className="w-full" margin="dense" size="small" id="outlined-basic" label="Enter your email / mobile" variant="outlined"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="pt-4 gap-4">
                                        <p>Password</p>
                                        <TextField
                                            className="w-full h-10"
                                            margin="dense"
                                            size="small"
                                            variant="outlined"
                                            id="password"
                                            name="password"
                                            value={password}
                                            onChange={(e) => {setPassword(e.target.value)}}
                                            type={showPw ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <FontAwesomeIcon icon={faEyeSlash} className="hover:cursor-pointer"
                                                            onClick={() => { setShowPw(e => !e) }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label="Enter your password"
                                        />
                                    </div>
                                    <div className="flex flex-row-reverse">
                                        <Typography variant="subtitle1" className="text-end cursor-pointer w-fit self-end">Forgot Password?</Typography>
                                    </div>
                                </div>
                            </div>
                            <Button variant="contained" className="self-center pt-4" color="warning"
                                onClick={() => {
                                    submitLogin()
                                }}
                            >Login</Button>
                            <Typography className="self-center pt-2" variant="caption">
                                Don't have an account yet?
                                <span
                                    onClick={() => setIsRegister(true)}
                                    className="underline font-semibold cursor-pointer">
                                    Register
                                </span>
                            </Typography>
                        </div>

                    )
                }

                <div className="flex flex-col justify-center h-1/6 pt-8">
                    <Typography className="text-center" variant="caption">Copyright © 2024 UGKS, All rights reserved.</Typography>
                </div>
            </div>
        </div>
    )
}

export default LoginPage