import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, CardContent, Typography, Button, Divider, FormLabel, TextField, Autocomplete, Select, MenuItem, Switch } from "@mui/material"
import { timezones, currencies } from "../form-data"
import React from "react"

const GeneralSettings = () => {
    return (
        

        <>
        <div className="flex flex-col gap-4">
                    <div className="flex flex-row">
                        <FormLabel className="flex w-1/6 flex-col justify-center">Store Name</FormLabel>
                        <div className="w-5/6 flex flex-col">
                            <TextField placeholder="Enter Store" value={'UGKS'} size="small" />
                            <span></span>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <FormLabel className="flex w-1/6 flex-col justify-center">Project Type</FormLabel>
                        <div className="w-5/6 flex flex-col">
                            <TextField placeholder="Enter Store" value={'UGKS'} size="small" />
                            <span></span>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <FormLabel className="flex w-1/6 flex-col justify-center">Website URL</FormLabel>
                        <div className="w-5/6 flex flex-col">
                            <TextField placeholder="Enter Store" value={'UGKS'} size="small" />
                            <span></span>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <FormLabel className="flex w-1/6 flex-col justify-center">Application Time Zone</FormLabel>
                        <div className="w-5/6 flex flex-col">
                            <Autocomplete disablePortal options={timezones} size="small"
                                renderInput={(params) => <TextField {...params} label="" />}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <FormLabel className="flex w-1/6 flex-col justify-center">Store Currency</FormLabel>
                        <div className="w-5/6 flex flex-col">
                            <Autocomplete disablePortal options={currencies} size="small"
                                renderInput={(params) => <TextField {...params} label="" />}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <FormLabel className="flex w-1/6 flex-col justify-center">Currency Symbol</FormLabel>
                        <div className="w-5/6 flex flex-col">
                            <TextField placeholder="Currency Symbol" size="small" />
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <FormLabel className="flex w-1/6 flex-col justify-center">Additional Stop Rate (S$):</FormLabel>
                        <div className="w-5/6 flex flex-col">
                            <TextField placeholder="Additional Stop Rate" size="small" />
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <FormLabel className="flex w-1/6 flex-col justify-center">Currency Symbol Alignment</FormLabel>
                        <div className="w-5/6 flex flex-col">
                            <Select size="small">
                                {['Right', 'Left'].map((val: any) => (
                                    <MenuItem>{val}</MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <FormLabel className="flex w-1/6 flex-col justify-center">Tax Percentage:</FormLabel>
                        <div className="w-5/6 flex flex-col">
                            <TextField placeholder="Tax Percentage" size="small" />
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <FormLabel className="flex w-1/6 flex-col justify-center">Default Supplier Markup(in %):</FormLabel>
                        <div className="w-5/6 flex flex-col">
                            <TextField placeholder="Default Supplier Markup(in %" size="small" />
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <FormLabel className="flex w-1/6 flex-col justify-center">Max Time for Sending Request:</FormLabel>
                        <div className="w-5/6 flex flex-col">
                            <TextField placeholder="Max Time for Sending Request:" size="small" />
                            <span className="text-sm">Maximum time in minutes after which Tier 2 drivers gets notification for trip requests</span>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <FormLabel className="flex w-1/6 flex-col justify-center">Driver radius for on location:</FormLabel>
                        <div className="w-5/6 flex flex-col">
                            <TextField placeholder="Driver radius for on location:" size="small" />
                            <span className="text-sm">Set radius in meters for which a driver allowed to click on location</span>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <FormLabel className="flex w-1/6 flex-col justify-center">Driver radius for on location:</FormLabel>
                        <div className="w-5/6 flex flex-col">
                            <Switch />
                        </div>
                    </div>
                </div>
        </>
    )
}

export default GeneralSettings