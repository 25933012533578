import React, { useEffect, useState } from "react"
import LoadingPage from "../../common/Loading.page"
import { useFormik } from "formik";
import { StaffRoleInitialData, roleOptionsList } from "./formData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";

const StaffRoleDetail = () => {


    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const id: string = queryParams.get('id') ?? ''
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [showPw, setShowPw] = useState<boolean>(false)
    const [roleOptions, setRoleOptions] = useState<{ label: string, value: any }[]>([])
    const [pageAction, setPageAction] = useState<'ADD' | 'EDIT'>('ADD')

    const formik = useFormik({
        initialValues: StaffRoleInitialData,
        enableReinitialize: true,
        onSubmit: (values: any) => {
            console.log(values)
        },
    });

    useEffect(() => {
        if (id) {
            setPageAction('EDIT')
        }
        setRoleOptions(roleOptionsList)
    }, [navigate])

    return (
        <div className="flex flex-1 flex-col">
            <LoadingPage loading={isLoading} />
            <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-row w-full p-4 justify-between">
                    <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                        <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                        <Typography variant="h5" className="pt-2 text-[#333333]">
                            {`${pageAction === 'ADD' ? 'Create' : 'Edit'} Staff Role`}
                        </Typography>
                    </div>
                </div>
                <div className="px-4">
                    <div className="flex flex-row-reverse pr-12">
                        <Button type="submit" variant="contained" style={{ backgroundColor: '#C4A34F' }} className=" w-32">
                            <span className="text-neutral-800 font-semibold">
                                {pageAction === 'ADD' ? 'Save' : 'Update'}
                            </span>
                        </Button>
                    </div>
                </div>

                <div className="flex flex-col p-4 mx-8">
                    <div className="flex flex-row h-4 z-40">
                        <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                            Role Detail
                        </span>
                    </div>
                    <div className="flex flex-col border border-[#EDBA37] rounded-lg pt-6 gap-4 h-fit">
                        <div className="flex flex-row gap-4 p-4">
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">First Name*</InputLabel>
                                <TextField
                                    className="w-full h-10"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    id=""
                                />
                            </div>
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">Last Name*</InputLabel>
                                <TextField
                                    className="w-full h-10"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    id=""
                                />
                            </div>
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">Phone*</InputLabel>
                                <div className="flex flex-row">
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={''}
                                        onChange={() => { }}
                                        size="small"
                                        className="h-10 mt-2 w-20"
                                        name=""
                                        defaultValue=""
                                    >
                                        <MenuItem value={'-'}>Select</MenuItem>
                                    </Select>
                                    <TextField
                                        className="w-full h-10"
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        value={''}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">Email Address*</InputLabel>
                                <TextField
                                    className="w-full h-10"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    id="email"
                                    name="email"
                                />
                            </div>
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">Password*</InputLabel>
                                <TextField
                                    className="w-full h-10"
                                    margin="dense"
                                    size="small"
                                    variant="outlined"
                                    id="password"
                                    onChange={formik.handleChange}
                                    type={showPw ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FontAwesomeIcon icon={faEyeSlash} className="hover:cursor-pointer"
                                                    onClick={() => { setShowPw(e => !e) }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="flex flex-col w-1/5">
                                <InputLabel className="text-sm -mb-2">Role*</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={''}
                                    onChange={() => { }}
                                    size="small"
                                    className="h-10 mt-2 w-full"
                                    name=""
                                    defaultValue=""
                                >
                                    {roleOptions?.map((opt) => (
                                        <MenuItem value={opt.value}>{opt.label}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default StaffRoleDetail