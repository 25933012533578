import { useState } from "react"
import LoadingPage from "../common/Loading.page"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MenuItem, Select, Typography } from "@mui/material"
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"

const AssignDriver = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    return (
        <div className="flex flex-1 flex-col p-4">
            <LoadingPage loading={loading} />
            <div className="flex flex-row w-full justify-between p-4">
                <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                    <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                    <Typography variant="h5" className="pt-2 text-[#333333]">Assign Driver</Typography>
                </div>
            </div>
            <div className="flex flex-col p-4 mx-8">
                <div className="flex flex-row h-4 z-40">
                    <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                        Driver List
                    </span>
                </div>
                <div className="flex flex-col border border-[#EDBA37] rounded-lg pt-6 gap-4 h-fit">
                    <div className="grid grid-cols-2 grid-flow-row gap-4 p-4">
                        <div className="flex">
                            <span className="w-1/3">
                                Driver List </span>
                                {/* <Select>
                                    {[{driverName'Driver Name'}]}
                                    <MenuItem>{}</MenuItem>
                                </Select> */}
                            {/* <span>: {state?.bankDetails?.accountNumber}</span> */}
                        </div>
                        <div className="flex">
                            <span className="w-1/3">
                                Account Name </span>
                            {/* <span>: {state?.bankDetails?.accountName}</span> */}
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssignDriver