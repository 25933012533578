import { faPlus, faFilePen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Typography,
  Button,
  Box,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../../common/Loading.page";

const SupplierExtraSeatChargesPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <LoadingPage loading={loading} />
      <div className="flex flex-1 bg-[#F1F2F3] py-4">
        <div className="w-72 flex flex-col justify-center pl-8">
          <Typography variant="h5">Extra Seat Charge</Typography>
        </div>
      </div>
      <div className="flex flex-row-reverse px-20 gap-4">
        <Button
          className="w-32"
          variant="contained"
          size="small"
          style={{ backgroundColor: "#B6A269" }}
          onClick={() => {
            navigate("/price/extra-seat-charge/add");
          }}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-4" />
          Add New
        </Button>
      </div>
      <div>
        <Box>
          <CardContent>
            <TableContainer component={Paper} className="px-8">
              <Table size="small" sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>CUSTOMER TYPE</TableCell>
                    <TableCell>VEHICLE TYPE</TableCell>
                    <TableCell>VALUE</TableCell>
                    <TableCell>ACTION</TableCell>
                    <TableCell>STATUS</TableCell>
                  </TableRow>
                </TableHead>
                {/* <TableBody>
                  <TableRow
                    key={"val.id"}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{"Yes"}</TableCell>
                    <TableCell>{"15"}</TableCell>
                    <TableCell>
                      <div className="flex w-full gap-2">
                        <FontAwesomeIcon
                          icon={faFilePen}
                          className="hover:cursor-pointer h-6 w-6 pl-4"
                          onClick={() => {
                            navigate("/price/extra-seat-charge/edit");
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <Switch
                        size="small"
                        checked={false}
                        onChange={() => {}}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody> */}
                <span>No Extra Seat Charge found!!</span>
              </Table>
            </TableContainer>
          </CardContent>
        </Box>
      </div>
    </div>
  );
};

export default SupplierExtraSeatChargesPage;
