import { faCar, faEye, faFilePen, faPlus, faRightToBracket, faSearch, faUsersLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, MenuItem, Paper, Select, SelectChangeEvent, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { SupplierListItem } from "./User.model";
import LoadingPage from "../common/Loading.page";
import { getAllUser, getSupplier, updateSupplier } from "./services/User.services";
import { UserModel } from "./model/User.model";
import { performLogout } from "../../utils/GeneralUtils";
import { toast } from "react-toastify";

const SupplierManagement = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [status, setStatus] = React.useState('');
    const [ListAllUser, setListAlluser] = useState<UserModel[]>([])

    const statusList = [{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }]

    const handleChange = (event: SelectChangeEvent) => {
        setStatus(event.target.value as string);
    };

    const fetchSupplierList = () => {
        setLoading(true)
        getSupplier({ pageableSize: 20, pageablePage: 0, accountType: 'SUPPLIER' })
            .then((res: any) => {
                setListAlluser(res?.data?.elements)
            })
            .catch((err) => {
                if(err?.response?.status === 403){
                    toast.error("Your session has expired")
                    performLogout(navigate)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleChangeStatusSupplier = (param: any) => {
        
        const body = {
            ...param,
            isActive: !Boolean(param?.isActive),
            roleType: 'owner'
        }
        setLoading(true)
        updateSupplier(body)
        .then((res) => {
            console.log('RES',res)
        })
        .catch((err) => {
            console.log(err)
        })
        .finally(() =>{
            fetchSupplierList()
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchSupplierList()
    }, [])
    return (
        <div className="flex flex-col">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-72 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Supplier Management</Typography>
                </div>
                <div className="flex flex-row p-4 justify-center w-full gap-8 -ml-72">
                    <TextField
                        id="outlined-basic"
                        placeholder="Search Name/Email/Phone"
                        variant="outlined"
                        size="small"
                        className="rounded-lg w-[30%]"
                    />
                    <Select
                        className="w-32"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        size="small"
                        defaultValue={status === '' ? 'status' : ''}
                        onChange={handleChange}
                    >
                        <MenuItem value={'status'}>Status</MenuItem>
                        {statusList.map((_) => (
                            <MenuItem key={_.label} value={_.value}>{_.label}</MenuItem>
                        ))}
                    </Select>
                    <Button variant="contained" style={{ backgroundColor: '#0A2638' }}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </div>
            </div>
            <div className="flex flex-row-reverse px-20 gap-4">
                <Button className="w-32" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}>
                    Export
                </Button>
                <Button className="w-32 gap-4" variant="contained" size="small" style={{ backgroundColor: '#C4A34F' }}
                    onClick={() => navigate('/user/supplier-management/add-new')}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    Add New
                </Button>
            </div>
            <div>
                <TableContainer component={Paper} className="p-8">
                    <span className="text-sm">{`Total Supplier: ${ListAllUser.length}`}</span>
                    <Table size="small" sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>ID</TableCell> */}
                                <TableCell>COMPANY NAME</TableCell>
                                <TableCell>CONTACT NAME</TableCell>
                                <TableCell>EMAIL</TableCell>
                                <TableCell>PHONE</TableCell>
                                <TableCell>COUNTRY</TableCell>
                                <TableCell>GNET ID</TableCell>
                                <TableCell>ACTION</TableCell>
                                <TableCell>STATUS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ListAllUser?.map((val, idx) => (
                                <TableRow
                                    key={val.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {/* <TableCell component="th" scope="row">
                                        {val.id}
                                    </TableCell> */}
                                    <TableCell>{val?.companyDetails?.companyName ?? '-'}</TableCell>
                                    <TableCell>{val?.accountName}</TableCell>
                                    <TableCell>{val?.email ?? '-'}</TableCell>
                                    <TableCell>{val?.phoneNumber ?? '-'}</TableCell>
                                    <TableCell>{val?.companyDetails?.country ?? '-'}</TableCell>
                                    <TableCell>{val?.gNetProviderName ?? '-'}</TableCell>
                                    <TableCell>
                                        <div className="flex w-full gap-2">
                                            <FontAwesomeIcon icon={faFilePen} className="hover:cursor-pointer"
                                                title={`Edit ${val?.accountName}`}
                                                onClick={() => navigate(`/user/supplier-management/detail?id=${val?.id}`)}
                                            />
                                            <FontAwesomeIcon
                                                icon={faRightToBracket} className="hover:cursor-pointer"
                                                title={`Login to ${val?.accountName}`}
                                                onClick={() => { }} />
                                            <FontAwesomeIcon
                                                icon={faUsersLine} className="hover:cursor-pointer"
                                                title={`List Driver`}
                                                onClick={() => {
                                                    navigate(`/user/driver-management?id=${val?.id}`)
                                                }} />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <Switch
                                            size="small"
                                            checked={val.isActive}
                                            onChange={(e) => {handleChangeStatusSupplier(val)}}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default SupplierManagement