import React, { useEffect, useState } from "react"
import LoadingPage from "../../common/Loading.page"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleLeft, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { useLocation, useNavigate } from "react-router-dom"
import { Autocomplete, Button, InputAdornment, InputLabel, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import { CorporateInitialData, DummyOptions } from "./formData"
import { exampleData } from "../customer/formData"

const CorporateDetailPage = () => {

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const id: string = queryParams.get('id') ?? ''
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showPw, setShowPw] = useState<boolean>(false)
    const [pageAction, setPageAction] = useState<'ADD' | 'EDIT'>('ADD')


    const formik = useFormik({
        initialValues: CorporateInitialData,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log(values)
        },
    });

    useEffect(() => {
        if (id) {
            setPageAction('EDIT')
        }
    }, [navigate])


    return (
        <div className="flex flex-1 flex-col">
            <LoadingPage loading={isLoading} />
            <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-row w-full p-4 justify-between">
                    <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                        <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                        <Typography variant="h5" className="pt-2 text-[#333333]">
                            {`${pageAction === 'ADD' ? 'Add' : 'Edit'} Corporate`}
                        </Typography>
                    </div>
                </div>
                <div className="px-4">
                    <div className="flex flex-row-reverse pr-12">
                        <Button type="submit" variant="contained" style={{ backgroundColor: '#C4A34F' }} className=" w-32">
                            <span className="text-neutral-800 font-semibold">
                                {pageAction === 'ADD' ? 'Save' : 'Update'}
                            </span>
                        </Button>
                    </div>
                    <div className="flex flex-col p-4 mx-8">
                        <div className="flex flex-row h-4 z-40">
                            <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                                Business Information
                            </span>
                        </div>

                        {/* BUSINESS INFORMATION */}
                        <div className="flex flex-col border border-[#EDBA37] rounded-lg pt-6 gap-4 h-fit">
                            <div className="flex flex-row gap-4 p-4">
                                <div className="flex flex-col w-fit">
                                    <InputLabel className="text-sm -mb-2">Company Name*</InputLabel>
                                    <TextField
                                        className="w-full h-10"
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        id="companyDetails.companyName"
                                    />
                                </div>
                                <div className="flex flex-col w-fit">
                                    <InputLabel className="text-sm -mb-2">Email Address*</InputLabel>
                                    <TextField
                                        className="w-full h-10"
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        id="companyDetails.companyName"
                                    />
                                </div>
                                <div className="flex flex-col w-fit">
                                    <InputLabel className="text-sm -mb-2">Company Phone Number*</InputLabel>
                                    <div className="flex flex-row gap-2">
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={''}
                                            onChange={() => { }}
                                            size="small"
                                            className="h-10 mt-2 w-20"
                                            name=""
                                            defaultValue=""
                                        >
                                            <MenuItem value={'-'}>Select</MenuItem>
                                        </Select>
                                        <TextField
                                            className="w-full h-10"
                                            margin="dense"
                                            size="small"
                                            variant="outlined"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            value={''}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col w-fit">
                                    <InputLabel className="text-sm -mb-2">Tax</InputLabel>
                                    <TextField
                                        className="w-full h-10"
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        id="email"
                                        name="email"
                                    />
                                </div>
                                <div className="flex flex-col w-1/3">
                                    <InputLabel className="text-sm -mb-2">Company Address</InputLabel>
                                    <TextField
                                        className="w-full h-10"
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        id="password"
                                        onChange={formik.handleChange}
                                        type='text'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* BASIC INFORMATION */}
                    <div className="flex flex-col p-4 mx-8">
                        <div className="flex flex-row h-4 z-40">
                            <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                                Basic Information
                            </span>
                        </div>
                        <div className="flex flex-col border border-[#EDBA37] rounded-lg pt-6 gap-4 h-fit">
                            <div className="flex flex-row gap-4 p-4">
                                <div className="flex flex-col w-fit">
                                    <InputLabel className="text-sm -mb-2">First Name*</InputLabel>
                                    <TextField
                                        className="w-full h-10"
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        id="companyDetails.companyName"
                                    />
                                </div>
                                <div className="flex flex-col w-fit">
                                    <InputLabel className="text-sm -mb-2">Last Name*</InputLabel>
                                    <TextField
                                        className="w-full h-10"
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        id="companyDetails.companyName"
                                    />
                                </div>
                                <div className="flex flex-col w-fit">
                                    <InputLabel className="text-sm -mb-2">Phone*</InputLabel>
                                    <div className="flex flex-row gap-2">
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={''}
                                            onChange={() => { }}
                                            size="small"
                                            className="h-10 mt-2 w-20"
                                            name=""
                                            defaultValue=""
                                        >
                                            <MenuItem value={'-'}>Select</MenuItem>
                                        </Select>
                                        <TextField
                                            className="w-full h-10"
                                            margin="dense"
                                            size="small"
                                            variant="outlined"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            value={''}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col w-fit">
                                    <InputLabel className="text-sm -mb-2">Email*</InputLabel>
                                    <TextField
                                        className="w-full h-10"
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        id="email"
                                        name="email"
                                    />
                                </div>
                                <div className="flex flex-col w-fit">
                                    <InputLabel className="text-sm -mb-2">Password*</InputLabel>
                                    <TextField
                                        className="w-full h-10"
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        id="password"
                                        onChange={formik.handleChange}
                                        type={showPw ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FontAwesomeIcon icon={faEyeSlash} className="hover:cursor-pointer"
                                                        onClick={() => { setShowPw(e => !e) }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div className="flex flex-col w-fit">
                                    <InputLabel className="text-sm -mb-2">Customer Type*</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={''}
                                        onChange={() => { }}
                                        size="small"
                                        className="h-10 mt-2 w-52"
                                        name="tier"
                                        placeholder="Select"
                                        defaultValue=""
                                    >
                                        <MenuItem value={1}>Corporate</MenuItem>
                                        <MenuItem value={2}>Travel Agent</MenuItem>
                                    </Select>
                                </div>
                            </div>
                            <div className="p-4">
                                <div className="flex flex-col w-1/3">
                                    <InputLabel className="text-sm -mb-2">Location*</InputLabel>
                                    <Autocomplete
                                        className="w-full"
                                        options={DummyOptions}
                                        renderInput={(params) => (
                                            <TextField {...params} label={'Search Location...'} variant="outlined" />
                                        )}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        style={{ marginTop: '16px' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* VIEW TRIPS */}
                    <div className={`flex flex-col p-4 mx-8 ${pageAction === 'ADD' ? 'hidden' : 'visible' }`}>
                        <div className="flex flex-row h-4 z-40">
                            <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                                View Trips
                            </span>
                        </div>
                        <div className="flex flex-col border border-[#EDBA37] rounded-lg pt-6 gap-4 h-fit">
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Status & ref no.</TableCell>
                                            <TableCell>Service type</TableCell>
                                            <TableCell>Date & time</TableCell>
                                            <TableCell>Trip amount (S$)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {exampleData.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{row.statusAndRefNo}</TableCell>
                                                <TableCell>{row.serviceType}</TableCell>
                                                <TableCell>{new Date(row.dateTime).toLocaleString()}</TableCell>
                                                <TableCell>{row.tripAmount.toFixed(2)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>

                    {/* VIEW TRIPS */}
                    <div className={`flex flex-col p-4 mx-8 ${pageAction === 'ADD' ? 'hidden' : 'visible' }`}>
                        <div className="flex flex-row h-4 z-40">
                            <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                                Credit Terms
                            </span>
                        </div>
                        <div className="flex flex-row justify-between border border-[#EDBA37] rounded-lg p-6 gap-4 h-fit">
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">Credit Days*</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={''}
                                    onChange={() => { }}
                                    size="small"
                                    className="h-10 mt-2 w-52"
                                    name="tier"
                                    placeholder="Select"
                                    defaultValue=""
                                >
                                    <MenuItem value={1}>Corporate</MenuItem>
                                    <MenuItem value={2}>Travel Agent</MenuItem>
                                </Select>
                            </div>

                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">Unlimited</InputLabel>
                                <Switch
                                    className="w-full h-full self-center justify-center my-auto"
                                    size="small"
                                    checked={false}
                                    onChange={() => { }}
                                />
                            </div>
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">Status</InputLabel>
                                <Switch
                                    className="w-full h-full self-center justify-center my-auto"
                                    size="small"
                                    checked={false}
                                    onChange={() => { }}
                                />
                            </div>
                            <div className="flex flex-col w-fit">
                                <InputLabel className="text-sm -mb-2">Status</InputLabel>
                                <div className="flex flex-col h-full justify-center">
                                    <span className="text-xl font-bold">
                                        {`S$123`}
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-col w-fit justify-center">
                                <Button type="submit" variant="contained" style={{ backgroundColor: '#C4A34F' }} className=" w-32">
                                    <span className="text-neutral-800 font-semibold">
                                        {pageAction === 'ADD' ? 'Save' : 'Update'}
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    )
}

export default CorporateDetailPage