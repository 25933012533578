import React, { useEffect, useState } from "react"
import LoadingPage from "../../../common/Loading.page"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleLeft, faEye, faFilePen, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons"
import { Box, Button, CardContent, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"

const VehicleTypeManagementPage = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)

    const [vehicleTypeList, setVehicleTypeList] = useState<any[]>([])


    useEffect(() => {
        setVehicleTypeList([
            {
                id: '23324',
                vehicleType: 'First Class - Mercedes S class',
                passenger: 2,
                handCarry: 3,
                checkinBag: 4,
                childBabyBooster: 2
            }])
    }, [])
    return (
        <div className="flex flex-col">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Vehicle Type Management</Typography>
                </div>
                <div className="flex flex-row p-4 justify-center w-full gap-8 -ml-72">
                    <TextField
                        id="outlined-basic"
                        placeholder="Search by Supplier Name"
                        variant="outlined"
                        size="small"
                        className="rounded-lg w-[30%]"
                    />
                    <Button variant="contained" style={{ backgroundColor: '#0A2638' }}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </div>
            </div>

            <div>
                <Box>
                    <CardContent>
                        <div className="flex flex-row-reverse gap-4 mb-4">
                            <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}>
                               <FontAwesomeIcon icon={faPlus}/>
                                Add New
                            </Button>
                            <Button className="w-32 disabled:text-white disabled:cursor-not-allowed" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}>
                                Buffer Time
                            </Button>
                        </div>
                        <TableContainer component={Paper} className="px-8">
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>VEHICLE TYPE</TableCell>
                                        <TableCell>NO OF PASSENGERS</TableCell>
                                        <TableCell>NO OF HAND CARRY</TableCell>
                                        <TableCell>NO OF CHECKIN BAG</TableCell>
                                        <TableCell>NO OF CHILD/BABY/BOOSTER SEATS</TableCell>
                                        <TableCell>ACTION</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        vehicleTypeList.map((val, idx) => (
                                            <TableRow
                                                key={idx}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {val.id}
                                                </TableCell>
                                                <TableCell>{val?.vehicleType}</TableCell>
                                                <TableCell>{val?.passenger}</TableCell>
                                                <TableCell>{val?.handCarry}</TableCell>
                                                <TableCell>{val?.checkinBag}</TableCell>
                                                <TableCell>{val?.childBabyBooster}</TableCell>
                                                <TableCell>
                                                    <div className="flex justify-center">
                                                        <FontAwesomeIcon icon={faFilePen} className="hover:cursor-pointer h-6 w-6 pl-4"
                                                            onClick={() => {
                                                                navigate(`/vehicle/vehicle-type-management/edit?id=${val?.id}`)
                                                            }} />
                                                        <Switch
                                                            size="small"
                                                            checked={true}
                                                            onChange={() => { }}
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>

            </div>

        </div>
    )
}

export default VehicleTypeManagementPage