import { faChevronCircleLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Typography,
  Button,
  InputLabel,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../../common/Loading.page";
import { CountryCodeList, CountryList } from "../../../common/DataUtils";
import ContainerPanel from "../../../common/ContainerPanel";

const AddDriverPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  return (
    <div className="flex flex-col">
      <LoadingPage loading={loading} />
      <div className="flex flex-1 bg-[#F1F2F3] p-4">
        <div
          className="flex flex-row gap-4 hover:cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            className={`h-10 w-10 text-[#333333]`}
          />
          <Typography variant="h5">Add Driver</Typography>
        </div>
      </div>
      <div className="flex flex-row-reverse px-20 gap-4">
        <Button
          className="w-32"
          variant="contained"
          size="small"
          style={{ backgroundColor: "#B6A269" }}
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faSave} className="mr-4" />
          Save
        </Button>
      </div>

      <div className="flex flex-col p-4 mx-8">
        <div className="flex flex-row h-4 z-40">
          <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
            Personal Details
          </span>
        </div>
        <div className="flex flex-col justify-between border border-[#EDBA37] rounded-lg pt-6 py-4 px-4 gap-2 h-fit">
          <div className="grid grid-cols-6 gap-2 pb-4">
            {/* DRIVER NAME */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Driver Name*</InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
                placeholder="Partner Name"
              ></TextField>
            </div>
            {/* NRIC */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">
                NRIC(last 4 Digits)
              </InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
              ></TextField>
            </div>

            {/* DOB */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">DOB*</InputLabel>
              <TextField
                type="date"
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
                placeholder="Partner Name"
              ></TextField>
            </div>

            {/* CUOUNTRY */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Country*</InputLabel>
              <Select className="mt-2 h-10">
                {CountryList.map((country) => (
                  <MenuItem value={country.value}>{country.label}</MenuItem>
                ))}
              </Select>
            </div>

            {/* STATE */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">State</InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
              ></TextField>
            </div>

            {/* CITY */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">City</InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
              ></TextField>
            </div>

            {/* POSTAL CODE */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Postal Code</InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
              ></TextField>
            </div>

            {/* EMAIL */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Email</InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
              ></TextField>
            </div>

            {/* MOBILE */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Mobile</InputLabel>
              <div className="flex flex-row gap-2">
                <Select
                  labelId="countryCode"
                  id="countryCode"
                  size="small"
                  className="h-10 mt-2 w-[30%]"
                  name="countryCode"
                  defaultValue=""
                >
                  {CountryCodeList.map((country) => (
                    <MenuItem value={country.value}>{country.label}</MenuItem>
                  ))}
                </Select>
                <TextField
                  className="w-70 h-10"
                  margin="dense"
                  size="small"
                  variant="outlined"
                  id="phoneNumber"
                  name="phoneNumber"
                />
              </div>
            </div>

            {/* GENDER */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Gender</InputLabel>
              <Select className="mt-2 h-10">
                {["Male", "Female"].map((gender) => (
                  <MenuItem value={gender}>{gender}</MenuItem>
                ))}
              </Select>
            </div>

            {/* HIRE DATE */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Hire Date</InputLabel>
              <TextField
                type="date"
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
                placeholder="Partner Name"
              ></TextField>
            </div>

            {/* CATEGORY */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Category</InputLabel>
              <Select className="mt-2 h-10">
                <MenuItem value={""}>Select</MenuItem>
                <MenuItem value={1}>Tier 1</MenuItem>
                <MenuItem value={2}>Tier 2</MenuItem>
                <MenuItem value={3}>Tier 3</MenuItem>
                <MenuItem value={4}>Tier 4</MenuItem>
                <MenuItem value={5}>Tier 5</MenuItem>
              </Select>
            </div>

            {/* ADDRESS */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Address</InputLabel>
              <TextField
                minRows={2}
                multiline
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
              ></TextField>
            </div>

            {/* Photo */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">
                Professional Photo Headshot*
              </InputLabel>
              <TextField
                type="file"
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
              ></TextField>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col p-4 mx-8">
        <div className="flex flex-row h-4 z-40">
          <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
            License Details
          </span>
        </div>
        <div className="flex flex-col justify-between border border-[#EDBA37] rounded-lg pt-6 py-4 px-4 gap-2 h-fit">
          <div className="grid grid-cols-6 gap-2 pb-4">
            {/* DRIVing LIcense */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">
                Driving License No
              </InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
              ></TextField>
            </div>

            {/* Place of issIed */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Place of issued</InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
              ></TextField>
            </div>

            {/* Place of issIed */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Date of issued</InputLabel>
              <TextField
                type="date"
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
              ></TextField>
            </div>

            {/* Date of expired */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Date of Expired</InputLabel>
              <TextField
                type="date"
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
              ></TextField>
            </div>
          </div>
        </div>
      </div>

      <ContainerPanel title={"Emergency Contact Details"}>
        <ContainerPanel title={"Contact Person 1"}>
          <div className="grid grid-cols-6 gap-2 pb-4">
            {/* DRIVing LIcense */}
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">
                Contact Person Name
              </InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
              ></TextField>
            </div>

            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Relationship</InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
              ></TextField>
            </div>

            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">
                Country Code & Mobile
              </InputLabel>
              <div className="flex flex-row gap-2">
                <Select
                  labelId="countryCode"
                  id="countryCode"
                  size="small"
                  className="h-10 mt-2 w-[30%]"
                  name="countryCode"
                  defaultValue=""
                >
                  {CountryCodeList.map((country) => (
                    <MenuItem value={country.value}>{country.label}</MenuItem>
                  ))}
                </Select>
                <TextField
                  className="w-70 h-10"
                  margin="dense"
                  size="small"
                  variant="outlined"
                  id="phoneNumber"
                  name="phoneNumber"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Email</InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
              ></TextField>
            </div>

            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Address</InputLabel>
              <TextField
                multiline
                minRows={2}
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
              ></TextField>
            </div>
          </div>
        </ContainerPanel>
      </ContainerPanel>

      <ContainerPanel title={"Bank Account Details"}>
        <div className="grid grid-cols-6 gap-2 pb-4">
          {/* DRIVER NAME */}
          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">Account Number</InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">Account Name</InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">Type of Account</InputLabel>
            <Select className="mt-2 h-10">
              <MenuItem value={""}>Select</MenuItem>
              <MenuItem value={1}>Current</MenuItem>
              <MenuItem value={2}>Savings</MenuItem>
            </Select>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">Bank Name</InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">Branch Code</InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>
        </div>
      </ContainerPanel>
    </div>
  );
};

export default AddDriverPage;
