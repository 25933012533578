import React, { useState } from "react"
import LoadingPage from "../common/Loading.page"
import { Box, Button, CardContent, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePen, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"

const CreditSettlement = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <div className="flex flex-col py-4">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Credit Settlement Pending</Typography>
                </div>
            </div>
            <div>
                <Box>
                    <CardContent>
                        <div className="flex flex-row-reverse gap-4">
                            <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#B6A269' }}
                                onClick={() => window.location.reload()}
                            >
                                Save
                            </Button>
                            <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}
                                onClick={() => window.location.reload()}
                            >
                                {`Settled (1)`}
                            </Button>
                        </div>
                        <div className="flex flex-row gap-4 mb-4">
                            <TextField
                                className="flex"
                                margin="dense"
                                size="small"
                                type="date"
                                variant="outlined"
                                id="seat"
                                name="seat"
                                placeholder="Remark"
                            ></TextField>
                            <TextField
                                className="flex"
                                margin="dense"
                                size="small"
                                type="date"
                                variant="outlined"
                                id="seat"
                                name="seat"
                                placeholder="Remark"
                            ></TextField>
                            <Button className="w-32 h-fit self-center align-middle disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}
                                onClick={() => window.location.reload()}
                            >
                                {`Search`}
                            </Button>
                            <Button className="w-32 h-fit self-center align-middle disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}
                                onClick={() => window.location.reload()}
                            >
                                {`Export`}
                            </Button>
                        </div>
                        <div className="flex flex-row-reverse">
                            <span>{'Total Records Found: 36'}</span>
                        </div>
                        <TableContainer component={Paper} className="px-8">
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>COMPANY NAME</TableCell>
                                        <TableCell>BOOKING ID</TableCell>
                                        <TableCell>AMOUNT</TableCell>
                                        <TableCell>TRANSACTION ID</TableCell>
                                        <TableCell>PAYMENT TRANSACTION ID</TableCell>
                                        <TableCell>TRANSACTION DATE</TableCell>
                                        <TableCell>STATUS</TableCell>
                                        <TableCell>ACTION</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[...Array(25)].map(() => (
                                        <TableRow
                                            key={'idx'}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell className="w-4"><Checkbox /></TableCell>
                                            <TableCell component="th" scope="row" className="w-56">
                                                {'Packyourbags travel and tour'}
                                            </TableCell>
                                            <TableCell>{`2347`}</TableCell>
                                            <TableCell>{`12`}</TableCell>
                                            <TableCell>{'423'}</TableCell>
                                            <TableCell>{'23743264342'}</TableCell>
                                            <TableCell>{dayjs().format('DD MMM YYYY')}</TableCell>
                                            <TableCell><span className="font-semibold text-red-500">Pending</span></TableCell>
                                            <TableCell><FontAwesomeIcon className="cursor-pointer" icon={faFilePen} /></TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>
            </div>

        </div>
    )
}

export default CreditSettlement