import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, Box, CardContent, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../../common/Loading.page";

const SupplierWaitingTime = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {}, []);

  return (
    <div className="flex flex-col">
      <LoadingPage loading={loading} />
      <div className="flex flex-1 bg-[#F1F2F3] p-4">
        <div className="w-96 flex flex-col justify-center pl-8">
          <Typography variant="h5">Waiting Time Charges</Typography>
        </div>
      </div>
      <div>
        <Box>
          <CardContent>
            <div className="flex flex-row-reverse gap-4 mb-4">
              <Button
                className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2"
                variant="contained"
                size="small"
                style={{ backgroundColor: "#0A2638" }}
                onClick={() => navigate("/zone/waiting-time/add")}
              >
                <FontAwesomeIcon icon={faPlus} />
                Add New
              </Button>
            </div>
            <TableContainer component={Paper} className="px-8">
              <Table size="small" sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>TYPE</TableCell>
                    <TableCell>INTERNATIONAL WAITING TIME</TableCell>
                    <TableCell>DOMESTIC WAITING TIME</TableCell>
                    <TableCell>POINT TO POINT</TableCell>
                    <TableCell>EXTRA CHARGE</TableCell>
                    <TableCell>ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <TableRow
                                        className="hidden"
                                        key={'idx'}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {'Changi Airport'}
                                        </TableCell>
                                        <TableCell>{'Singapore'}</TableCell>
                                        <TableCell>{'Singapore'}</TableCell>
                                        <TableCell>{'Singapore'}</TableCell>
                                        <TableCell>{'Airport'}</TableCell>
                                        <TableCell>
                                            <div className="flex justify-start gap-3">
                                                <FontAwesomeIcon icon={faFilePen} className="hover:cursor-pointer h-6 w-6" title="Edit"
                                                    onClick={() => { }} />
                                                <FontAwesomeIcon icon={faTrash} className="hover:cursor-pointer h-6 w-6" title="Edit"
                                                    onClick={() => { }} />
                                                <Switch
                                                    size="small"
                                                    checked={false}
                                                    onChange={() => { }}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Box>
      </div>
    </div>
  );
};

export default SupplierWaitingTime;
