import React, { useState } from "react"
import LoadingPage from "../common/Loading.page"
import { Box, Button, CardContent, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePen, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"

const ManageBankPage = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <div className="flex flex-col py-4">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Bank Details</Typography>
                </div>
            </div>
            <div>
                <Box>
                    <CardContent>
                        <div className="flex flex-row-reverse gap-4 mb-4">
                            <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#B6A269' }}
                                onClick={() => navigate('/payments/bank-details/add')}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                Add New
                            </Button>
                        </div>
                        <TableContainer component={Paper} className="px-8">
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>BANK</TableCell>
                                        <TableCell>ACCOUNT NUMBER</TableCell>
                                        <TableCell>BANK CODE</TableCell>
                                        <TableCell>BRANCH CODE</TableCell>
                                        <TableCell>SWIFT CODE</TableCell>
                                        <TableCell>ACTION</TableCell>
                                        <TableCell>STATUS</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[...Array(15)].map(() => (
                                        <TableRow
                                            key={'idx'}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" className="w-56">
                                                {'ODBC'}
                                            </TableCell>
                                            <TableCell>{'111111111'}</TableCell>
                                            <TableCell>{'ODBC'}</TableCell>
                                            <TableCell>{'ODBC'}</TableCell>
                                            <TableCell>{'ODBC'}</TableCell>
                                            <TableCell>
                                                <FontAwesomeIcon 
                                                onClick={() => navigate('/payments/bank-details/edit')}
                                                className="cursor-pointer" icon={faFilePen}/>
                                            </TableCell>
                                            <TableCell><Switch/></TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>
            </div>

        </div>
    )
}

export default ManageBankPage