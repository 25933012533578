import axios from "axios"
import api from "../../../services/axiosConfig"
import { AccountDetail, AddNewSupplier } from "../User.model"
import { axiosInstance } from "../../../services/axiosInstance"
import { AccountType } from "./UserModel"

const DATA_ACCOUNT_URL = '/account/'

export const getAllUser = () => {
    return axiosInstance.get('/account/?pageableSize=2&pageablePage=0&sortingPage=ASC')
}

export const getSupplier = (param: any) => {
    return axiosInstance.get(`/account/?pageableSize=${param?.pageableSize ?? 10}&pageablePage=${param?.pageablePage ?? 0}&sortingPage=ASC&accountType=${param?.accountType ?? 'SUPPLIER'}`)
}

export const updateSupplier = (param: any) => {
    return axiosInstance.put(`/account/update/id/${param?.id}`, param)
}

export const createSupplier = (payload: AccountDetail) => {
    return axiosInstance.post(`account/create`, payload)
}

export const getUserById = (id: string) => {
    return axiosInstance.get(`/account/id/${id}`)
}


export const editSupplier = (id: string, payload: AccountDetail) => {
    return axiosInstance.put(`/account/update/id/${id}`, payload)
}

export const getDriverBySupplierId = (id: string) => {
    return axiosInstance.get(`/account/id/${id}`)
}
