import { useNavigate } from "react-router-dom"

export const statusList = [{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }]

export const performLogout = (navigate: any) => {
    localStorage.clear()
    sessionStorage.clear()
    navigate('/login')
}

export function debounce<T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
    let timeout: ReturnType<typeof setTimeout>;
    return (...args: Parameters<T>) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  }

  