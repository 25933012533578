import React, { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleLeft, faFileUpload, faSave, faUpload } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { Button, InputAdornment, InputLabel, MenuItem, Radio, Select, TextField, Typography } from "@mui/material"
import LoadingPage from "../../common/Loading.page"

const AddAPIPartnerPage = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    return (
        <div className="flex flex-col p-4">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                    <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                    <Typography variant="h5">Add Partner</Typography>
                </div>
            </div>
            <div className="flex flex-row-reverse px-20 gap-4">
                <Button className="w-32" variant="contained" size="small" style={{ backgroundColor: '#B6A269' }}
                    onClick={() => navigate(-1)}
                >
                    <FontAwesomeIcon icon={faSave} className="mr-4" />
                    Save
                </Button>
            </div>

            <div className="flex flex-col p-4 mx-8">
                <div className="flex flex-row h-4 z-40">
                    <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                        Partner Details
                    </span>
                </div>
                <div className="flex flex-col justify-between border border-[#EDBA37] rounded-lg pt-6 py-4 px-4 gap-2 h-fit">
                    <div className="grid grid-cols-6 gap-2">
                        <div className="flex flex-col">
                            <InputLabel className="text-sm -mb-2">Partner Name*</InputLabel>
                            <TextField
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="seat"
                                name="seat"
                                placeholder="Partner Name"
                            ></TextField>
                        </div>
                        <div className="flex flex-col">
                            <InputLabel className="text-sm -mb-2">Email*</InputLabel>
                            <TextField
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="seat"
                                name="seat"
                                placeholder="Email"
                            ></TextField>
                        </div>
                        <div className="flex flex-col">
                            <InputLabel className="text-sm -mb-2">Phone</InputLabel>
                            <TextField
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="seat"
                                name="seat"
                                placeholder="Phone Number"
                            ></TextField>
                        </div>
                        <div className="flex flex-col">
                            <InputLabel className="text-sm -mb-2">Password*</InputLabel>
                            <TextField
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="seat"
                                type="password"
                                name="seat"
                                placeholder="Enter Password"
                            ></TextField>
                        </div>
                        <div className="flex flex-col">
                            <InputLabel className="text-sm -mb-2">Key*</InputLabel>
                            <TextField
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="seat"
                                name="seat"
                                placeholder="Key"
                            ></TextField>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddAPIPartnerPage