import React, { useState } from "react"
import LoadingPage from "../common/Loading.page"
import { Autocomplete, AutocompleteRenderInputParams, Box, Button, CardContent, Divider, FormLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { currencies, timezones } from "./form-data"
import GeneralSettings from "./component/GeneralSettings"
import RegistrationContent from "./component/RegistrationContent"

const SettingsPage = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedSettingsMenu, setSelectedSettingsMenu] = useState<'GENERAL' | 'REGISTRATION_CONTENT'>('GENERAL')
    return (
        <div className="flex flex-col py-4">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Settings</Typography>
                </div>
            </div>
            <div className="flex flex-row p-4 gap-4">
                <div className="w-1/4">
                    <Box sx={{ boxShadow: 3, borderRadius: 2 }}>
                        <CardContent className="flex flex-col gap-2">
                            <div
                                className={`flex w-full rounded-lg 
                                ${selectedSettingsMenu === 'GENERAL' ? 'bg-[#B6A269]' : 'border border-[#B6A269]'} p-2 hover:cursor-pointer`}
                                onClick={() => setSelectedSettingsMenu('GENERAL')}>
                                <span>General</span>
                            </div>
                            <div
                                className={`flex w-full rounded-lg 
                                ${selectedSettingsMenu === 'REGISTRATION_CONTENT' ? 'bg-[#B6A269]' : 'border border-[#B6A269]'} p-2 hover:cursor-pointer`}
                                onClick={() => setSelectedSettingsMenu('REGISTRATION_CONTENT')}>
                                <span>Registration Content</span>
                            </div>
                        </CardContent>
                    </Box>
                </div>
                <div className="w-3/4">
                    <Box sx={{ boxShadow: 3, borderRadius: 2 }}>
                        <CardContent className="flex flex-col gap-2 p-4">
                            <div className="flex flex-row w-full">
                                <div className="flex flex-col w-full">
                                    <div className="flex flex-row w-full justify-between mb-4">
                                        <div className="flex flex-col">
                                            <Typography variant="h5">{selectedSettingsMenu === 'GENERAL' ? 'General' : 'Regisration Content'}</Typography>
                                            <span>{selectedSettingsMenu === 'GENERAL' ? 
                                            "Website's General Settings" : "Registration Content"}</span>
                                        </div>
                                        <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}
                                            onClick={() => { }}
                                        >
                                            <FontAwesomeIcon icon={faCheck} />
                                            Save
                                        </Button>
                                    </div>
                                    <Divider />
                                </div>
                            </div>
                            {selectedSettingsMenu === 'GENERAL' && <GeneralSettings/>}
                            {selectedSettingsMenu === 'REGISTRATION_CONTENT' && <RegistrationContent/>}
                        </CardContent>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default SettingsPage