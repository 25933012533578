import { faEye, faFilePen, faPlus, faRightToBracket, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, CardContent, MenuItem, Paper, Select, SelectChangeEvent, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { CorporateTAListItem, CustomerListItem, APIPartnerListItem } from "./User.model";
import { statusList } from "../../utils/GeneralUtils";

const APIPartnermanagement = () => {
    const navigate = useNavigate()
    const [APIPartnerList, setAPIPartnerList] = useState<APIPartnerListItem[]>([])
    const [status, setStatus] = useState('');

    const fetchAPIPartnerList = () => {
        const list: APIPartnerListItem[] = [
            {
              id: 'u1d2n3i4q5',
              name: 'Alice Johnson',
              email: 'alice.johnson@example.com',
              phone: '123-456-7890',
              status: 'active'
            },
            {
              id: 'p6l7m8o9n0',
              name: 'Bob Smith',
              email: 'bob.smith@example.com',
              phone: '234-567-8901',
              status: 'inactive'
            },
            {
              id: 'a1b2c3d4e5',
              name: 'Carol White',
              email: 'carol.white@example.com',
              phone: '345-678-9012',
              status: 'pending'
            },
            {
              id: 'f6g7h8i9j0',
              name: 'David Brown',
              email: 'david.brown@example.com',
              phone: '456-789-0123',
              status: 'active'
            },
            {
              id: 'k1l2m3n4o5',
              name: 'Eva Green',
              email: 'eva.green@example.com',
              phone: '567-890-1234',
              status: 'inactive'
            },
            {
              id: 'p6q7r8s9t0',
              name: 'Frank Black',
              email: 'frank.black@example.com',
              phone: '678-901-2345',
              status: 'pending'
            },
            {
              id: 'u1v2w3x4y5',
              name: 'Grace Adams',
              email: 'grace.adams@example.com',
              phone: '789-012-3456',
              status: 'active'
            },
            {
              id: 'z6a7b8c9d0',
              name: 'Henry Clark',
              email: 'henry.clark@example.com',
              phone: '890-123-4567',
              status: 'inactive'
            },
            {
              id: 'e1f2g3h4i5',
              name: 'Ivy Stewart',
              email: 'ivy.stewart@example.com',
              phone: '901-234-5678',
              status: 'pending'
            },
            {
              id: 'j6k7l8m9n0',
              name: 'Jack White',
              email: 'jack.white@example.com',
              phone: '012-345-6789',
              status: 'active'
            }
          ]

        setAPIPartnerList(list)
    }

    useEffect(() => {
        fetchAPIPartnerList()
    }, [])
    return (
        <div className="flex flex-col">
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center pl-8">
                    <Typography variant="h5">API Partner Management</Typography>
                </div>
                <div className="flex flex-row p-4 justify-center w-full gap-8 -ml-72">
                    <TextField
                        id="outlined-basic"
                        placeholder="Search by Name"
                        variant="outlined"
                        size="small"
                        className="rounded-lg w-[30%]"
                    />

                    <Button variant="contained" style={{ backgroundColor: '#0A2638' }}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </div>
            </div>
            <div className="flex flex-row-reverse px-20 gap-4">
                <Button className="w-32 gap-4" variant="contained" size="small" style={{ backgroundColor: '#C4A34F' }}
                    onClick={() => navigate('/user/api-partner-management/add')}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    Add New
                </Button>
            </div>
            <div>
                <Box>
                    <CardContent>
                        <TableContainer component={Paper} className="px-8">
                            <span className="text-sm">{`Total Partners: ${APIPartnerList.length}`}</span>
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>PARTNER NAME</TableCell>
                                        <TableCell>EMAIL</TableCell>
                                        <TableCell>PHONE</TableCell>
                                        <TableCell>STATUS</TableCell>
                                        <TableCell>ACTION</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        APIPartnerList.map((val, idx) => (
                                            <TableRow
                                                key={val.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{val.name}</TableCell>
                                                <TableCell>{val.email}</TableCell>
                                                <TableCell>{val.phone}</TableCell>
                                                <TableCell>{val.status}</TableCell>
                                                <TableCell>
                                                    <div className="flex w-full gap-2" title="Edit">
                                                        <FontAwesomeIcon icon={faFilePen} className="hover:cursor-pointer h-6 w-6 pl-4"
                                                            onClick={() => {navigate('/user/api-partner-management/edit')}} />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>

            </div>
        </div>
    )
}

export default APIPartnermanagement