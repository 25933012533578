import React, { useState } from "react";
import LoadingPage from "../../../common/Loading.page";
import {
  Box,
  Button,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faEye, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const SupplierVehiclePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="flex flex-col p-4">
      <LoadingPage loading={loading} />
      <div className="flex flex-1 bg-[#F1F2F3]">
        <div className="w-72 flex flex-col justify-center pl-8">
          <Typography variant="h5">Vehicle Management</Typography>
        </div>
      </div>
      <div className="flex flex-row-reverse px-20 gap-4">
        {/* <Button className="w-32" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}>
                    Export
                </Button> */}
        <Button
          className="w-32"
          variant="contained"
          size="small"
          style={{ backgroundColor: "#B6A269" }}
          onClick={()  => navigate('/supplier/vehicle-management/add-vehicle')}
        >
          <FontAwesomeIcon icon={faAdd} className="mr-2" />
          Add New
        </Button>
      </div>
      <div>
        <Box>
          <CardContent>
            <TableContainer component={Paper} className="px-8">
              <Table size="small" sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>VEHICLE TYPE</TableCell>
                    <TableCell>NO. OF VEHICLE</TableCell>
                    <TableCell>ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {([] as any[])?.map((val, idx) => (
                    <TableRow
                      key={idx}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {/* <TableCell component="th" scope="row">
                                                    {val.id}
                                                </TableCell> */}
                      <TableCell>{val?.accountName}</TableCell>
                      <TableCell>{"<val?.supplierCountry>"}</TableCell>
                      <TableCell>{val?.vehicleBrand}</TableCell>
                      <TableCell>{val?.vehicleModel}</TableCell>
                      <TableCell>{val?.vehicleNumber}</TableCell>
                      <TableCell>
                        <div className="flex w-full gap-2">
                          <FontAwesomeIcon
                            icon={faEye}
                            className="hover:cursor-pointer h-6 w-6 pl-4"
                            onClick={() => {
                              navigate(
                                `/vehicle/vehicle-management/list/detail?id=${val?.id}`
                              );
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Box>
      </div>
    </div>
  );
};

export default SupplierVehiclePage;
