import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import LoadingPage from "../common/Loading.page"
import { Box, Button, CardContent, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePen, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"

const ZoneManagementPage = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
    }, [])

    return (
        <div className="flex flex-col">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Zone Management</Typography>
                </div>
                <div className="flex flex-row p-4 justify-center w-full gap-8 -ml-72">
                    <TextField
                        id="outlined-basic"
                        placeholder="Search by Name"
                        variant="outlined"
                        size="small"
                        className="rounded-lg w-[30%]"
                    />
                    <Button variant="contained" style={{ backgroundColor: '#0A2638' }}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </div>
            </div>
            <div>
                <Box>
                    <CardContent>
                        <div className="flex flex-row-reverse gap-4 mb-4">
                            <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}
                                onClick={() => navigate('/zone/zone-management/add')}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                Add New
                            </Button>
                        </div>
                        <div className="flex flex-row-reverse">
                            <span>{'Total Zone: 33'}</span>
                        </div>
                        <TableContainer component={Paper} className="px-8">
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>NAME</TableCell>
                                        <TableCell>COUNTRY</TableCell>
                                        <TableCell>STATE</TableCell>
                                        <TableCell>CITY</TableCell>
                                        <TableCell>HUB TYPE</TableCell>
                                        <TableCell>ACTION</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/* {
                                        pricePlanList.map((val: PricePlan, idx) => ( */}
                                    <TableRow
                                        key={'idx'}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {'Changi Airport'}
                                        </TableCell>
                                        <TableCell>{'Singapore'}</TableCell>
                                        <TableCell>{'Singapore'}</TableCell>
                                        <TableCell>{'Singapore'}</TableCell>
                                        <TableCell>{'Airport'}</TableCell>
                                        <TableCell>
                                            <div className="flex justify-start gap-3">
                                                <FontAwesomeIcon icon={faFilePen} className="hover:cursor-pointer h-6 w-6" title="Edit"
                                                    onClick={() => {
                                                        // navigate(`/price/selling-price/edit?id=${val?.id}`)
                                                    }} />
                                                <FontAwesomeIcon icon={faTrash} className="hover:cursor-pointer h-6 w-6" title="Edit"
                                                    onClick={() => {
                                                        // navigate(`/price/selling-price/edit?id=${val?.id}`)
                                                    }} />
                                                <Switch
                                                    size="small"
                                                    checked={false}
                                                    onChange={() => { }}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    {/* ))
                                    } */}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>
            </div>

        </div>
    )
}

export default ZoneManagementPage