import {
  faChevronCircleLeft,
  faEyeSlash,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Typography,
  Button,
  InputLabel,
  TextField,
  MenuItem,
  Select,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import LoadingPage from "../../../common/Loading.page";
import { useNavigate } from "react-router-dom";
import ContainerPanel from "../../../common/ContainerPanel";
import { CountryList } from "../../../common/DataUtils";

const MyProfilePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPw, setShowPw] = useState<boolean>(false);
  const [showRePw, setShowRePw] = useState<boolean>(false);

  return (
    <div className="flex flex-col">
      <LoadingPage loading={loading} />
      <div className="flex flex-1 bg-[#F1F2F3] p-4">
        <div
          className="flex flex-row gap-4 hover:cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            className={`h-10 w-10 text-[#333333]`}
          />
          <Typography variant="h5">Supplier Profile</Typography>
        </div>
      </div>
      <ContainerPanel title={"Individual/Company Detail"}>
        <div className="grid grid-cols-6 gap-2 pb-4">
          {/* DRIVER NAME */}
          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2" required>
              Name
            </InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2" required>
              Contact Phone
            </InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2" required>
              Email
            </InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2" required>
              Country
            </InputLabel>
            <Select className="mt-2 h-10">
              {CountryList.map((country) => (
                <MenuItem value={country.value}>{country.label}</MenuItem>
              ))}
            </Select>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2" required>
              State
            </InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2" required>
              City
            </InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>
        </div>
      </ContainerPanel>

      <ContainerPanel title={"Company Details"}>
        <div className="grid grid-cols-6 gap-2 pb-4">
          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2" required>
              Company Name
            </InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">
              Registration Number
            </InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">Website</InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">Address</InputLabel>
            <TextField
              minRows={2}
              multiline
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>
        </div>
      </ContainerPanel>

      <ContainerPanel title={"Billing Detail"}>
        <div className="grid grid-cols-6 gap-2 pb-4">
          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2" required>
              Company Name
            </InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">Contact Name</InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">Contact Phone</InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">Email</InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">Address</InputLabel>
            <TextField
              minRows={2}
              multiline
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>
        </div>
      </ContainerPanel>

      <ContainerPanel title={"Other Detail"}>
        <div className="grid grid-cols-6 gap-2 pb-4">
          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2" required>
              Trip Booking Email
            </InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2" required>
              Trip Booking Email CC
            </InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2" required>
              Registration Certificate
            </InputLabel>
            <TextField
              type="file"
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2" required>
              Certificate of Liability Insurance
            </InputLabel>
            <TextField
              type="file"
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
            ></TextField>
          </div>
        </div>
      </ContainerPanel>
      <ContainerPanel title={"Login Credentials"}>
        <div className="grid grid-cols-6 gap-2 pb-4">
          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2" required>
              Current Password
            </InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
              type={showPw ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      className="hover:cursor-pointer"
                      onClick={() => {
                        setShowPw((e) => !e);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2" required>
              New Password
            </InputLabel>
            <TextField
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
              type={showRePw ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      className="hover:cursor-pointer"
                      onClick={() => {
                        setShowRePw((e) => !e);
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </div>
        </div>
      </ContainerPanel>
    </div>
  );
};

export default MyProfilePage;
