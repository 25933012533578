import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import LoadingPage from "../../../common/Loading.page";
import { Box, CardContent, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const SupplierPaymentSettlementPage = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const location = useLocation();
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(location.search);
    const id: string = queryParams.get('id') ?? ''

    const [companyName, setCompanyName] = useState<any>('')
    const [supplierPaymentList, setSupplierPaymentList] = useState<any[]>([])
    return(
        <div className="flex flex-col">
            <LoadingPage loading={loading}/>
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-72 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Payment Settlement</Typography>
                </div>
            </div>
            <div>
                <Box>
                    <CardContent>
                        <TableContainer component={Paper} className="px-8">
                            <span className="text-sm">{`Total Records: ${'0'}`}</span>
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{`DRIVER`}</TableCell>
                                        <TableCell>{`TRIP ID`}</TableCell>
                                        <TableCell>{'TRIP TOTAL(S$)'}</TableCell>
                                        <TableCell>{`TRIP STATUS`}</TableCell>
                                        <TableCell>{`DRIVER AMOUNT(S$)`}</TableCell>
                                        <TableCell>{`PAID AMOUNT(S$)`}</TableCell>
                                        <TableCell>{`TRANSACTION ID`}</TableCell>
                                        <TableCell>{`STATUS`}</TableCell>
                                        <TableCell>{`ACTION`}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        supplierPaymentList?.map((val, idx) => (
                                            <TableRow
                                                key={val.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {val.id}
                                                </TableCell>
                                                <TableCell>{val.driverName}</TableCell>
                                                <TableCell>{companyName}</TableCell>
                                                <TableCell>{val.email}</TableCell>
                                                <TableCell>{val.category}</TableCell>
                                                <TableCell>{val.phoneNumber}</TableCell>
                                                <TableCell>
                                                    <div className="flex w-full gap-2">
                                                        <FontAwesomeIcon icon={faEye} className="hover:cursor-pointer h-6 w-6 pl-4"
                                                            onClick={() => {
                                                                navigate(`/user/driver-management/detail?id=${val.id}`, {state: val})
                                                            }} />
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <Switch
                                                        size="small"
                                                        checked={false}
                                                        onChange={() => { }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>

            </div>
        </div>
    )
}

export default SupplierPaymentSettlementPage