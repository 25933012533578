import React, { useState } from "react";
import Images from "../../../utils/ImageUtils";
import { Outlet, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBriefcase,
  faCar,
  faCreditCard,
  faFile,
  faGaugeHigh,
  faGear,
  faGrip,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import { Menu, MenuItem } from "@mui/material";
import { performLogout } from "../../../utils/GeneralUtils";

type menuOptions =
  | ""
  | "SUPPLIER_DRIVER"
  | "SUPPLIER_VEHICLES"
  | "SUPPLIER_SETTINGS"
  | "SUPPLIER_COST_PRICES"
  | "SUPPLIER_TRIPS"
  | "SUPPLIER_REPORTS"
  | "PROFILE";

const SupplierLayout = () => {
  const navigate = useNavigate();
  const [menuActive, setMenuActive] = useState<menuOptions>("");
  const [selectedMenu, setSelectedMenu] = useState<menuOptions>("");
  const [anchorEl, setAnchorEl] = useState(null);
  const iconStyle = "h-6 w-6 text-white text-center self-center justify-center";
  const textMenuStyle = "text-center text-lg mt-2";

  const supplierDriverListItem = [
    {
      label: "Driver Management",
      path: "/supplier/driver-management",
    },
    {
      label: "Driver Rates",
      path: "/supplier/driver-rates",
    },
    {
      label: "Payment Settlement",
      path: "/supplier/payment-settlement",
    },
  ];

  const supplierSettingsListItem = [
    {
      label: "Waiting Time",
      path: "/supplier/waiting-time",
    },
    {
      label: "Extra Seat Charges",
      path: "/supplier/extra-seat-charges",
    },
  ];

  const supplierReportsListItem = [
    {
      label: "Billing",
      path: "/supplier/billing",
    },
  ];

  const adminProfileListItem = [
    {
      label: "Go Back To Admin",
      path: "/supplier/driver-management",
    },
    {
      label: "My Profile",
      path: "/supplier/profile",
    },
    {
      label: "Logout",
      path: "/supplier/payment-settlement",
    },
  ];

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const listMenu = (selectedMenu: menuOptions) => {
    let returnMenu: any[] = [];
    switch (selectedMenu) {
      case "SUPPLIER_DRIVER":
        returnMenu = supplierDriverListItem;
        break;
      case "SUPPLIER_SETTINGS":
        returnMenu = supplierSettingsListItem;
        break;
      case "SUPPLIER_REPORTS":
        returnMenu = supplierReportsListItem;
        break;
      case "PROFILE":
        returnMenu = adminProfileListItem;
        break;
      default:
        break;
    }
    return returnMenu;
  };

  return (
    <div className="flex flex-col min-h-screen">
      <header className="bg-[#333333] p-4 h-24 flex flex-row w-full">
        <div className="flex w-2/12 mr-4">
          <img alt="" className="h-16" src={Images.primaryLogo} />
        </div>
        <div className="flex w-7/12 flex-row gap-2 justify-between">
          <div
            className="flex w-[15%] flex-col cursor-pointer hover:bg-slate-700 hover:rounded-lg justify-between"
            onClick={(e) => {
              handleClick(e);
              setMenuActive("SUPPLIER_DRIVER");
              setSelectedMenu("SUPPLIER_DRIVER");
            }}
          >
            <FontAwesomeIcon
              icon={faGaugeHigh}
              color="white"
              className={twMerge(
                iconStyle,
                `${
                  menuActive === "SUPPLIER_DRIVER"
                    ? "text-[#EDBA37]"
                    : "text-white"
                }`
              )}
            />
            <span
              className={twMerge(
                textMenuStyle,
                `${
                  menuActive === "SUPPLIER_DRIVER"
                    ? "text-[#EDBA37]"
                    : "text-white"
                }`
              )}
            >
              Driver
            </span>
          </div>

          <div
            className="flex w-[15%] flex-col cursor-pointer hover:bg-slate-700 hover:rounded-lg justify-between"
            onClick={(e) => {
              setMenuActive("SUPPLIER_VEHICLES");
              setSelectedMenu("SUPPLIER_VEHICLES");
              navigate("/supplier/vehicle-management");
            }}
          >
            <FontAwesomeIcon
              icon={faCar}
              color="white"
              className={twMerge(
                iconStyle,
                `${
                  menuActive === "SUPPLIER_VEHICLES"
                    ? "text-[#EDBA37]"
                    : "text-white"
                }`
              )}
            />
            <span
              className={twMerge(
                textMenuStyle,
                `${
                  menuActive === "SUPPLIER_VEHICLES"
                    ? "text-[#EDBA37]"
                    : "text-white"
                }`
              )}
            >
              Vehicles
            </span>
          </div>

          <div
            className="flex w-[15%] flex-col cursor-pointer hover:bg-slate-700 hover:rounded-lg justify-between"
            onClick={(e) => {
              handleClick(e);
              setMenuActive("SUPPLIER_SETTINGS");
              setSelectedMenu("SUPPLIER_SETTINGS");
            }}
          >
            <FontAwesomeIcon
              icon={faGear}
              color="white"
              className={twMerge(
                iconStyle,
                `${
                  menuActive === "SUPPLIER_SETTINGS"
                    ? "text-[#EDBA37]"
                    : "text-white"
                }`
              )}
            />
            <span
              className={twMerge(
                textMenuStyle,
                `${
                  menuActive === "SUPPLIER_SETTINGS"
                    ? "text-[#EDBA37]"
                    : "text-white"
                }`
              )}
            >
              Settings
            </span>
          </div>

          <div
            className="flex w-[15%] flex-col cursor-pointer hover:bg-slate-700 hover:rounded-lg justify-between"
            onClick={() => {
              setMenuActive("SUPPLIER_COST_PRICES");
              setSelectedMenu("SUPPLIER_COST_PRICES");
              navigate("/supplier/cost-price");
            }}
          >
            <FontAwesomeIcon
              icon={faCreditCard}
              color="white"
              className={twMerge(
                iconStyle,
                `${
                  menuActive === "SUPPLIER_COST_PRICES"
                    ? "text-[#EDBA37]"
                    : "text-white"
                }`
              )}
            />
            <span
              className={twMerge(
                textMenuStyle,
                `${
                  menuActive === "SUPPLIER_COST_PRICES"
                    ? "text-[#EDBA37]"
                    : "text-white"
                }`
              )}
            >
              Cost Price
            </span>
          </div>

          <div
            className="flex w-[15%] flex-col cursor-pointer hover:bg-slate-700 hover:rounded-lg justify-between"
            onClick={() => {
              setMenuActive("SUPPLIER_TRIPS");
              setSelectedMenu("SUPPLIER_TRIPS");
              navigate("/supplier/trips");
            }}
          >
            <FontAwesomeIcon
              icon={faBriefcase}
              color="white"
              className={twMerge(
                iconStyle,
                `${
                  menuActive === "SUPPLIER_TRIPS"
                    ? "text-[#EDBA37]"
                    : "text-white"
                }`
              )}
            />
            <span
              className={twMerge(
                textMenuStyle,
                `${
                  menuActive === "SUPPLIER_TRIPS"
                    ? "text-[#EDBA37]"
                    : "text-white"
                }`
              )}
            >
              Trips
            </span>
          </div>

          <div
            className="flex w-[15%] flex-col cursor-pointer hover:bg-slate-700 hover:rounded-lg justify-between"
            onClick={(e) => {
              handleClick(e);
              setMenuActive("SUPPLIER_REPORTS");
              setSelectedMenu("SUPPLIER_REPORTS");
            }}
          >
            <FontAwesomeIcon
              icon={faFile}
              color="white"
              className={twMerge(
                iconStyle,
                `${
                  menuActive === "SUPPLIER_REPORTS"
                    ? "text-[#EDBA37]"
                    : "text-white"
                }`
              )}
            />
            <span
              className={twMerge(
                textMenuStyle,
                `${
                  menuActive === "SUPPLIER_REPORTS"
                    ? "text-[#EDBA37]"
                    : "text-white"
                }`
              )}
            >
              Reports
            </span>
          </div>
          <Menu
            id="menu-user"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {listMenu(selectedMenu).map((item) => (
              <div
                onClick={() => {
                  setMenuActive(selectedMenu);
                  if (item.label === "Logout") {
                    performLogout(navigate);
                  } else {
                    navigate(item.path);
                  }
                }}
              >
                <MenuItem onClick={handleClose}>{item.label}</MenuItem>
              </div>
            ))}
          </Menu>
        </div>
        <div className="flex w-3/12 flex-2 mr-4 pl-8">
          <div className="w-[40%] flex flex-row justify-between">
            <div
              className="flex flex-col justify-center cursor-pointer"
              title="Unscheduled Trip"
            >
              <FontAwesomeIcon
                icon={faCar}
                color="white"
                className="h-6 w-6 text-white text-center self-center justify-center"
              />
            </div>
            <div
              className="flex flex-col justify-center cursor-pointer"
              title="Notification"
            >
              <FontAwesomeIcon
                icon={faBell}
                color="white"
                className="h-6 w-6 text-white text-center self-center justify-center"
              />
            </div>
          </div>
          <div
            className="w-[60%] flex flex-row justify-center rounded-lg hover:bg-[#565656] m-2 cursor-pointer"
            onClick={(e) => {
              handleClick(e);
              setSelectedMenu("PROFILE");
            }}
          >
            <div className="flex flex-col justify-center mr-2">
              <span className="self-center align-middle justify-center text-white font-semibold">
                UGKS ADMIN
              </span>
            </div>
            <FontAwesomeIcon
              icon={faUserCircle}
              color="white"
              className="h-6 w-6 text-white text-center self-center justify-center"
            />
          </div>
        </div>
      </header>
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default SupplierLayout;
