import React, { useState } from "react"
import LoadingPage from "../common/Loading.page"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleLeft, faFileUpload, faSave, faUpload } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { Button, InputAdornment, InputLabel, MenuItem, Radio, Select, TextField, Typography } from "@mui/material"

const EditDiscountPage = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    return (
        <div className="flex flex-col p-4">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                    <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                    <Typography variant="h5">Add Discount</Typography>
                </div>
            </div>
            <div className="flex flex-row-reverse px-20 gap-4">
                <Button className="w-32" variant="contained" size="small" style={{ backgroundColor: '#B6A269' }}
                    onClick={() => navigate(-1)}
                >
                    <FontAwesomeIcon icon={faSave} className="mr-4" />
                    Save
                </Button>
            </div>

            <div className="flex flex-col p-4 mx-8">
                <div className="flex flex-row h-4 z-40">
                    <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                        Add Discount
                    </span>
                </div>
                <div className="flex flex-col justify-between border border-[#EDBA37] rounded-lg pt-6 py-4 px-4 gap-2 h-fit">
                    <div className="grid grid-cols-4 gap-2">
                    <div className="flex flex-col">
                            <InputLabel className="text-sm">Customer Type*</InputLabel>
                            <Select
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="seat"
                                name="seat"
                                placeholder="Coupon Type"
                            >
                                {[
                                    { label: 'All', value: 'all' },
                                    { label: 'Corporate', value: 'corporate' },
                                    { label: 'Travel Agent', value: 'TA' },
                                    { label: 'Customers', value: 'customers' },

                                ].map((val: any) => (
                                    <MenuItem>{val.label}</MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="flex flex-col">
                            <InputLabel className="text-sm">Vehicle Type*</InputLabel>
                            <Select
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="seat"
                                name="seat"
                                placeholder="Coupon Type"
                            >
                                {[
                                    { label: 'All', value: 'all' },
                                    { label: 'Specific', value: 'specific' },

                                ].map((val: any) => (
                                    <MenuItem>{val.label}</MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="flex flex-col">
                            <InputLabel className="text-sm">Discount Type*</InputLabel>
                            <Select
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="seat"
                                name="seat"
                                placeholder="Coupon Type"
                            >
                                {[
                                    { label: 'Fix Amount Discount', value: 'fix' },
                                    { label: 'Percentage Discount', value: 'percent' }
                                ].map((val: any) => (
                                    <MenuItem>{val.label}</MenuItem>
                                ))}
                            </Select>
                        </div>

                        <div className="flex flex-col">
                            <InputLabel className="text-sm -mb-2">Discount Value</InputLabel>
                            <TextField
                                className="flex w-full h-10"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                id="seat"
                                name="seat"
                                placeholder="Coupon Name"
                            ></TextField>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditDiscountPage