// AutocompleteInput.tsx

import React, { useState, useEffect, ChangeEvent, FC } from 'react';
import '@react-google-maps/api'
import { Autocomplete, Divider, Select, TextField } from '@mui/material';

interface AutocompleteInputProps {
    placeholder?: string;
    minLength?: number;
    debounceTime?: number;
    onPlaceSelected?: (place: google.maps.places.PlaceResult) => void;
}

const AutocompleteInput: FC<AutocompleteInputProps> = ({
    placeholder = "Enter a location",
    minLength = 3,
    debounceTime = 300,
    onPlaceSelected,
}) => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchSuggestions = async () => {
            if (query.length < minLength) {
                setSuggestions([]);
                return;
            }

            setLoading(true);

            const service = new window.google.maps.places.AutocompleteService();
            service.getPlacePredictions({ input: query }, (predictions, status) => {
                setLoading(false);
                if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
                    setSuggestions(predictions);
                } else {
                    setSuggestions([]);
                }
            });
        };

        const timeoutId = setTimeout(() => {
            fetchSuggestions();
        }, debounceTime);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [query, minLength, debounceTime]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        setQuery(e.target.value);
    };

    const handleSelect = (suggestion: google.maps.places.AutocompletePrediction) => {
        setQuery(suggestion.description);
        setSuggestions([]);

        const service = new window.google.maps.places.PlacesService(document.createElement('div'));
        service.getDetails({ placeId: suggestion.place_id }, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
                if (onPlaceSelected) {
                    onPlaceSelected(place);
                    setSuggestions([]);
                }
            }
        });
    };

    return (
        <div>
            <TextField
            className='flex flex-1 w-full'
                type="text"
                value={query}
                onChange={handleChange}
                placeholder={placeholder}
                size='small'
                title={query}
                onKeyDown={(e) => {
                    if(e.key === 'Escape'){
                        setSuggestions([])
                    }
                }}
            />
            {loading ? <div>Loading...</div>
                :
                <ul>
                    {suggestions.map((suggestion) => (
                        <>
                            <li className='shadow-xl drop-shadow-xl p-2 border-neutral-500 hover:bg-[#B6A269] hover:bg-opacity-80 cursor-pointer' key={suggestion.place_id} onClick={() => handleSelect(suggestion)}>
                                {suggestion.description}
                            </li>
                            <Divider />
                        </>
                    ))}
                </ul>
            }


        </div>
    );
};

export default AutocompleteInput;
