import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation, useNavigate } from "react-router-dom"
import { faChevronCircleLeft, faSearch } from "@fortawesome/free-solid-svg-icons"
import { Button, Grid, TextField, Typography } from "@mui/material"
import dayjs from "dayjs"
import LoadingPage from "../../../common/Loading.page"
import { getVehicleById } from "../../../trips/services/TripsServices.services"
import { handleError } from "../../../../services/axiosInstance"
import { VehicleDetailModel, VehicleDetails } from "../../../trips/model/Trip.model"

const VehicleDetailsPage = () => {

    const location = useLocation();
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const queryParams = new URLSearchParams(location.search);
    const id: string = queryParams.get('id') ?? ''
    const [dataVehicle, setDataVehicle] = useState<VehicleDetailModel>()

    const fetchVehicleById = (id: string) => {
        setLoading(true)
        getVehicleById(id)
        .then((res) => {
            console.log(res?.data)
            setDataVehicle(res?.data)
        })
        .catch((err) => {
            handleError(err, navigate)
        })
        .finally(() => {
            setLoading(false)
        })
    }


    useEffect(() => {
        fetchVehicleById(id)
    }, [id])
    

    return (
        <div className="flex flex-col">
            <LoadingPage loading={loading} />
            <div className="flex flex-row w-full justify-between p-4">
                <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                    <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                    <Typography variant="h5" className="pt-2 text-[#333333]">Vehicle Details</Typography>
                </div>
            </div>
            <div className="flex flex-row justify-center p-4">
                <img 
                src="https://placehold.co/600x400" //TODO: REPLACE WITH REAL URL
                alt={dataVehicle?.vehicleModel}
                className="rounded-lg h-[300px] w-[500px] object-cover"
                />
            </div>
            <div className="flex flex-col p-4 mx-8">
                <div className="flex flex-row h-4 z-40">
                    <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                        Vehicle Information
                    </span>
                </div>
                <div className="flex flex-col border border-[#EDBA37] rounded-lg pt-6 gap-4 h-fit">
                    <div className="grid grid-rows-4 grid-flow-col gap-4 p-4">
                        <div className="flex">
                            <span className="w-1/2">Supplier Name</span>
                            <span>: {dataVehicle?.accountName}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">Vehicle Type</span>
                            <span>: {dataVehicle?.vehicleType}</span>
                        </div>
                        <div className="flex">

                            <span className="w-1/2">Description  </span>
                            <span>: {dataVehicle?.vehicleDescription}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">Model  </span>
                            <span>: {dataVehicle?.vehicleModel}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">
                                No Of Passenger</span>
                            <span>: {dataVehicle?.vehicleDetails.numberOfPassengers}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">No of Bag </span>
                            <span>: {dataVehicle?.vehicleDetails.numberOfCheckInBag}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">No of Handbag</span>
                            <span>: {dataVehicle?.vehicleDetails.numberOfHandCarry}</span>
                        </div>
                        <div className="flex">
                            <span className="w-1/2">Vehicle Location</span>
                            <span>: {dataVehicle?.vehicleLocation}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col p-4 mx-8">
                <div className="flex flex-row h-4 z-40">
                    <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                        Vehicle Details
                    </span>
                </div>
                <div className="flex flex-col border border-[#EDBA37] rounded-lg pt-6 h-fit">
                    <div className="flex flex-col py-2 mx-8">
                        <div className="flex flex-row h-4 z-40">
                            <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                                Vehicle
                            </span>
                        </div>
                        <div className="flex flex-col border border-[#EDBA37] rounded-lg pt-6 gap-2 h-fit">
                            <div className="grid grid-cols-3 gap-0 p-4 divide-x-2">
                                <div className="p-2">
                                    <span className="text-lg font-semibold">Basic Information</span>
                                    <div className="flex pl-2">
                                        <span className="w-1/2">Car Plate Number</span>
                                        <span>: {dataVehicle?.vehicleNumber}</span>
                                    </div>
                                    <div className="flex pl-2">
                                        <span className="w-1/2">Brand</span>
                                        <span>: {dataVehicle?.vehicleBrand}</span>
                                    </div>
                                    <div className="flex pl-2">
                                        <span className="w-1/2">Color</span>
                                        <span>: {dataVehicle?.vehicleColor}</span>
                                    </div>
                                    <div className="flex pl-2">
                                        <span className="w-1/2">Driver</span>
                                        <span>: {dataVehicle?.driverName}</span>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <span className="text-lg font-semibold">Registration Details</span>
                                    <div className="flex pl-2">
                                        <span className="w-1/2">Registration Date</span>
                                        <span>: {dataVehicle?.vehicleDetails?.vehicleRegistrationDetails?.registrationDate}</span>
                                    </div>
                                    <div className="flex pl-2">
                                        <span className="w-1/2">Expiry Date</span>
                                        <span>: {dataVehicle?.vehicleDetails?.vehicleRegistrationDetails?.expirationDate}</span>
                                    </div>
                                    <div className="flex pl-2">
                                        <span className="w-1/2">PHV Decal</span>
                                        <span>: {dataVehicle?.vehicleDetails?.vehicleRegistrationDetails?.phvDecal}</span>
                                    </div>
                                </div>

                                <div className="p-2">
                                    <span className="text-lg font-semibold">Insurance Details</span>
                                    <div className="flex pl-2">
                                        <span className="w-1/2">Expiry Date</span>
                                        <span>: {dataVehicle?.vehicleDetails?.vehicleInsuranceDetails?.expiryDate}</span>
                                    </div>
                                    <div className="flex pl-2">
                                        <span className="w-1/2">Insurance Certificate</span>
                                        <span>: {dataVehicle?.vehicleDetails?.vehicleInsuranceDetails?.insuranceCertificate}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VehicleDetailsPage