import { faAdd, faChevronCircleLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Typography,
  Button,
  InputLabel,
  TextField,
  Radio,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import LoadingPage from "../../../common/Loading.page";
import { useNavigate } from "react-router-dom";
import ContainerPanel from "../../../common/ContainerPanel";
import Colors from "../../../../utils/ColorUtils";

const AddDriverRatePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="flex flex-col">
      <LoadingPage loading={loading} />
      <div className="flex flex-1 bg-[#F1F2F3] p-4">
        <div
          className="flex flex-row gap-4 hover:cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            className={`h-10 w-10 text-[#333333]`}
          />
          <Typography variant="h5">Add Driver Rate</Typography>
        </div>
      </div>
      <div className="flex flex-row-reverse px-20 gap-4">
        <Button
          className="w-32"
          variant="contained"
          size="small"
          style={{ backgroundColor: "#B6A269" }}
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faSave} className="mr-4" />
          Save
        </Button>
        <Button
          className="w-32"
          variant="contained"
          size="small"
          style={{ backgroundColor: Colors.signatureDark }}
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faAdd} className="mr-4" />
          Add New
        </Button>
      </div>
      <ContainerPanel title={"Driver Rate Details"}>
        <div className="flex flex-col">
          <div className=" flex flex-row">
            <div className="flex flex-row w-fit">
              <Radio />
              <InputLabel className="text-sm align-middle self-center">
                All Drivers
              </InputLabel>
            </div>
            <div className="flex flex-row w-fit">
              <Radio />
              <InputLabel className="text-sm align-middle self-center">
                Set Rate for Specific Driver
              </InputLabel>
            </div>
            <div className="flex flex-row w-fit">
              <Radio />
              <InputLabel className="text-sm align-middle self-center">
                Other Changes
              </InputLabel>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-6 gap-2 pb-4">
          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">Transfer Type</InputLabel>
            <Select className="mt-2 h-10">
                <MenuItem value={""}>Select</MenuItem>
                <MenuItem value={1}>Arrival</MenuItem>
                <MenuItem value={1}>Departure</MenuItem>
                <MenuItem value={1}>Point to Point</MenuItem>
                <MenuItem value={1}>Hourly</MenuItem>

              </Select>
          </div>
          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">Vehicle Type</InputLabel>
            <Select className="mt-2 h-10">
                <MenuItem value={""}>Select</MenuItem>
                <MenuItem value={1}>All</MenuItem>
                <MenuItem value={1}>Bussiness MPV Alphard</MenuItem>
              </Select>
          </div>
          <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2" required>Rate(S$)</InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
                placeholder="Partner Name"
              ></TextField>
            </div>

            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2" required>Extra Stop(S$)</InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
                placeholder="Partner Name"
              ></TextField>
            </div>
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2" required>Child Rate(S$)</InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
                placeholder="Partner Name"
              ></TextField>
            </div>

        </div>
      </ContainerPanel>
    </div>
  );
};

export default AddDriverRatePage;
