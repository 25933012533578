import { faChevronCircleLeft, faPlus, faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, InputLabel, TextField, Typography } from "@mui/material"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import LoadingPage from "../../../common/Loading.page"

const EditExtraSeatCharge = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    return (
        <div className="flex flex-col p-4">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="flex flex-row gap-4 hover:cursor-pointer" onClick={() => navigate(-1)} >
                    <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333]`} />
                    <Typography variant="h5">Edit Extra Seat Charge</Typography>
                </div>
            </div>
            <div className="flex flex-row-reverse px-20 gap-4">
                <Button className="w-32" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}>
                    <FontAwesomeIcon icon={faSave} className="mr-4" />
                    Save
                </Button>
            </div>

            <div className="flex flex-col p-4 mx-8">
                <div className="flex flex-row h-4 z-40">
                    <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
                        Edit Extra Seat Charge
                    </span>
                </div>
                <div className="flex flex-row border border-[#EDBA37] rounded-lg pt-6 py-4 px-4 gap-2 h-fit">
                    <div>
                        <InputLabel className="text-sm -mb-2">Child/Baby/Booster Seats*</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="seat"
                            name="seat"
                        />
                    </div>
                    <div>
                        <InputLabel className="text-sm -mb-2">value</InputLabel>
                        <TextField
                            className="w-full h-10"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            id="seat"
                            name="seat"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditExtraSeatCharge