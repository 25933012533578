import React from "react";

const ContainerPanel = ({children, title}: any) => {
  return (
    <div className="flex flex-col p-4 mx-8">
      <div className="flex flex-row h-4 z-40">
        <span className="text-neutral-800 font-semibold -mb-4 ml-6 bg-[#B6A269] w-fit px-4 py-1 z-20 rounded-md">
          {title}
        </span>
      </div>
      <div className="flex flex-col justify-between border border-[#EDBA37] rounded-lg pt-6 py-4 px-4 gap-2 h-fit">
        {children}
      </div>
    </div>
  );
};


export default ContainerPanel