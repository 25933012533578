import React, { useState } from "react";
import LoadingPage from "../../../common/Loading.page";
import {
  Box,
  Button,
  CardContent,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faEye, faSearch } from "@fortawesome/free-solid-svg-icons";

const SupplierDriverRatesPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const id: string = queryParams.get("id") ?? "";

  const [companyName, setCompanyName] = useState<any>("");
  const [driverList, setDriverList] = useState<any[]>([]);
  return (
    <div className="flex flex-col">
      <LoadingPage loading={loading} />
      <div className="flex flex-1 bg-[#F1F2F3]">
        <div className="w-72 flex flex-col justify-center pl-8">
          <Typography variant="h5">Driver Rates</Typography>
        </div>
        <div className="flex flex-row p-4 justify-center w-full gap-8 -ml-72">
          <TextField
            id="outlined-basic"
            placeholder="Search by Driver Name/Transfer Type/Vehicle Type"
            variant="outlined"
            size="small"
            className="rounded-lg w-[30%]"
          />
          <Button variant="contained" style={{ backgroundColor: "#0A2638" }}>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </div>
      </div>
      <div className="flex flex-row-reverse px-20 gap-4">
        <Button
          className="w-32"
          variant="contained"
          size="small"
          style={{ backgroundColor: "#B6A269" }}
          onClick={() =>
            navigate("/supplier/driver-management/add-driver-rate")
          }
        >
          <FontAwesomeIcon icon={faAdd} className="mr-2" />
          Add New
        </Button>
      </div>
      <div>
        <Box>
          <CardContent>
            <TableContainer component={Paper} className="px-8">
              <span className="text-sm">{`Total: ${"0"}`}</span>
              <Table size="small" sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{`SUPPLIER NAME`}</TableCell>
                    <TableCell>{`DRIVER NAME`}</TableCell>
                    <TableCell>{`VEHICLE TYPE`}</TableCell>
                    <TableCell>{`TRANSFER TYPE`}</TableCell>
                    <TableCell>{`RATE(S$)`}</TableCell>
                    <TableCell>{`ACTION`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {driverList.map((val, idx) => (
                    <TableRow
                      key={val.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {val.id}
                      </TableCell>
                      <TableCell>{val.driverName}</TableCell>
                      <TableCell>{companyName}</TableCell>
                      <TableCell>{val.email}</TableCell>
                      <TableCell>{val.category}</TableCell>
                      <TableCell>{val.phoneNumber}</TableCell>
                      <TableCell>
                        <div className="flex w-full gap-2">
                          <FontAwesomeIcon
                            icon={faEye}
                            className="hover:cursor-pointer h-6 w-6 pl-4"
                            onClick={() => {
                              navigate(
                                `/user/driver-management/detail?id=${val.id}`,
                                { state: val }
                              );
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <Switch
                          size="small"
                          checked={false}
                          onChange={() => {}}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Box>
      </div>
    </div>
  );
};

export default SupplierDriverRatesPage;
