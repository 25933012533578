import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
    // 'Authorization': `Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJBU0RAZ21haWwuY29tIiwiaWF0IjoxNzIxNzQ0NjAxLCJleHAiOjE3MjE3NDgyMDF9.f6hoBkFRzT3yH8B_Sl5ixQdjrGHSM4-BhNOGdVguVuk`,
  },
});

export default api;