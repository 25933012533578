import { Divider, FormLabel, Typography } from "@mui/material"
import React, { useState } from "react"
import { createTheme, ThemeProvider, makeStyles } from '@mui/material/styles'
import RichTextEditor from "./RichTextEditor";

const RegistrationContent = () => {
    const [editorValue, setEditorValue] = useState<string>('');
    return (
        <div className="flex flex-col">
            <div className="flex flex-col">
                <div>
                    <Typography variant="h6" className="flex  flex-col justify-center">Registration - Success Page</Typography>
                    <span className="text-sm">Below code will affect the Registration</span>
                </div>
                <RichTextEditor value={editorValue} onChange={setEditorValue} />
            </div>
            <div className="flex flex-col">
                <div>
                    <Typography variant="h6" className="flex  flex-col justify-center">Registration - Success Email</Typography>
                    <span className="text-sm">Below code will affect the Registration</span>
                </div>
                <RichTextEditor value={editorValue} onChange={setEditorValue} />
            </div>
            <div className="flex flex-col">
                <div>
                    <Typography variant="h6" className="flex  flex-col justify-center">Verification - Success Page</Typography>
                    <span className="text-sm">Below code will affect the Registration</span>
                </div>
                <RichTextEditor value={editorValue} onChange={setEditorValue} />
            </div>
            <div className="flex flex-col">
                <div>
                    <Typography variant="h6" className="flex flex-col justify-center">Verification - Success Email</Typography>
                    <span className="text-sm">Below code will affect the Registration</span>
                </div>
                <RichTextEditor value={editorValue} onChange={setEditorValue} />
            </div>
            <div className="flex flex-col">
                <div>
                    <Typography variant="h6" className="flex  flex-col justify-center">Supplier Activation - Success Email</Typography>
                    <span className="text-sm">Below code will affect the Registration</span>
                </div>
                <RichTextEditor value={editorValue} onChange={setEditorValue} />
            </div>

            <Typography variant="h5" className="flex mt-4 flex-col justify-center">DRIVER REGISTRATION CONTENT</Typography>
            <Divider />
            <div className="flex flex-col">
                <div>
                    <Typography variant="h6" className="flex  flex-col justify-center">Driver Registration - Success Page</Typography>
                    <span className="text-sm">Below code will affect the Driver Registration - Success Page</span>
                </div>
                <RichTextEditor value={editorValue} onChange={setEditorValue} />
            </div>
            <div className="flex flex-col">
                <div>
                    <Typography variant="h6" className="flex  flex-col justify-center">Driver Registration - Success Email</Typography>
                    <span className="text-sm">Below code will affect the Driver Registration - Success Email</span>
                </div>
                <RichTextEditor value={editorValue} onChange={setEditorValue} />
            </div>
            <div className="flex flex-col">
                <div>
                    <Typography variant="h6" className="flex  flex-col justify-center">Driver Activation - Success Email</Typography>
                    <span className="text-sm">Below code will affect the Driver Activation - Success Email</span>
                </div>
                <RichTextEditor value={editorValue} onChange={setEditorValue} />
            </div>
            <div className="flex flex-col">
                <div>
                    <Typography variant="h6" className="flex  flex-col justify-center">Trip Booking - Success Email</Typography>
                    <span className="text-sm">Below code will affect the Trip Booking - Success Email</span>
                </div>
                <RichTextEditor value={editorValue} onChange={setEditorValue} />
            </div>
        </div>
    )
}

export default RegistrationContent