import axios from 'axios';
import { toast } from 'react-toastify';

// Create an Axios instance for the payment API
export const axiosInstance = axios.create({
    baseURL: 'https://api.ugkslimousine.com/ms-data-master-payment/api/v1',
});

export const axiosPayment = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL_BOOKING,
});

// Get Price Plans
export const getPricePlans = async (params: { pageableSize?: number; pageablePage?: number; sortingPage?: string; status?: string }) => {
    try {
        const response = await axiosInstance.get('/price/', { params });
        // Return the response data directly if elements are at the top level
        return response.data;
    } catch (error) {
        handleError(error); // Ensure this is defined elsewhere
        throw error;
    }
};



// Get Price Plan by ID
export const getPricePlanById = async (id: string) => {
    try {
        const response = await axiosInstance.get(`/price/id/${id}`);
        return response.data;
    } catch (error) {
        handleError(error);
        throw error;
    }
};

// Create Price Plan
export const createPricePlan = async (pricePlan: any) => {
    try {
        const response = await axiosInstance.post('/price/create', pricePlan);
        return response.data;
    } catch (error) {
        handleError(error);
        throw error;
    }
};

// Update Price Plan
export const updatePricePlan = async (id: string, pricePlan: any) => {
    try {
        const response = await axiosInstance.put(`/price/update/id/${id}`, pricePlan);
        return response.data;
    } catch (error) {
        handleError(error);
        throw error;
    }
};

// Delete Price Plan
export const deletePricePlan = async (id: string) => {
    try {
        const response = await axiosInstance.delete(`/price/delete/id/${id}`);
        return response.data;
    } catch (error) {
        handleError(error);
        throw error;
    }
};

// Handle errors
const handleError = (err: any) => {
    if (err?.response?.status === 403) {
        toast.error("Your session has expired");
        // Perform logout or redirect to login here if necessary
    } else {
        toast.error("An error occurred, please try again.");
    }
};
