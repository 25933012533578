import {
  faSearch,
  faPlus,
  faEdit,
  faCopy,
  faFilePen,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Typography,
  TextField,
  Button,
  Box,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Modal,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../../common/Loading.page";
import { PricePlan } from "../../../user/price/model/Price.model";
import Colors from "../../../../utils/ColorUtils";

const SupplierCostPricePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [pricePlanList, setPricePlanList] = useState<PricePlan[]>([]);
  const [selectedPricePlan, setSelectedPricePlan] = useState<PricePlan>();
  const [showModalPricePlan, setShowModalPricePlan] = useState<boolean>(false);
  const [selectedName, setSelectedName] = useState<string>("");

  useEffect(() => {
  //   setPricePlanList([
  //     {
  //       name: "All Users",
  //       country: "Singapore",
  //       state: "Singapore",
  //       currency: "Singapore Dollar",
  //       status: false,
  //       id: 0,
  //     },
  //     {
  //       name: "15-30",
  //       country: "Singapore",
  //       state: "Singapore",
  //       currency: "Singapore Dollar",
  //       status: false,
  //       id: 1,
  //     },
  //   ]);
  }, []);

  const [searchQuery, setSearchQuery] = useState<string>("");

const filteredPricePlans = pricePlanList.filter(plan =>
  plan.accountName.toLowerCase().includes(searchQuery.toLowerCase())
);

return (
  <div className="flex flex-col">
    <LoadingPage loading={loading} />
    <div className="flex flex-1 bg-[#F1F2F3]">
      <div className="w-96 flex flex-col justify-center pl-8">
        <Typography variant="h5">Price Plan Management</Typography>
      </div>
      <div className="flex flex-row p-4 justify-center w-full gap-8 -ml-72">
        <TextField
          id="search"
          placeholder="Search by Supplier Name"
          variant="outlined"
          size="small"
          className="rounded-lg w-[30%]"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Button variant="contained" style={{ backgroundColor: "#0A2638" }}>
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </div>
    </div>
    <div>
      <Box>
        <CardContent>
          <div className="flex flex-row-reverse gap-4 mb-4">
            <Button
              style={{ backgroundColor: Colors.signatureGold }}
              onClick={() => navigate("/supplier/cost-price/add-price-plan")}
              className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2"
              variant="contained"
              size="small"
            >
              <FontAwesomeIcon icon={faPlus} />
              Add New
            </Button>
          </div>
          <TableContainer component={Paper} className="px-8">
            <Table size="small" sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>NAME</TableCell>
                  <TableCell>ACCOUNT TYPE</TableCell>
                  <TableCell>COUNTRY</TableCell>
                  <TableCell>STATE</TableCell>
                  <TableCell>CURRENCY</TableCell>
                  <TableCell>ACTION</TableCell>
                  <TableCell>STATUS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredPricePlans.length > 0 ? (
                  filteredPricePlans.map((val: PricePlan, idx) => (
                    <TableRow
                      key={idx}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {val.accountName}
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="hover:cursor-pointer h-4 w-4 pl-4"
                          onClick={() => {
                            setSelectedPricePlan(val);
                            setSelectedName(val.accountName);
                            setShowModalPricePlan(true);
                          }}
                        />
                      </TableCell>
                      <TableCell>{val.accountType}</TableCell>
                      <TableCell>{val.countryCode}</TableCell>
                      <TableCell>{val.stateCode}</TableCell>
                      <TableCell>{val.currencyCode}</TableCell>
                      <TableCell>
                        <div className="flex justify-center">
                          <FontAwesomeIcon
                            icon={faCopy}
                            className="hover:cursor-pointer h-6 w-6 pl-4"
                            title="Copy"
                            onClick={() => {
                              // Add copy functionality
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faFilePen}
                            className="hover:cursor-pointer h-6 w-6 pl-4"
                            title="Edit"
                            onClick={() => {
                              navigate(`/price/selling-price/edit?id=${val.id}`);
                            }}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <Switch
                          size="small"
                          checked={val.status === 'active'} // Assuming status is a string like 'active' or 'inactive'
                          onChange={() => {
                            // Add toggle status functionality
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No data found!!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Box>
    </div>
    <Modal
      open={showModalPricePlan}
      onClose={() => setShowModalPricePlan(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="flex items-center justify-center"
    >
      <Box
        className="flex flex-col w-1/3 bg-white rounded-lg p-4"
        sx={{ maxWidth: 500 }}
      >
        <Typography variant="h5">Edit Name</Typography>
        <InputLabel className="text-sm mt-2">Name*</InputLabel>
        <TextField
          className="w-full"
          margin="dense"
          size="small"
          variant="outlined"
          id="name"
          name="name"
          value={selectedName}
          onChange={(e) => setSelectedName(e.target.value)}
        />
        <div className="flex flex-row-reverse mt-4">
          <Button
            className="w-32 gap-2"
            variant="contained"
            size="small"
            style={{ backgroundColor: "#B6A269" }}
            onClick={() => {
              // Save changes logic here
              setShowModalPricePlan(false); // Close the modal
            }}
          >
            <FontAwesomeIcon icon={faSave} />
            Save Changes
          </Button>
        </div>
      </Box>
    </Modal>
  </div>
);

};

export default SupplierCostPricePage;
