// src/components/MapWithRoute.tsx
import React, { useCallback, useRef, useState, useEffect } from "react";
import { GoogleMap, DirectionsRenderer, useLoadScript, useJsApiLoader } from "@react-google-maps/api";

// Define the types for the props
interface MapWithRouteProps {
  origin: [number, number]; // Tuple for latitude and longitude
  destination: [number, number]; // Tuple for latitude and longitude
}

// Define the container style
const containerStyle = {
  width: '100%',
  height: '420px',
};

// Define a default center for the map (optional)
const defaultCenter = {
  lat: 37.7749,
  lng: -122.4194,
};

const MapWithRoute: React.FC<MapWithRouteProps> = ({ origin, destination }) => {
  const [directionsResponse, setDirectionsResponse] = useState<google.maps.DirectionsResult | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAiPQF2KnUfZpG4DOaoM9j2ouAAWd597oQ',
    libraries: ["places"],
  });

  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  useEffect(() => {
    if (origin && destination) {
      const directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          origin: { lat: origin[0] ?? defaultCenter?.lat, lng: origin[1] ?? defaultCenter.lng },
          destination: { lat: destination[0] ?? defaultCenter.lat, lng: destination[1] ?? defaultCenter.lng },
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirectionsResponse(result);
          } else {
            console.error(`Error fetching directions: ${JSON.stringify(result)}`);
          }
        }
      );
    }
  }, [origin, destination]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <div className="map-container">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={defaultCenter}
        zoom={10}
        onLoad={onMapLoad}
      >
        {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
      </GoogleMap>
    </div>
  );
};

export default MapWithRoute;
