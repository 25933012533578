import React, { useEffect, useState } from "react"
import LoadingPage from "../../../common/Loading.page"
import { useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleLeft, faEye, faSearch } from "@fortawesome/free-solid-svg-icons"
import { Box, Button, CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { getVehicleById, getVehicleList } from "../../../trips/services/TripsServices.services"
import { handleError } from "../../../../services/axiosInstance"

const VehicleListPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState<boolean>(false)

    const [vehicleList, setVehicleList] = useState<any[]>([])


    const queryParams = new URLSearchParams(location.search);
    const id: string = queryParams.get('id') ?? ''

    const fetchVehicleById = (id: string) => {
        setLoading(true)
        getVehicleById(id)
        .then((res) => {
            console.log(res?.data)
            // setVehicleList(res?.data?.elements)
        })
        .catch((err) => {
            handleError(err, navigate)
        })
        .finally(() => {
            setLoading(false)
        })
    }


    useEffect(() => {
        fetchVehicleById(id)
    }, [id])
    useEffect(() => {
        setVehicleList([
            {
                id: '23324',
                vehicleType: 'Business Mpv Alphard',
                brand: 'ALPHARD',
                vehicleNo: 'SNL4601K',
                driverName: 'Jamie Tan Yu Mei',
                supplierName: 'Jamie'
            }])
    }, [])
    return (
        <div className="flex flex-col">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-row justify-center hover:cursor-pointer p-4" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faChevronCircleLeft} className={`h-10 w-10 text-[#333333] mr-2`} />
                    <Typography variant="h5" className="pt-2">Vehicle Management</Typography>
                </div>
                <div className="flex flex-row p-4 justify-center w-full gap-8 -ml-72">
                    <TextField
                        id="outlined-basic"
                        placeholder="Search by Vehicle No"
                        variant="outlined"
                        size="small"
                        className="rounded-lg w-[30%]"
                    />
                    <Button variant="contained" style={{ backgroundColor: '#0A2638' }}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </div>
            </div>

            <div>
                <Box>
                    <CardContent>
                        <TableContainer component={Paper} className="px-8">
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>VEHICLE TYPE</TableCell>
                                        <TableCell>BRAND</TableCell>
                                        <TableCell>VEHIVLE NO.</TableCell>
                                        <TableCell>DRIVER NAME</TableCell>
                                        <TableCell>VEHICLE TYPE</TableCell>
                                        <TableCell>ACTION</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        vehicleList.map((val, idx) => (
                                            <TableRow
                                                key={idx}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {val.id}
                                                </TableCell>
                                                <TableCell>{val?.vehicleType}</TableCell>
                                                <TableCell>{val?.brand}</TableCell>
                                                <TableCell>{val?.vehicleNo}</TableCell>
                                                <TableCell>{val?.driverName}</TableCell>
                                                <TableCell>{val?.supplierName}</TableCell>
                                                <TableCell>
                                                    <div className="flex w-full gap-2">
                                                        <FontAwesomeIcon icon={faEye} className="hover:cursor-pointer h-6 w-6 pl-4"
                                                        title="View Vehicle Detail"
                                                            onClick={() => {
                                                                navigate(`/vehicle/vehicle-management/list/detail?id=${val?.id}`)
                                                            }} />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>

            </div>

        </div>
    )
}

export default VehicleListPage