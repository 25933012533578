import React, { useState } from "react"
import LoadingPage from "../common/Loading.page"
import { Box, Button, CardContent, Checkbox, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePen, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"

const CouponsManagementPage = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <div className="flex flex-col py-4">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Coupons Management</Typography>
                </div>
            </div>
            <div>
                <Box>
                    <CardContent>
                        <div className="flex flex-row-reverse gap-4">
                            <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}
                                onClick={() => window.location.reload()}
                            >
                                {`Export`}
                            </Button>
                            <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#B6A269' }}
                                onClick={() => navigate('/modules/coupons-management/add')}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                Add new
                            </Button>
                        </div>
                        <div className="flex flex-row-reverse py-2">
                            <span>{`Total Coupons: 5253`}</span>
                        </div>
                        <TableContainer component={Paper} className="px-8">
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><span className="font-semibold">NAME</span></TableCell>
                                        <TableCell><span className="font-semibold">CUSTOMER TYPE</span></TableCell>
                                        <TableCell><span className="font-semibold">CODE</span></TableCell>
                                        <TableCell><span className="font-semibold">TYPE</span></TableCell>
                                        <TableCell><span className="font-semibold">DISCOUNT</span></TableCell>
                                        <TableCell><span className="font-semibold">USAGE</span></TableCell>
                                        <TableCell><span className="font-semibold">TOTAL USED</span></TableCell>
                                        <TableCell><span className="font-semibold">EXPIRY DATE</span></TableCell>
                                        <TableCell><span className="font-semibold">ACTION</span></TableCell>
                                        <TableCell><span className="font-semibold">STATUS</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[...Array(25)].map(() => (
                                        <TableRow
                                            key={'idx'}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">UGKS LIMO</TableCell>
                                            <TableCell>ALL</TableCell>
                                            <TableCell>JUNEHOLIDAY10</TableCell>
                                            <TableCell>AMOUNT</TableCell>
                                            <TableCell>S$10</TableCell>
                                            <TableCell>10</TableCell>
                                            <TableCell>2</TableCell>
                                            <TableCell>30-06-2024</TableCell>
                                            <TableCell><FontAwesomeIcon className="cursor-pointer"
                                            onClick={() => navigate('/modules/coupons-management/edit')}
                                            icon={faFilePen}/></TableCell>
                                            <TableCell><Switch/></TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>
            </div>

        </div>
    )
}

export default CouponsManagementPage