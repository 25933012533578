
import React, { useState } from "react"
import LoadingPage from "../common/Loading.page"
import { Box, Button, CardContent, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faFilePen, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { AddOnServices, countries } from "./data/form-data"

const AddOnItemsManagementPage = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <div className="flex flex-col">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Add On Items</Typography>
                </div>
                <div className="flex flex-row p-4 justify-center w-full gap-8 -ml-72">
                    <TextField
                        id="outlined-basic"
                        placeholder="Search By Name"
                        variant="outlined"
                        size="small"
                        className="rounded-lg w-[30%]"
                    />
                    <Button variant="contained" style={{ backgroundColor: '#0A2638' }}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </div>
            </div>
            <div>
                <Box>
                    <CardContent>
                        <div className="flex flex-row-reverse gap-4 mb-4">
                            <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#B6A269' }}
                                onClick={() => navigate('/modules/add-on-items-management/add')}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                Add New
                            </Button>
                        </div>
                        <div className="flex flex-row-reverse">
                            <span>{'Total Add On Items: 4'}</span>
                        </div>
                        <TableContainer component={Paper} className="px-8">
                            <Table size="small" className="w-1/2">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><span className="font-semibold">NAME</span></TableCell>
                                        <TableCell><span className="font-semibold">TYPE</span></TableCell>
                                        <TableCell><span className="font-semibold">LABEL 1</span></TableCell>
                                        <TableCell><span className="font-semibold">LABEL 2</span></TableCell>
                                        <TableCell><span className="font-semibold">ACTION</span></TableCell>
                                        <TableCell><span className="font-semibold">STATUS</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[...Array(10)].map((val: any, idx: any) => (
                                        <TableRow
                                            key={'idx'}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" className="w-1/3">
                                                {'Midnight Charge'}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {'Time Range'}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {'From'}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {'To'}
                                            </TableCell>
                                            <TableCell>
                                                <div className="flex flex-row gap-4">
                                                    <FontAwesomeIcon
                                                        onClick={() => navigate('/modules/add-on-items-management/edit')}
                                                        className="cursor-pointer w-6 h-6" icon={faFilePen} />
                                                </div>
                                            </TableCell>
                                            <TableCell><Switch /></TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>
            </div>

        </div>
    )
}

export default AddOnItemsManagementPage