import { faChevronCircleLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Typography,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import LoadingPage from "../../../common/Loading.page";
import { useNavigate } from "react-router-dom";
import ContainerPanel from "../../../common/ContainerPanel";

const AddNewVehiclePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>();
  return (
    <div className="flex flex-col">
      <LoadingPage loading={loading} />
      <div className="flex flex-1 bg-[#F1F2F3] p-4">
        <div
          className="flex flex-row gap-4 hover:cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            className={`h-10 w-10 text-[#333333]`}
          />
          <Typography variant="h5">Add Vehicle</Typography>
        </div>
      </div>
      <div className="flex flex-row-reverse px-20 gap-4">
        <Button
          className="w-32"
          variant="contained"
          size="small"
          style={{ backgroundColor: "#B6A269" }}
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faSave} className="mr-4" />
          Save
        </Button>
      </div>
      <ContainerPanel title={"Vehicle Details"}>
        <>
          <div className="grid grid-cols-6 gap-2 pb-4">
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Vehicle Type</InputLabel>
              <Select className="mt-2 h-10">
                <MenuItem value={""}>Choose Vehicle Type</MenuItem>
              </Select>
            </div>
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Description</InputLabel>
              <TextField
                multiline
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
                placeholder="Partner Name"
              ></TextField>
            </div>
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">
                Image (Exterior & Interior)
              </InputLabel>
              <TextField
                type="file"
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
                placeholder="Partner Name"
              ></TextField>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-2 pb-4">
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">
                Car Plate Number
              </InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
                placeholder="Partner Name"
              ></TextField>
            </div>
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Model</InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
                placeholder="Partner Name"
              ></TextField>
            </div>
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Brand</InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
                placeholder="Partner Name"
              ></TextField>
            </div>
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Color</InputLabel>
              <TextField
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
                placeholder="Partner Name"
              ></TextField>
            </div>
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">Driver</InputLabel>
              <Select className="mt-2 h-10">
                <MenuItem value={""}>Wang</MenuItem>
              </Select>
            </div>
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">
                Car Plate Picture
              </InputLabel>
              <TextField
                type="file"
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
                placeholder="Partner Name"
              ></TextField>
            </div>
            <div className="flex flex-col">
              <InputLabel className="text-sm -mb-2">PHV Decals</InputLabel>
              <TextField
                type="file"
                className="flex w-full h-10"
                margin="dense"
                size="small"
                variant="outlined"
                id="seat"
                name="seat"
                placeholder="Partner Name"
              ></TextField>
            </div>
          </div>
        </>
      </ContainerPanel>
      <ContainerPanel title={"Registration Detail"}>
        <div className="grid grid-cols-6 gap-2 pb-4">
          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">Registration Card</InputLabel>
            <TextField
              type="file"
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
              placeholder="Partner Name"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">Registration Date</InputLabel>
            <TextField
              type="date"
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
              placeholder="Partner Name"
            ></TextField>
          </div>

          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">Expiry Date</InputLabel>
            <TextField
              type="date"
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
              placeholder="Partner Name"
            ></TextField>
          </div>
        </div>
      </ContainerPanel>
      <ContainerPanel title={"Insurance Details"}>
        <div className="grid grid-cols-6 gap-2 pb-4">
          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">
              Insurance Certificate
            </InputLabel>
            <TextField
              type="file"
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
              placeholder="Partner Name"
            ></TextField>
          </div>
          <div className="flex flex-col">
            <InputLabel className="text-sm -mb-2">
              Expiry Date
            </InputLabel>
            <TextField
              type="date"
              className="flex w-full h-10"
              margin="dense"
              size="small"
              variant="outlined"
              id="seat"
              name="seat"
              placeholder="Partner Name"
            ></TextField>
          </div>
        </div>
      </ContainerPanel>
    </div>
  );
};

export default AddNewVehiclePage;
