import { CorporateModel } from "./Corporate.model";

export const CorporateInitialData: CorporateModel = {
    id: '423468'
}

export const DummyOptions = [
    { label: 'Singapore 01', value: 'sin01' },
    { label: 'Singapore 02', value: 'sin02' },
    { label: 'Singapore 03', value: 'sin03' }
];