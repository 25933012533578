import React, { useEffect, useState } from "react"
import LoadingPage from "../../../common/Loading.page"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faSearch } from "@fortawesome/free-solid-svg-icons"
import { Box, Button, CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { getVehicleList } from "../../../trips/services/TripsServices.services"
import { handleError } from "../../../../services/axiosInstance"
import { VehicleType } from "../../model/Vehicle.model"

const VehicleManagementPage = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)

    const [vehicleList, setVehicleList] = useState<VehicleType[]>([])


    const fetchVehicleList = () => {
        setLoading(true)
        getVehicleList()
        .then((res) => {
            console.log(res?.data?.elements)
            setVehicleList(res?.data?.elements)
        })
        .catch((err) => {
            handleError(err, navigate)
        })
        .finally(() => {
            setLoading(false)
        })
    }


    useEffect(() => {
        fetchVehicleList()
    }, [])
    return (
        <div className="flex flex-col">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-72 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Vehicle Management</Typography>
                </div>
                <div className="flex flex-row p-4 justify-center w-full gap-8 -ml-72">
                    <TextField
                        id="outlined-basic"
                        placeholder="Search by Supplier Name"
                        variant="outlined"
                        size="small"
                        className="rounded-lg w-[30%]"
                    />
                    <Button variant="contained" style={{ backgroundColor: '#0A2638' }}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </div>
            </div>

            <div>
                <Box>
                    <CardContent>
                        <TableContainer component={Paper} className="px-8">
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        {/* <TableCell>ID</TableCell> */}
                                        <TableCell>ACCOUNT NAME</TableCell>
                                        <TableCell>VEHICLE TYPE</TableCell>
                                        <TableCell>VEHICLE BRAND</TableCell>
                                        <TableCell>VEHICLE MODEL</TableCell>
                                        <TableCell>NO OF VEHICLE</TableCell>
                                        <TableCell>ACTION</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        vehicleList?.map((val, idx) => (
                                            <TableRow
                                                key={idx}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                {/* <TableCell component="th" scope="row">
                                                    {val.id}
                                                </TableCell> */}
                                                <TableCell>{val?.accountName}</TableCell>
                                                <TableCell>{val?.vehicleType}</TableCell>
                                                <TableCell>{val?.vehicleBrand}</TableCell>
                                                <TableCell>{val?.vehicleModel}</TableCell>
                                                <TableCell>{val?.vehicleNumber}</TableCell>
                                                <TableCell>
                                                    <div className="flex w-full gap-2">
                                                        <FontAwesomeIcon icon={faEye} className="hover:cursor-pointer h-6 w-6 pl-4"
                                                            onClick={() => {
                                                                navigate(`/vehicle/vehicle-management/list/detail?id=${val?.id}`)
                                                            }} />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>

            </div>

        </div>
    )
}

export default VehicleManagementPage