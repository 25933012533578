// src/Layout.tsx

import React, { useEffect, useState } from 'react';
import Images from '../../utils/ImageUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCar, faClock, faCreditCard, faDollarSign, faFile, faGaugeHigh, faGear, faGrip, faGripVertical, faLocationArrow, faMoneyCheckDollar, faUserCircle, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Outlet, useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge';
import { ClickAwayListener, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { performLogout } from '../../utils/GeneralUtils';

type menuOptions = '' | 'DASHBOARD' | 'USER' | 'CAR_TYPE' | 'TRIPS' | 'PRICE' | 'ZONE' | 'PAYMENTS' | 'MODULES' | 'REPORTS' | 'SETTINGS' | 'PROFILE'

const Layout: React.FC<any> = (props) => {
    const navigate = useNavigate()
    const [menuActive, setMenuActive] = useState<menuOptions>('')

    const [selectedMenu, setSelectedMenu] = useState<menuOptions>('')
    const iconStyle = 'h-6 w-6 text-white text-center self-center justify-center'
    const textMenuStyle = 'text-center text-lg mt-2'

    const userListItem = [
        {
            label: 'Supplier Management',
            path: '/user/supplier-management'
        },
        {
            label: 'Customer(B2C) Management',
            path: '/user/customer-management'
        },
        {
            label: 'Corporate/TA Management',
            path: '/user/corporate-ta-management'
        },
        {
            label: 'Staff Role Management',
            path: '/user/staff-role-management'
        },
        {
            label: 'Account Type Management',
            path: '/user/account-management'
        },
        {
            label: 'API Partner Management',
            path: '/user/api-partner-management'
        }
    ];

    const tripListItem = [
        {
            label: 'Trip Management',
            path: '/trip/trip-management'
        },
        {
            label: 'Calendar',
            path: '/'
        },
    ]

    const vehicleListItem = [
        {
            label: 'Vehicle Management',
            path: '/vehicle/vehicle-management'
        },
        {
            label: 'Vehicle Type Management',
            path: '/vehicle/vehicle-type-management'
        },
    ]
    const priceListItem = [
        {
            label: 'Selling Price',
            path: '/price/selling-price'
        },
        {
            label: 'Extra Seat Charge',
            path: '/price/extra-seat-charge'
        },
    ]
    const zoneListItem = [
        {
            label: 'Zone Management',
            path: '/zone/zone-management'
        },
        {
            label: 'Waiting Time',
            path: '/zone/waiting-time'
        },
    ]
    const paymentListItem = [
        {
            label: 'Debit/Topup',
            path: '/payments/debit-topup'
        },
        {
            label: 'Credit Settlement',
            path: '/payments/credit-settlement'
        },
        {
            label: 'Manage Banks',
            path: '/payments/bank-details'
        },
        {
            label: 'Manual Payment',
            path: '/payments/manual-payment'
        },
    ]
    const modulesListItem = [
        {
            label: 'Manage Coupons',
            path: '/modules/coupons-management'
        },
        {
            label: 'Manage Discount',
            path: '/modules/discount-management'
        },
        {
            label: 'Countries',
            path: '/modules/country-management'
        },
        {
            label: 'Add On Services',
            path: '/modules/add-on-services-management'
        },
        // {
        //     label: 'Color',
        //     path: '/'
        // },
        {
            label: 'Add On Items',
            path: '/modules/add-on-items-management'
        },
        {
            label: 'Currency Conversion',
            path: '/modules/currency-conversion'
        },
    ]
    const reportsListItem = [
        {
            label: 'Sales Revenue',
            path: '/reports/sales-reports'
        },
        {
            label: 'Vehicles',
            path: '/reports/vehicle-reports'
        },
        {
            label: 'Trips',
            path: '/reports/trip-reports'
        },
        {
            label: 'Driver',
            path: '/reports/driver-reports'
        },
        {
            label: 'Customer',
            path: '/reports/customer-reports'
        },
        {
            label: 'Billing',
            path: '/reports/billing-reports'
        },
    ]
    const profileListItem = [
        {
            label: 'Logout',
            path: '/login'
        },
    ]

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const listMenu = (selectedMenu: menuOptions) => {
        let returnMenu: any[] = []
        switch (selectedMenu) {
            case 'USER':
                returnMenu = userListItem
                break;
            case 'TRIPS':
                returnMenu = tripListItem
                break;
            case 'CAR_TYPE':
                returnMenu = vehicleListItem
                break;
            case 'PRICE':
                returnMenu = priceListItem
                break;
            case 'PROFILE':
                returnMenu = profileListItem
                break;
            case 'ZONE':
                returnMenu = zoneListItem
                break;
            case 'PAYMENTS':
                returnMenu = paymentListItem
                break;
            case 'MODULES':
                returnMenu = modulesListItem
                break;
            case 'REPORTS':
                returnMenu = reportsListItem
                break;
            default:
                break;
        }
        return returnMenu
    }

    useEffect(() => {
        // window.location.href = 'https://ugkslimousine.com/public/auth/login'
        // window.location.href = 'http://admin.ugkslimousine.com/'
    }, [])

    return (
        <div className="flex flex-col min-h-screen">
            <header className="bg-[#333333] p-4 h-24 flex flex-row w-full">
                <div className='flex w-2/12 mr-4'>
                    <img alt="" className="h-16" src={Images.primaryLogo} />
                </div>
                <div className='flex w-7/12 flex-row gap-2 justify-between'>
                    <div className='flex w-[15%] flex-col cursor-pointer hover:bg-slate-700 hover:rounded-lg justify-between'
                        onClick={() => {
                            setMenuActive('DASHBOARD')
                            setSelectedMenu('DASHBOARD')
                            navigate('/dashboard')
                        }}>
                        <FontAwesomeIcon icon={faGrip} color='white'
                            className={twMerge(iconStyle, `${menuActive === 'DASHBOARD' ? 'text-[#EDBA37]' : 'text-white'}`)} />
                        <span className={twMerge(textMenuStyle, `${menuActive === 'DASHBOARD' ? 'text-[#EDBA37]' : 'text-white'}`)}>Dashboard</span>
                    </div>
                    <div
                        className='flex w-[15%] flex-col cursor-pointer hover:bg-slate-700 hover:rounded-lg justify-between'
                        onClick={(e) => {
                            handleClick(e)
                            setSelectedMenu('USER')
                        }}
                    >
                        <FontAwesomeIcon icon={faUsers} color='white'
                            className={twMerge(iconStyle, `${menuActive === 'USER' ? 'text-[#EDBA37]' : 'text-white'}`)} />
                        <span className={twMerge(textMenuStyle, `${menuActive === 'USER' ? 'text-[#EDBA37]' : 'text-white'}`)}>User</span>
                    </div>

                    <div onClick={(e) => {
                        handleClick(e)
                        setSelectedMenu('CAR_TYPE')
                    }} className='flex w-[15%] flex-col cursor-pointer hover:bg-slate-700 hover:rounded-lg justify-between'>
                        <FontAwesomeIcon icon={faCar} color='white'
                            className={twMerge(iconStyle, `${menuActive === 'CAR_TYPE' ? 'text-[#EDBA37]' : 'text-white'}`)} />
                        <span className={twMerge(textMenuStyle, `${menuActive === 'CAR_TYPE' ? 'text-[#EDBA37]' : 'text-white'}`)}>Car Type</span>
                    </div>
                    <div onClick={(e) => {
                        handleClick(e)
                        setSelectedMenu('TRIPS')
                    }} className='flex w-[15%] flex-col cursor-pointer hover:bg-slate-700 hover:rounded-lg justify-between'>
                        <FontAwesomeIcon icon={faGaugeHigh} color='white'
                            className={twMerge(iconStyle, `${menuActive === 'TRIPS' ? 'text-[#EDBA37]' : 'text-white'}`)} />
                        <span className={twMerge(textMenuStyle, `${menuActive === 'TRIPS' ? 'text-[#EDBA37]' : 'text-white'}`)}>Trips</span>
                    </div>
                    <div onClick={(e) => {
                        handleClick(e)
                        setSelectedMenu('PRICE')
                    }}
                        className='flex w-[15%] flex-col cursor-pointer hover:bg-slate-700 hover:rounded-lg justify-between'>
                        <FontAwesomeIcon icon={faDollarSign} color='white'
                            className={twMerge(iconStyle, `${menuActive === 'PRICE' ? 'text-[#EDBA37]' : 'text-white'}`)} />
                        <span className={twMerge(textMenuStyle, `${menuActive === 'PRICE' ? 'text-[#EDBA37]' : 'text-white'}`)}>Price</span>
                    </div>
                    <div onClick={(e) => {
                        handleClick(e)
                        setSelectedMenu('ZONE')
                    }}
                        className='flex w-[15%] flex-col cursor-pointer hover:bg-slate-700 hover:rounded-lg justify-between'>
                        <FontAwesomeIcon icon={faLocationArrow} color='white'
                            className={twMerge(iconStyle, `${menuActive === 'ZONE' ? 'text-[#EDBA37]' : 'text-white'}`)} />
                        <span className={twMerge(textMenuStyle, `${menuActive === 'ZONE' ? 'text-[#EDBA37]' : 'text-white'}`)}>Zone</span>
                    </div>
                    <div onClick={(e) => {
                        handleClick(e)
                        setSelectedMenu('PAYMENTS')
                    }}
                        className='flex w-[15%] flex-col cursor-pointer hover:bg-slate-700 hover:rounded-lg justify-between'>
                        <FontAwesomeIcon icon={faCreditCard} color='white'
                            className={twMerge(iconStyle, `${menuActive === 'PAYMENTS' ? 'text-[#EDBA37]' : 'text-white'}`)} />
                        <span className={twMerge(textMenuStyle, `${menuActive === 'PAYMENTS' ? 'text-[#EDBA37]' : 'text-white'}`)}>Payments</span>
                    </div>
                    <div onClick={(e) => {
                        handleClick(e)
                        setSelectedMenu('MODULES')
                    }}
                        className='flex w-[15%] flex-col cursor-pointer hover:bg-slate-700 hover:rounded-lg justify-between'>
                        <FontAwesomeIcon icon={faGripVertical} color='white'
                            className={twMerge(iconStyle, `${menuActive === 'MODULES' ? 'text-[#EDBA37]' : 'text-white'}`)} />
                        <span className={twMerge(textMenuStyle, `${menuActive === 'MODULES' ? 'text-[#EDBA37]' : 'text-white'}`)}>Modules</span>
                    </div>
                    <div onClick={(e) => {
                        handleClick(e)
                        setSelectedMenu('REPORTS')
                    }}
                        className='flex w-[15%] flex-col cursor-pointer hover:bg-slate-700 hover:rounded-lg justify-between'>
                        <FontAwesomeIcon icon={faFile} color='white'
                            className={twMerge(iconStyle, `${menuActive === 'REPORTS' ? 'text-[#EDBA37]' : 'text-white'}`)} />
                        <span className={twMerge(textMenuStyle, `${menuActive === 'REPORTS' ? 'text-[#EDBA37]' : 'text-white'}`)}>Reports</span>
                    </div>
                    <div
                        onClick={(e) => {
                            setMenuActive('SETTINGS')
                            navigate('/settings')
                        }}
                        className='flex w-[15%] flex-col cursor-pointer hover:bg-slate-700 hover:rounded-lg justify-between'>
                        <FontAwesomeIcon icon={faGear} color='white'
                            className={twMerge(iconStyle, `${menuActive === 'SETTINGS' ? 'text-[#EDBA37]' : 'text-white'}`)} />
                        <span className={twMerge(textMenuStyle, `${menuActive === 'SETTINGS' ? 'text-[#EDBA37]' : 'text-white'}`)}>Settings</span>
                    </div>
                    <Menu
                        id="menu-user"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {listMenu(selectedMenu).map((item) => (
                            <div onClick={() => {
                                setMenuActive(selectedMenu)
                                if (item.label === 'Logout') {
                                    performLogout(navigate)
                                } else {
                                    navigate(item.path)
                                }
                            }}>
                                <MenuItem onClick={handleClose}>{item.label}</MenuItem>
                            </div>
                        ))}
                    </Menu>
                </div>
                <div className='flex w-3/12 flex-2 mr-4 pl-8'>
                    <div className='w-[40%] flex flex-row justify-between'>
                        <div className='flex flex-col justify-center cursor-pointer' title='Unscheduled trip'>
                            <FontAwesomeIcon icon={faClock} color='white' className='h-6 w-6 text-white text-center self-center justify-center' />
                        </div>
                        <div className='flex flex-col justify-center cursor-pointer' title='Pending Ttopup'>
                            <FontAwesomeIcon icon={faMoneyCheckDollar} color='white' className='h-6 w-6 text-white text-center self-center justify-center' />
                        </div>
                        <div className='flex flex-col justify-center cursor-pointer' title='Notification'>
                            <FontAwesomeIcon icon={faBell} color='white' className='h-6 w-6 text-white text-center self-center justify-center' />
                        </div>
                    </div>
                    <div
                        className='w-[60%] flex flex-row justify-center rounded-lg hover:bg-[#565656] m-2 cursor-pointer'
                        onClick={(e) => {
                            handleClick(e)
                            setSelectedMenu('PROFILE')
                        }}
                    >
                        <div className='flex flex-col justify-center mr-2'>
                            <span className='self-center align-middle justify-center text-white font-semibold'>
                                UGKS ADMIN
                            </span>
                        </div>
                        <FontAwesomeIcon icon={faUserCircle} color='white' className='h-6 w-6 text-white text-center self-center justify-center' />
                    </div>
                </div>
            </header>
            <main>
                <Outlet />
            </main>
        </div>
    );
};

export default Layout;
