import React, { useState } from "react"
import LoadingPage from "../common/Loading.page"
import { Box, Button, CardContent, Checkbox, MenuItem, Pagination, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePen, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"

const BillingReportPage = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <div className="flex flex-col py-4">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Billing Report</Typography>
                </div>
            </div>
            <div>
                <Box>
                    <CardContent>
                        <div className="flex flex-row justify-between mb-4">
                            <div className="flex flex-row gap-4">
                                <TextField
                                    id="outlined-basic"
                                    placeholder=""
                                    variant="outlined"
                                    size="small"
                                    className="rounded-lg w-[30%]"
                                    type="date"
                                />
                                <TextField
                                    id="outlined-basic"
                                    placeholder=""
                                    variant="outlined"
                                    size="small"
                                    className="rounded-lg w-[30%]"
                                    type="date"
                                />
                                <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}
                                    onClick={() => window.location.reload()}
                                >
                                    {`Search`}
                                </Button>
                                <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}
                                    onClick={() => window.location.reload()}
                                >
                                    {`Export`}
                                </Button>
                            </div>
                            <Button color="success" className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}
                                onClick={() => window.location.reload()}
                            >
                                {`All Selected`}
                            </Button>
                        </div>
                        <TableContainer component={Paper} className="px-8">
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><span className="font-semibold">TRIP ID</span></TableCell>
                                        <TableCell><span className="font-semibold">TRIP DATE</span></TableCell>
                                        <TableCell><span className="font-semibold">STATUS</span></TableCell>
                                        <TableCell><span className="font-semibold">PICKUP</span></TableCell>
                                        <TableCell><span className="font-semibold">DROP OFF</span></TableCell>
                                        <TableCell><span className="font-semibold">SERVICE TYPE</span></TableCell>
                                        <TableCell><span className="font-semibold">CUSTOMER</span></TableCell>
                                        <TableCell><span className="font-semibold">SUB ACCOUNT</span></TableCell>
                                        <TableCell><span className="font-semibold">PASSENGER</span></TableCell>
                                        <TableCell><span className="font-semibold">INV. NO</span></TableCell>
                                        <TableCell><span className="font-semibold">PRICE</span></TableCell>
                                        <TableCell><span className="font-semibold">ADD ON</span></TableCell>
                                        <TableCell><span className="font-semibold">EXTRA</span></TableCell>
                                        <TableCell><span className="font-semibold">DISCOUNT</span></TableCell>
                                        <TableCell><span className="font-semibold">TOTAL</span></TableCell>
                                        <TableCell><span className="font-semibold">PAYMENTS STATUS</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[...Array(15)].map(() => (
                                        <TableRow
                                            key={'idx'}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            className="hover:bg-[#B6A269] hover:bg-opacity-30"
                                        >
                                            <TableCell>374</TableCell>
                                            <TableCell>12-06-2024</TableCell>
                                            <TableCell>Trip Completed</TableCell>
                                            <TableCell>
                                                <div className="w-44">
                                                    Airport Boulevard, Changi Airport (SIN), Singapore
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="w-44">
                                                    Hill Street, Pullman Singapore Hill Street, Singapore
                                                </div>
                                            </TableCell>
                                            <TableCell>Arrival</TableCell>
                                            <TableCell>
                                                ugks
                                                Corporate
                                                ugks booking
                                            </TableCell>
                                            <TableCell>
                                                374
                                            </TableCell>
                                            <TableCell>
                                                KIM SUNMI
                                            </TableCell>
                                            <TableCell>Inv No:374</TableCell>
                                            <TableCell>46.00</TableCell>
                                            <TableCell>0</TableCell>
                                            <TableCell>0</TableCell>
                                            <TableCell>0</TableCell>
                                            <TableCell>46.00</TableCell>
                                            <TableCell>Completed</TableCell>

                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="flex flex-row-reverse py-4">
                            <Pagination count={10} />
                        </div>
                    </CardContent>
                </Box>
            </div>

        </div>
    )
}

export default BillingReportPage