import React, { useState } from "react"
import LoadingPage from "../common/Loading.page"
import { Box, Button, CardContent, Checkbox, MenuItem, Pagination, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePen, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"

const SalesReportPage = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <div className="flex flex-col py-4">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Sales Report</Typography>
                </div>
            </div>
            <div>
                <Box>
                    <CardContent>
                        <div className="flex flex-row-reverse gap-4">
                            <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}
                                onClick={() => window.location.reload()}
                            >
                                {`Reset`}
                            </Button>
                            <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}
                                onClick={() => window.location.reload()}
                            >
                                {`filter`}
                            </Button>
                            <Select
                                id="outlined-basic"
                                placeholder="Search by Supplier Name"
                                variant="outlined"
                                size="small"
                                className="rounded-lg w-[30%]"
                            >
                                {[
                                    { label: 'By Customer', value: 'customer' },
                                    { label: 'Vehicle Type', value: 'vehicle' },
                                    { label: 'Service Type', value: 'service' },
                                    { label: 'By Date Range', value: 'date' },
                                ].map((item) => {
                                    return (
                                        <MenuItem value={item?.value}>{item?.label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className="flex flex-row-reverse py-2">
                            <Button color="success" className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small"
                                onClick={() => window.location.reload()}
                            >
                                {`Excell`}
                            </Button>
                        </div>
                        <TableContainer component={Paper} className="px-8">
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><span className="font-semibold">CUSTOMER</span></TableCell>
                                        <TableCell><span className="font-semibold">BOOKING COUNT</span></TableCell>
                                        <TableCell><span className="font-semibold">RATE</span></TableCell>
                                        <TableCell><span className="font-semibold">ADD ON</span></TableCell>
                                        <TableCell><span className="font-semibold">EXTRA CHARGE</span></TableCell>
                                        <TableCell><span className="font-semibold">STOPS</span></TableCell>
                                        <TableCell><span className="font-semibold">AGENT MARKUP</span></TableCell>
                                        <TableCell><span className="font-semibold">ADMIN MARKUP</span></TableCell>
                                        <TableCell><span className="font-semibold">DISC</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[...Array(25)].map(() => (
                                        <TableRow
                                            key={'idx'}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            className="hover:bg-[#B6A269] hover:bg-opacity-30"
                                        >
                                            <TableCell component="th" scope="row">DSS,Corporate</TableCell>
                                            <TableCell>3</TableCell>
                                            <TableCell>205.00</TableCell>
                                            <TableCell>0</TableCell>
                                            <TableCell>15</TableCell>
                                            <TableCell>0</TableCell>
                                            <TableCell>0.00</TableCell>
                                            <TableCell>0.00</TableCell>
                                            <TableCell>0</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="flex flex-row-reverse py-4">
                            <Pagination count={10} />
                        </div>
                        <div className="flex flex-row justify-between">
                            <Typography variant="h5">{`Total Amount: 24769.00`}</Typography>
                            <Typography variant="h5">{`Total Add On: 0`}</Typography>
                            <Typography variant="h5">{`Extra Charges Total : 375`}</Typography>
                            <Typography variant="h5">{`Discount Total : 20`}</Typography>
                        </div>
                    </CardContent>
                </Box>
            </div>

        </div>
    )
}

export default SalesReportPage