import React, { useState } from "react"
import LoadingPage from "../common/Loading.page"
import { Box, Button, CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"

const DebitTopupPage = () => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <div className="flex flex-col">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Debit/Topup</Typography>
                </div>
                <div className="flex flex-row p-4 justify-center w-full gap-8 -ml-72">
                    <TextField
                        id="outlined-basic"
                        placeholder="Search by Name"
                        variant="outlined"
                        size="small"
                        className="rounded-lg w-[30%]"
                    />
                    <Button variant="contained" style={{ backgroundColor: '#0A2638' }}>
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </div>
            </div>
            <div>
                <Box>
                    <CardContent>
                        <div className="flex flex-row-reverse gap-4 mb-4">
                            <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#B6A269' }}
                                onClick={() => navigate('/payments/debit-topup/add')}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                Add New
                            </Button>
                            <Button className="w-32 disabled:text-white disabled:cursor-not-allowed gap-2" variant="contained" size="small" style={{ backgroundColor: '#0A2638' }}
                                onClick={() => window.location.reload()}
                            >
                                {`Pending(0)`}
                            </Button>
                        </div>
                        <div className="flex flex-row-reverse">
                            <span>{'Total Records Found: 36'}</span>
                        </div>
                        <TableContainer component={Paper} className="px-8">
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>COMPANY NAME</TableCell>
                                        <TableCell>PARTNER TYPE</TableCell>
                                        <TableCell>DEBIT</TableCell>
                                        <TableCell>TOPUP</TableCell>
                                        <TableCell>REFUND</TableCell>
                                        <TableCell>BALANCE</TableCell>
                                        <TableCell>RECEIPT</TableCell>
                                        <TableCell>TRIP</TableCell>
                                        <TableCell>BANK NAME</TableCell>
                                        <TableCell>BRANCH NAME</TableCell>
                                        <TableCell>TRANSACTION DATE</TableCell>
                                        <TableCell>REMARK</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[...Array(25)].map(() => (
                                        <TableRow
                                            key={'idx'}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" className="w-56">
                                                {'Packyourbags travel and tour'}
                                            </TableCell>
                                            <TableCell className="w-40">{'Agent'}</TableCell>
                                            <TableCell>{'72.00'}</TableCell>
                                            <TableCell>{'0.00'}</TableCell>
                                            <TableCell>{'0.00'}</TableCell>
                                            <TableCell>{'11.00'}</TableCell>
                                            <TableCell>{'-'}</TableCell>
                                            <TableCell>{'152'}</TableCell>
                                            <TableCell>{'ODBC'}</TableCell>
                                            <TableCell>{'Singapore'}</TableCell>
                                            <TableCell>{'30-04-2024'}</TableCell>
                                            <TableCell>{'Testing'}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>
            </div>

        </div>
    )
}

export default DebitTopupPage