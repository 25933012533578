import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { Modal, Typography } from "@mui/material"

const LoadingPage = ({ loading }: any) => {
    return (
        <Modal open={loading} className={`${loading ? 'visible' : 'hidden'} z-99999`}>
            <div className="w-screen h-screen flex flex-col justify-center">
                <FontAwesomeIcon icon={faSpinner} className="text-white h-24 w-24 align-middle justify-center self-center animate-spin" />
                <Typography variant="h4" className="text-white text-center w-full pt-8 align-middle self-center">Loading...</Typography>
            </div>
        </Modal>
    )
}

export default LoadingPage