import { faFilePen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { RoleListItem } from "./User.model";

const RoleManagement = () => {
    const navigate = useNavigate()
    const [RoleList, setRoleList] = useState<RoleListItem[]>([])

    const fetchRoleList = () => {
        const list: RoleListItem[] = [
            { id: '1', role: 'Staff' },
            { id: '2', role: 'TravelAgent' },
            { id: '3', role: 'Corporate' },
            { id: '4', role: 'Guest' },
            { id: '5', role: 'Supplier' },
        ];

        setRoleList(list)
    }

    useEffect(() => {
        fetchRoleList()
    }, [])
    return (
        <div className="flex flex-col">
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center pl-8">
                    <Typography variant="h5">Account Type Management</Typography>
                </div>

            </div>
            <div className="flex flex-row-reverse px-20 gap-4">
                <Button className="w-32 gap-4" variant="contained" size="small" style={{ backgroundColor: '#C4A34F' }}
                    onClick={() => navigate('/user/account-management/add-new')}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    Add New
                </Button>
            </div>
            <div>
                <Box>
                    <CardContent>
                        <TableContainer component={Paper} className="px-8">
                            <span className="text-sm">{`Total Role's: ${RoleList.length}`}</span>
                            <Table size="small" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ROLE</TableCell>
                                        <TableCell>ACTION</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        RoleList.map((val, idx) => (
                                            <TableRow
                                                key={val.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{val.role}</TableCell>
                                                <TableCell>
                                                    <div className="flex w-full gap-2" title="Edit">
                                                        <FontAwesomeIcon icon={faFilePen} className="hover:cursor-pointer h-6 w-6 pl-4"
                                                            onClick={() => {
                                                                navigate(`/user/account-management/add-new?id=${val.id}`)
                                                            }} />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Box>

            </div>
        </div>
    )
}

export default RoleManagement