import React, { useState } from "react"
import LoadingPage from "../common/Loading.page"
import { Button, FormLabel, TextField, Typography } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCar, faChartLine, faRoute, faSearch, faUserGroup } from "@fortawesome/free-solid-svg-icons"
import DatePicker from "react-date-picker"
import { BarChart, LineChart, PieChart } from "@mui/x-charts"
import { dataset } from "./form-data"

const DashboardPage = () => {

    type dashboardType = 'WEEKLY' | 'SERVICE TYPE' | 'CHANNELS' | 'VEHICLE TYPE'
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedSubMenu, setSelectedSubMenu] = useState<dashboardType>('WEEKLY')

    const logoRender = (menu: dashboardType) => {
        switch (menu) {
            case 'WEEKLY':
                return <FontAwesomeIcon icon={faChartLine} className="mr-4" />
            case 'SERVICE TYPE':
                return <FontAwesomeIcon icon={faRoute} className="mr-4" />
            case 'CHANNELS':
                return <FontAwesomeIcon icon={faUserGroup} className="mr-4" />
            case 'VEHICLE TYPE':
                return <FontAwesomeIcon icon={faCar} className="mr-4" />

            default:
                break;
        }
    }
    const chartSetting = {
        xAxis: [
            {
                label: 'rainfall (mm)',
            },
        ],
        width: 500,
        height: 400,
    };

    const valueFormatter = (value: number | null) => `${value}mm`;
    return (
        <div className="flex flex-col">
            <LoadingPage loading={loading} />
            <div className="flex flex-1 bg-[#F1F2F3]">
                <div className="w-96 flex flex-col justify-center pl-8">
                    <Typography variant="h5">SALES SUMMARY</Typography>
                </div>
                <div className="flex flex-row p-4 justify-end w-full gap-8">
                    <div className="flex flex-col w-[20%]">
                        <FormLabel>From Date</FormLabel>
                        <TextField
                            id="outlined-basic"
                            placeholder="From Date"
                            defaultValue={new Date}
                            variant="outlined"
                            size="small"
                            type="date"
                            className="rounded-lg w-full"
                        />
                    </div>
                    <div className="flex flex-col w-[20%]">
                        <FormLabel>To Date</FormLabel>
                        <TextField
                            id="outlined-basic"
                            placeholder="To Date"
                            defaultValue={new Date}
                            variant="outlined"
                            size="small"
                            type="date"
                            className="rounded-lg w-full"
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-row flex-1 w-full">
                {['WEEKLY', 'SERVICE TYPE', 'CHANNELS', 'VEHICLE TYPE'].map((menu) => (
                    <div
                        onClick={() => setSelectedSubMenu(menu as dashboardType)}
                        className={`cursor-pointer w-[15%] font-semibold p-4 ${selectedSubMenu === menu ? 'bg-[#B6A269]' : 'bg-[#333333] text-white'} drop-shadow-xl rounded-t-lg`}
                    >
                        {logoRender(menu as dashboardType)}
                        {menu}
                    </div>
                ))}
            </div>
            <div>
                {selectedSubMenu === 'WEEKLY' &&
                    <BarChart
                        xAxis={[
                            {
                                id: 'barCategories',
                                data: ['bar A', 'bar B', 'bar C'],
                                scaleType: 'band',
                            },
                        ]}
                        series={[
                            {
                                data: [2, 5, 3],
                            },
                        ]}
                        width={500}
                        height={300}
                    />}
                {selectedSubMenu === 'SERVICE TYPE' &&
                    <PieChart
                        series={[
                            {
                                data: [
                                    { id: 0, value: 10, label: 'series A' },
                                    { id: 1, value: 15, label: 'series B' },
                                    { id: 2, value: 20, label: 'series C' },
                                ],
                            },
                        ]}
                        width={400}
                        height={200}
                    />}
                {selectedSubMenu === 'CHANNELS' && <LineChart
                    xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                    series={[
                        {
                            data: [2, 5.5, 2, 8.5, 1.5, 5],
                        },
                    ]}
                    width={500}
                    height={300}
                />}
                {selectedSubMenu === 'VEHICLE TYPE' && <BarChart
                    dataset={dataset}
                    yAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                    series={[{ dataKey: 'seoul', label: 'Vehicle Type', valueFormatter }]}
                    layout="horizontal"
                    {...chartSetting}
                />}
            </div>
        </div>
    )
}

export default DashboardPage