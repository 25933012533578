import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const token = localStorage.getItem('AUTH_TOKEN'); 

 
  return token ? <Outlet /> : <Navigate to="/login" />;
 
};

export default PrivateRoute;
